export const LOADING_INVENTORIES = 'LOADING_INVENTORIES'
export const PERSIST_CHANGES_INVENTORIES = 'PERSIST_CHANGES_INVENTORIES'
export const SET_INVENTORY_ID = 'SET_INVENTORY_ID'
export const SET_ACCOUNT_ID = 'SET_ACCOUNT_ID'

export const GET_INVENTORIES = 'GET_INVENTORIES'
export const GET_INVENTORY = 'GET_INVENTORY'

export const NEW_INVENTORY = 'NEW_INVENTORY'

export const SET_ITEM_DISPLAY_REPORTS = 'SET_ITEM_DISPLAY_REPORTS'

export const Inventory = {
    Status: {
        ACTIVE: 'ACTIVE',
        DRAFT: 'DRAFT',
        DISABLED: 'DISABLED',
        ARCHIVED: 'ARCHIVED'
    }
}


