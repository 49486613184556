import obiApi from '../../services';
import { SET_MENU_EXPAND, SET_NOTIFICATION, SET_SELECTED_ACCOUNT, GET_AVAILABLE_ACCOUNTS } from "../base-const";
import { formatError } from '../../../utils/utils'

export const setExpand = (expand) => dispatch => {
    dispatch({
        type: SET_MENU_EXPAND,
        payload: expand
    })
};

export const logoutUser = history => dispatch => {
    obiApi
        .post('/auth/logout')
        .then(res => {
            if (res.data.message) {
                dispatch({
                    type: SET_NOTIFICATION,
                    payload: { type: "success", message: res.data.message, autoclose: true }
                })
                history.push("/");
            }
        })
        .catch(err =>
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        );
};

export const setSelectedAccount = (account_id) => dispatch => {
    obiApi.get('/accounts/' + account_id).then(res => {
        dispatch({
            type: SET_SELECTED_ACCOUNT,
            payload: res.data
        })
    }).catch(err => {
        dispatch({
            type: SET_NOTIFICATION,
            payload: { type: "error", message: formatError(err), autoclose: true }
        })
    });
};


export const getAvailableAccounts = () => dispatch => {
    obiApi.get('/accounts')
        .then(res => {
            dispatch({
                type: GET_AVAILABLE_ACCOUNTS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};
