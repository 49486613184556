import React from 'react';
import { Radio, FlexboxGrid, IconButton, Icon, Panel } from 'rsuite';
import {
    TextField, RadioField, SelectField,
    DateField, DateRangeField, TimeRangeField,
    NumberField, WeekdayRangeField
} from '../../../_common/form-fields';
import CalendarContainer from '../../calendars/calendars-container';
import { genAvailabilityStatusData } from '../../../../utils/utils';
import { Item, AVAILABILITY_INITIAL_STATE } from '../items-const'

const PanelAvailabilities = ({ item, formError, options, handleFormChange, removeFormRow, addFormRow, ftype, ...rest }) => {
    return (
        <Panel
            header="Availabilities"
            collapsible
            expanded={formError.availabilities ? true : undefined}
            {...rest}
        >
            {item.availabilities && item.availabilities.length > 0 ? item.availabilities.map((availability, index) => (
                <div key={"avail" + index} className="availability">
                    <div>
                        <FlexboxGrid >
                            <FlexboxGrid.Item>
                                <SelectField
                                    name={`availabilities.${index}.range_type`}
                                    placeholder="Range Type"
                                    searchable={false}
                                    data={
                                        item.calendar_type === Item.CalendarType.SINGLE_DATE ? Item.Availabilities.RangeTypesPermanent :
                                            item.calendar_type === Item.CalendarType.DATES_RANGE ? Item.Availabilities.RangeTypesDateRange :
                                                Item.Availabilities.RangeTypesTimeSlot
                                    }
                                    preventOverflow={true}
                                    style={{ width: 200 }}
                                    menuStyle={{ width: 250 }}
                                    value={availability.range_type}
                                    onChange={(e) => {
                                        availability.range_from = "";
                                        availability.range_to = "";
                                        availability.range_between = "";
                                        handleFormChange(item, ftype);
                                    }}
                                />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item className="pl-2">
                                {availability.range_type === Item.Availabilities.RangeType.DATE_ON ||
                                    availability.range_type === Item.Availabilities.RangeType.DATE_ON_TIME_BTW
                                    ?
                                    <DateField
                                        name={`availabilities.${index}.range_from`}
                                        placeholder="Select Date"
                                        item={item}
                                        handleFormChange={handleFormChange}
                                        date_format={"date"}
                                        date_value={availability.range_from}
                                        ftype={ftype}
                                    />
                                    :
                                    ""
                                }

                                {availability.range_type === Item.Availabilities.RangeType.DATE_RANGE ||
                                    availability.range_type === Item.Availabilities.RangeType.DATE_RANGE_TIME_BTW
                                    ?
                                    <DateRangeField
                                        name_from={`availabilities.${index}.range_from`}
                                        name_to={`availabilities.${index}.range_to`}
                                        item={item}
                                        handleFormChange={handleFormChange}
                                        date_format={"date"}
                                        date_value_from={availability.range_from}
                                        date_value_to={availability.range_to}
                                        ftype={ftype}
                                    />
                                    :
                                    ""
                                }

                                {availability.range_type === Item.Availabilities.RangeType.DATE_TIME_RANGE
                                    ?
                                    <DateRangeField
                                        name_from={`availabilities.${index}.range_from`}
                                        name_to={`availabilities.${index}.range_to`}
                                        placeholder="Select Date Range"
                                        item={item}
                                        handleFormChange={handleFormChange}
                                        date_format={"datetime"}
                                        date_value_from={availability.range_from}
                                        date_value_to={availability.range_to}
                                        ftype={ftype}
                                    />
                                    :
                                    ""
                                }

                                {availability.range_type === Item.Availabilities.RangeType.WEEKDAY_ON ||
                                    availability.range_type === Item.Availabilities.RangeType.WEEKDAY_ON_TIME_BTW
                                    ?
                                    <SelectField
                                        name={`availabilities.${index}.range_from`}
                                        placeholder="Select Weekday"
                                        searchable={false}
                                        data={Item.Availabilities.Weekdays}
                                        preventOverflow={true}
                                        style={{ width: 200 }}
                                        value={availability.range_from}
                                    />
                                    : ""}

                                {availability.range_type === Item.Availabilities.RangeType.WEEKDAY_RANGE ||
                                    availability.range_type === Item.Availabilities.RangeType.WEEKDAY_RANGE_TIME_BTW
                                    ?
                                    <WeekdayRangeField
                                        name_from={`availabilities.${index}.range_from`}
                                        name_to={`availabilities.${index}.range_to`}
                                        weekdays_data={Item.Availabilities.Weekdays}
                                        handleFormChange={handleFormChange}
                                        item={item}
                                        ftype={ftype}
                                        style={{ width: 200 }}
                                        value_from={availability.range_from}
                                        value_to={availability.range_to}
                                    />
                                    : ""}
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item className="pl-2">
                                {availability.range_type === Item.Availabilities.RangeType.DATE_ON_TIME_BTW ||
                                    availability.range_type === Item.Availabilities.RangeType.DATE_RANGE_TIME_BTW ||
                                    availability.range_type === Item.Availabilities.RangeType.WEEKDAY_ON_TIME_BTW ||
                                    availability.range_type === Item.Availabilities.RangeType.WEEKDAY_RANGE_TIME_BTW

                                    ?
                                    <TimeRangeField
                                        name={`availabilities.${index}.range_between`}
                                        item={item}
                                        handleFormChange={handleFormChange}
                                        time_range={availability.range_between}
                                        ftype={ftype}
                                    />

                                    : ""}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </div>

                    <div className={"pt-2"}>
                        <FlexboxGrid >
                            <FlexboxGrid.Item>
                                <RadioField
                                    name={`availabilities.${index}.booking_allowed`}
                                    className="inline-radio mt-2"
                                    appearance="picker"
                                    inline
                                    value={availability.booking_allowed}
                                >
                                    <span>Booking Allowed: </span>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </RadioField>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="pl-2">
                                <SelectField
                                    name={`availabilities.${index}.booking_allowed_label`}
                                    label={"Status Label"}
                                    placeholder="Select Label"
                                    searchable={false}
                                    data={genAvailabilityStatusData(options, item.item_type_id, availability.booking_allowed)}
                                    value={availability.booking_allowed_label}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="pl-2">
                                {availability.booking_allowed === true ?
                                    <NumberField
                                        name={`availabilities.${index}.available_slots`}
                                        min={0}
                                        label={`Available ${item.slot_label ? (item.slot_label + "s") : "slot(s)"}`}
                                        style={{ width: 100 }}
                                        value={availability.available_slots}
                                    />
                                    : <TextField type="hidden" name={`availabilities.${index}.available_slots`} value={0} />
                                }
                            </FlexboxGrid.Item>

                            {/* TEMPORARY HIDDEN*/}
                            <FlexboxGrid.Item className="pl-2">
                                {/*<UserSelectField
                                name={`availabilities.${index}.available_users`}
                                label="Related to (optional)"
                                placeholder="Select team members"
                                message={"You can set team members who are related to this availability. Example: Booking a meeting with specific team member."}
                                data={genUserSelectionData(users.data)}
                                size={"sm"}
                                value={availability.available_users}
                                type="hidden"
                            />*/}

                            </FlexboxGrid.Item>

                        </FlexboxGrid>
                    </div>
                    {/*<Divider /> */}
                    <IconButton
                        className="ml-1 delete"
                        size="xs"
                        placement="left"
                        onClick={() => removeFormRow(`availabilities`, index, ftype)}
                        icon={<Icon icon="trash" />}
                    />
                </div>
            )) : <div className="text-center">{"- No availabilities found -"}</div>
            }


            <div className="text-center">
                <IconButton
                    size="xs"
                    placement="left"
                    onClick={() => addFormRow(`availabilities`, ftype, AVAILABILITY_INITIAL_STATE)}
                    icon={<Icon icon="plus-square-o" />}
                    className="mt-2"
                >
                    {"Add Availability"}
                </IconButton>
            </div>

            {ftype === "edit" && item.status === Item.Status.ACTIVE ?
                <div className="availability-calendar pt-3 pb-3 text-center">
                    <CalendarContainer />
                    <small>Calendar Preview - Save item to see latest changes</small>
                </div>
                : <div className="pt-3 pb-3 text-center">
                    <small>{"Calendar preview is available after item is saved and item status is active."}</small>
                </div>
            }

        </Panel>


    )
}

export default PanelAvailabilities;