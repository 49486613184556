import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Schema } from 'rsuite';
import _ from 'lodash'

import { getAll, openEdit, persistChanges, update, del, reset } from './bookings-action'
import { closeDrawer } from '../_base/drawer/drawer-action';
import { showModal } from '../_base/modal/modal-action';
import { getAll as getAllUsers } from '../users/users-action'
import {
    getAll as getAllPeoples,
    openNew as openNewPerson,
    openEdit as openEditPerson,
    persistChanges as persistChangesPeople,
    create as createPeople
} from '../peoples/peoples-action'

import { model as peopleModel } from '../peoples/peoples-container'

import { Booking } from "./bookings-const";
import { User } from "../users/users-const";
import { People } from "../peoples/peoples-const";

import Bookings from "./bookings";

import { formatErrors, parse, unFlattenData } from '../../utils/utils'


const { StringType, NumberType, ObjectType, ArrayType, DateType } = Schema.Types;
export const model = Schema.Model({
    item_id: NumberType().isInteger("Only integer number allowed").isRequired('This field is required.'),
    booked_from: DateType(),
    booked_to: DateType(),
    booking_source: StringType().isRequired('This field is required'),
    status: StringType().isOneOf(Object.values(Booking.Status), `Can only be one of: ${Object.values(Booking.Status)}`),
    related_people: ArrayType().of(ObjectType()).minLength(1, 'Choose at least one person'),
    related_users: ArrayType().of(ObjectType()),
    details: ObjectType(),
    new_person: ObjectType(),
});

const BookingsContainer = () => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const bookings = useSelector(state => state.bookings);
    const selected_account = useSelector(state => state.base.selected_account);
    const users = useSelector(state => state.users.data);
    const current_user = useSelector(state => state.base.user);
    const peoples = useSelector(state => state.peoples);
    const selected_people_type = useSelector(state => state.base.selected_people_type);

    useEffect(() => {
        dispatch(reset());
        if (selected_account.id) {
            dispatch(getAll(selected_account.id));
            dispatch(getAllUsers(selected_account.id, 0, null, User.Status.ACTIVE));
        }
    }, [dispatch, selected_account]);



    const handlePageChange = useCallback(
        page => {
            dispatch(getAll(selected_account.id, page))
        }, [dispatch, selected_account]
    )

    const handleEdit = useCallback(
        id => {
            dispatch(openEdit(id, selected_account))
        }, [dispatch, selected_account]
    )

    const handleDelete = useCallback(
        id => {
            dispatch(del(id, selected_account.id, bookings.page.curr))
        }, [dispatch, bookings, selected_account]
    )

    /*const handleNew = useCallback(
        () => {
            dispatch(openNew())
        }, [dispatch]
    )*/

    const handleFormChange = useCallback(
        (form, ftype) => {
            setFormError({})
            dispatch(persistChanges(parse(form), ftype))
        }, [dispatch]
    )

    const removeFormRow = useCallback(
        (key, index, ftype) => {
            const data = _.cloneDeep(bookings[`${ftype}`]);
            const arr = _.get(data, key);
            arr.splice(index, 1);
            dispatch(persistChanges(data, ftype))
        }, [dispatch, bookings]
    )

    const addFormRow = useCallback(
        (key, ftype, initial_state = {}) => {
            const data = _.cloneDeep(bookings[`${ftype}`]);
            let arr = _.get(data, key);
            if (arr) {
                arr.push(initial_state);
            } else {
                _.set(data, key, []);
                arr = _.get(data, key)
                arr.push(initial_state);
            }
            dispatch(persistChanges(data, ftype))
        }, [dispatch, bookings]
    )

    const handleSave = useCallback(
        (ftype) => {
            const err = formatErrors(model.check(bookings[`${ftype}`]));
            if (err) {
                setFormError(err)
                return;
            }

            if (ftype === "edit") {
                delete (bookings.edit.item);
                delete (bookings.edit.inventory);
                dispatch(update(bookings.edit, null, selected_account))
            }
        }, [dispatch, bookings, selected_account]
    )

    const handleCancel = useCallback(
        (ftype) => {
            dispatch(closeDrawer('booking_' + ftype))
        }, [dispatch]
    )

    const handleStatusChange = useCallback(
        (booking, status) => {
            let b = _.cloneDeep(unFlattenData(booking));
            let item = _.cloneDeep(b.item);

            delete (b.item);
            delete (b.inventory);

            b.status = status;
            dispatch(update(b, item, selected_account, users, true));
        }, [dispatch, selected_account, users]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )

    const handleSearchPeople = useCallback(
        (event) => {
            let ids = [];
            bookings.edit.related_people.forEach(rp => {
                ids.push(rp.id)
            })
            dispatch(getAllPeoples(selected_people_type.id, selected_account.id, 0, event, People.Status.ACTIVE))
        }, [dispatch, selected_account, selected_people_type, bookings]
    )
    const handleSearchUsers = useCallback(
        (event) => {
            dispatch(getAllUsers(selected_account.id, 0, event, User.Status.ACTIVE))
        }, [dispatch, selected_account]
    )
    const handleNewPerson = () => {
        dispatch(openNewPerson(selected_account.id, selected_people_type.id))
    }

    const handleEditPerson = useCallback(
        id => {
            dispatch(openEditPerson(id))
        }, [dispatch]
    )

    const handleFormChangePeople = useCallback(
        (form, ftype) => {
            let data = _.cloneDeep(parse(form))
            if (_.isArray(data.permissions))
                data.permissions = data.permissions.join(' ')
            setFormError({})
            dispatch(persistChangesPeople(data, ftype))
        }, [dispatch]
    )
    const handleSavePeople = useCallback(
        (ftype) => {
            const err = formatErrors(peopleModel.check(peoples[`${ftype}`]));            
            if (err) {
                setFormError(err)
                return;
            }
            if (ftype === "new")
                dispatch(createPeople(peoples.new, selected_account.id, selected_people_type.id))
            else if (ftype === "edit")
                dispatch(update(peoples.edit, selected_account.id, selected_people_type.id))
        }, [dispatch, peoples, selected_account, selected_people_type]
    )

    const handleCancelPeople = useCallback(
        (ftype) => {
            dispatch(closeDrawer('people_' + ftype))
        }, [dispatch]
    )


    return (
        <>
            <Bookings
                bookings={bookings}
                selected_account={selected_account}
                sources={selected_account.options ? selected_account.options.sources : []}
                selected_people_type={selected_people_type}
                peoples={peoples}
                users={users}
                current_user={current_user}
                handlePageChange={handlePageChange}
                handleEdit={handleEdit}
                //handleNew={handleNew}
                handleDelete={handleDelete}
                handleCancel={handleCancel}
                handleShowModal={handleShowModal}
                handleFormChange={handleFormChange}
                removeFormRow={removeFormRow}
                addFormRow={addFormRow}
                handleSave={handleSave}
                handleStatusChange={handleStatusChange}
                formError={formError}
                model={model}
                handleSearchPeople={handleSearchPeople}
                handleSearchUsers={handleSearchUsers}
                handleEditPerson={handleEditPerson}
                handleFormChangePeople={handleFormChangePeople}
                handleSavePeople={handleSavePeople}
                handleCancelPeople={handleCancelPeople}
                peopleModel={peopleModel}
                handleNewPerson={handleNewPerson}
            />
        </>
    )
}

export default BookingsContainer;