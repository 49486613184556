import { isEmpty } from "lodash";
import moment from 'moment-timezone';
import { flatten, unflatten } from 'flat';

export const isAdmin = (user) => {
    return (user.role === 'SUPER_ADMIN' || user.role === 'ADMIN' || user.role === 'ACCOUNT_ADMIN') ? true : false;
}


export const formatErrors = (obj) => {
    let errors = {};
    Object.keys(obj).forEach(key => {
        if (obj[key].hasError)
            errors[`${key}`] = obj[key].errorMessage
    })
    if (!isEmpty(errors))
        return errors;
    return false;
}

export const flattenData = (data_arr) => {
    let new_arr = []
    for (const el of data_arr) {
        new_arr.push(flatten(el))
    }
    return new_arr;
}

export const unFlattenData = (el) => {
    return unflatten(el);
}

export const parse = (object) => {
    var split = '.';
    var objectTree = {};

    for (var key in object) {
        var keys = key.split(split);

        if (keys.length === 1) {
            objectTree[key] = object[key];
            continue;
        }

        objectTree[keys[0]] = objectTree[keys[0]] || {};
        var _temp = objectTree[keys[0]];

        for (var i = 1; i < keys.length; i++) {
            _temp[keys[i]] = i === keys.length - 1 ? _temp[keys[i]] = object[key] : _temp[keys[i]] || {};
            _temp = _temp[keys[i]];
        }
    }
    return objectTree;
}

export const getTzList = () => {
    const tz_arr = moment.tz.names();
    let tz_arr_obj = [];
    tz_arr.forEach(tz => {
        tz_arr_obj.push({ value: tz, label: tz })
    })
    return tz_arr_obj;
}

export const getUserTz = () => {
    return moment.tz.guess();
}

export const genUserSelectionData = (users) => {
    let new_data = [];
    if (users)
        users.forEach(user => {
            new_data.push({
                label: user.first_name + " " + user.last_name,
                value: user.id
            })
        })
    return new_data;
}

export const getSelectionValues = (selected_values) => {
    let new_data = [];
    if (selected_values)
        selected_values.forEach(sv => {
            new_data.push(sv.id)
        })
    return new_data;
}

export const genObjectsFromIds = (ids, arr_objs) => {
    let new_arr = []
    if (arr_objs)
        arr_objs.forEach(obj => {
            if (ids)
                ids.forEach(id => {
                    if (obj.id === id)
                        new_arr.push(obj)
                })

        })
    return new_arr;
}

export const convertToSelectionData = (array) => {
    let new_data = [];
    array.forEach(el => {
        new_data.push({
            label: el,
            value: el
        })
    })
    return new_data;
}

export const genAvailabilityStatusData = (options, selected_type, booking_allowed) => {
    let new_data = [];
    options.items_types.forEach(type => {
        if (type.id === selected_type)
            type.availabilities_status.forEach(avail => {
                if (avail.booking_allowed === booking_allowed)
                    new_data.push({
                        label: avail.booking_allowed_label,
                        value: avail.booking_allowed_label
                    })
            })
    })
    return new_data;
}

export const getRoleLevel = (current_user, role_types) => {
    let level = 99; //lowest level
    role_types.forEach(rt => {
        if (current_user.role === rt.value && rt.level < level)
            level = rt.level
    })
    return level;
}
export const allowedRoleTypes = (current_user, role_types) => {
    let role_arr = [];
    let level = getRoleLevel(current_user, role_types);
    role_types.forEach(rt => {
        if (rt.level >= level)
            role_arr.push(rt)
    })

    return role_arr;
}

export const formatError = (err) => {
    if (err.response && err.response.data && err.response.data.errors && err.response.data.errors[0].message)
        return err.response.data.errors[0].message
    else
        return err.message
}

export const slugify = (string) => {
    return string
        .toString()
        .trim()
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-") /*  /\-\-+/g, "-"  */
        .replace(/^-+/, "")
        .replace(/-+$/, "");
}

