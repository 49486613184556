import React from 'react';
import { Radio, FlexboxGrid, Panel } from 'rsuite';
import { RadioField, UserSelectField, NumberField } from '../../../_common/form-fields';
import { genUserSelectionData, getSelectionValues, genObjectsFromIds } from '../../../../utils/utils';


const PanelNotifications = ({ formError, users, item, handleFormChange, ftype, ...rest }) => {
    return (
        <Panel
            collapsible
            header="Approvals & Notifications"
            expanded={
                formError.approval ||
                    formError.notify_clients ||
                    formError.notify_users ||
                    formError.max_bookings_allowed
                    ? true : undefined
            }
            {...rest}
        >
            <FlexboxGrid>
                <FlexboxGrid.Item className="pr-4">
                    <RadioField
                        name="approval"
                        className="inline-radio"
                        appearance="picker"
                        inline
                        message={"If set to ‘required’, all booking will be set as pending and approvers will be notified before a booking is approved.If set to ‘not required’ all bookings will become automatically active and notifications of approval will be sent."}
                    >
                        <span>Booking Approval: </span>
                        <Radio value={true}>Required</Radio>
                        <Radio value={false}>Not Required</Radio>
                    </RadioField>
                    <UserSelectField
                        name="notify_users"
                        placeholder="Select Team Members"
                        label="Notify Team Members (optional)"
                        message={"Set who will receive booking notifications."}
                        onChange={(values, e) => {
                            e.preventDefault();
                            item.notify_users = genObjectsFromIds(values, users.data)
                            handleFormChange(item, ftype)
                        }}
                        data={genUserSelectionData(users.data)}
                        value={getSelectionValues(item.notify_users)}
                        size={"sm"}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <RadioField
                        name="notify_clients"
                        className="inline-radio"
                        appearance="picker"
                        inline
                        message={"Send email notification to users who booked. Generic notification emails are: Pending booking, Approved booking, Rescheduling, Canceled"}
                    >
                        <span>Notify Users: </span>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </RadioField>
                    <NumberField
                        name="max_bookings_allowed"
                        type="number"
                        min={0}
                        label="Max Bookings / per user (optional)"
                        message={"Limit number of times someone can book this inventory item. Only active bookings are counted in."}
                        style={{ width: 140 }}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Panel>
    )
}

export default PanelNotifications;