import React from 'react';

import {
    FormGroup, FormControl, ControlLabel, HelpBlock,
    RadioGroup, Radio, SelectPicker, InputGroup, TagPicker,
    DatePicker, Icon, InputNumber, Input, CheckPicker,
    CheckboxGroup, Checkbox
} from 'rsuite';

import moment from 'moment-timezone';

import { convertToSelectionData } from '../../utils/utils'

export const TextField = ({ name, message, label, error, accepter, ...rest }) => {
    return (
        <FormGroup>
            {label ?
                <ControlLabel>{label}</ControlLabel>
                : ""}
            <FormControl name={name} errorMessage={error} accepter={accepter} {...rest} size="sm" />
            {message ?
                <HelpBlock tooltip>{message}</HelpBlock>
                : ""}
        </FormGroup>
    );
}

export const NumberField = ({ name, message, label, value, error, accepter, ...rest }) => {
    return (
        <FormGroup>
            {label ?
                <ControlLabel>{label}</ControlLabel>
                : ""}
            <FormControl name={name} errorMessage={error} value={value} accepter={InputNumber}  {...rest} size="sm" />
            {message ?
                <HelpBlock tooltip>{message}</HelpBlock>
                : ""}
        </FormGroup>
    );
}

export const TextFieldLabelAddOn = ({ name, message, label, error, addon, accepter, children, left, right, inside, ...rest }) => {
    return (
        <FormGroup>
            {label ?
                <ControlLabel className={"d-inline-block"}>{label}</ControlLabel>
                : ""}
            {message ?
                <HelpBlock tooltip className={"ml-1 mt-0"}>{message}</HelpBlock>
                : ""}
            <InputGroup inside className={left ? "addon-left" : "addon-right"}>
                {left ? <InputGroup.Addon>{addon}</InputGroup.Addon> : ""}
                <FormControl name={name} errorMessage={error} accepter={accepter} {...rest} size="sm" />
                {right ? <InputGroup.Addon>{addon}</InputGroup.Addon> : ""}
            </InputGroup>

        </FormGroup>
    );
}


export const RadioField = ({ name, message, label, value, error, ...rest }) => {
    return (
        <FormGroup>
            {label ?
                <ControlLabel>{label}</ControlLabel>
                : ""}
            <FormControl name={name} accepter={RadioGroup} errorMessage={error} value={value} {...rest} />
            {message ?
                <HelpBlock tooltip>{message}</HelpBlock>
                : ""}
        </FormGroup>
    );
}


export const SelectField = ({ name, message, label, error, value, ...rest }) => {
    return (
        <FormGroup>
            {label ?
                <ControlLabel>{label} </ControlLabel>
                : ""}
            <FormControl name={name} accepter={SelectPicker} value={value} errorMessage={error} size="sm" {...rest} />
            {message ?
                <HelpBlock tooltip>{message}</HelpBlock>
                : ""}
        </FormGroup>
    );
}

export const MultiSelectField = ({ name, message, label, error, value, ...rest }) => {
    return (
        <FormGroup>
            {label ?
                <ControlLabel>{label} </ControlLabel>
                : ""}
            <FormControl name={name} accepter={CheckPicker} value={value} errorMessage={error} size="sm" {...rest} />
            {message ?
                <HelpBlock tooltip>{message}</HelpBlock>
                : ""}
        </FormGroup>
    );
}

export const UserSelectField = ({ name, message, label, accepter, error, handleEdit = undefined, ...rest }) => {
    return (
        <FormGroup>
            <ControlLabel>{label} </ControlLabel>
            <FormControl
                name={name}
                accepter={TagPicker}
                block
                style={{ minWidth: 200 }}
                errorMessage={error}
                creatable={false}
                virtualized={false}
                renderMenuItem={(label) => {
                    return (
                        <div>
                            <i className="rs-icon rs-icon-user" /> {label}
                        </div>
                    );
                }}
                renderMenuGroup={(label, item) => {
                    return (
                        <div>
                            <i className="rs-icon rs-icon-group" /> {label} - (
                            {item.children.length})
                        </div>
                    );
                }}
                renderValue={(value, item, selectedElement) => {
                    if (handleEdit)
                        return (
                            <div
                                onClick={() => handleEdit(value)}
                                className={"clickable"}
                            >
                                <span style={{ color: '#575757' }}>
                                    <i className="rs-icon rs-icon-user" />
                                </span>{' '}
                                {selectedElement}
                            </div>
                        );
                    else
                        return (
                            <div>
                                <span style={{ color: '#575757' }}>
                                    <i className="rs-icon rs-icon-user" />
                                </span>{' '}
                                {selectedElement}
                            </div>
                        );
                }}
                {...rest}
            />
            {message ?
                <HelpBlock tooltip>{message}</HelpBlock>
                : ""}
        </FormGroup>
    );

}

export const DateField = ({ name, message, label, error, item, handleFormChange, date_value, date_format, ftype, ...rest }) => {
    const format = date_format === "date" ? "DD/MM/YYYY" : "DD/MM/YYYY HH:mm";
    return (
        <FormGroup className={error ? 'has-error' : ''}>
            {label ?
                <ControlLabel>{label}</ControlLabel>
                : ""}

            <DatePicker
                size="sm"
                format={format}
                ranges={[]}
                preventOverflow={true}
                placeholder={"Select Date"}
                isoWeek={true}
                value={moment(date_value, format).isValid() ? new Date(moment(date_value, format).format()) : null}
                onChange={(v) => {
                    if (moment(v, format).isValid())
                        handleFormChange({ ...item, [`${name}`]: `${moment(v).format(format)}` }, ftype)
                    else
                        handleFormChange({ ...item, [`${name}`]: "" }, ftype)
                }
                }
            />

            <FormControl
                type="hidden"
                size="sm"
                name={name}
                errorMessage={error}
                value={moment(date_value, format).isValid() ? moment(date_value, format).format(format) : moment().format(format)}
                {...rest}
            />
            {message ?
                <HelpBlock tooltip>{message}</HelpBlock>
                : ""}
        </FormGroup>
    );
}


export const DateRangeField = ({ name_from, name_to, message, label, error, item, handleFormChange, date_value_from, date_value_to, date_format, ftype, ...rest }) => {
    const format = date_format === "date" ? "DD/MM/YYYY" : "DD/MM/YYYY HH:mm";
    return (
        <FormGroup className={error ? 'has-error' : ''}>
            {label ?
                <ControlLabel>{label}</ControlLabel>
                : ""}
            <InputGroup>
                <DatePicker
                    key={"dt_from"}
                    size="sm"
                    format={format}
                    preventOverflow={true}
                    ranges={[]}
                    isoWeek={true}
                    placeholder={"Select Date"}
                    value={moment(date_value_from, format).isValid() ? new Date(moment(date_value_from, format).format()) : null}
                    onChange={(v) => {
                        if (moment(v, format).isValid())
                            handleFormChange({ ...item, [`${name_from}`]: `${moment(v).format(format)}` }, ftype)
                        else
                            handleFormChange({ ...item, [`${name_from}`]: "" }, ftype)
                    }}

                />
                <InputGroup.Addon style={{ padding: "4px 10px" }}>
                    <Icon icon="angle-right" />
                </InputGroup.Addon>
                <DatePicker
                    key={"dt_to"}
                    size="sm"
                    format={format}
                    preventOverflow={true}
                    ranges={[]}
                    isoWeek={true}
                    placeholder={"Select Date"}
                    value={moment(date_value_to, format).isValid() ? new Date(moment(date_value_to, format).format()) : null}
                    onChange={(v) => {
                        if (moment(v, format).isValid())
                            handleFormChange({ ...item, [`${name_to}`]: `${moment(v).format(format)}` }, ftype)
                        else
                            handleFormChange({ ...item, [`${name_to}`]: "" }, ftype)
                    }}
                />

                <FormControl
                    type="hidden"
                    size="sm"
                    name={name_from}
                    errorMessage={error}
                    value={moment(date_value_from, format).isValid() ? moment(date_value_from, format).format(format) : moment().format(format)}
                    {...rest}
                />
                <FormControl
                    type="hidden"
                    size="sm"
                    name={name_to}
                    errorMessage={error}
                    value={moment(date_value_to, format).isValid() ? moment(date_value_to, format).format(format) : moment().format(format)}
                    {...rest}
                />
            </InputGroup>
            {message ?
                <HelpBlock tooltip>{message}</HelpBlock>
                : ""}
        </FormGroup>
    );
}



export const TimeRangeField = ({ name, message, label, error, addon, inside, item, time_range, handleFormChange, ftype, ...rest }) => {
    const time_from = time_range && moment(time_range.split("-")[0], "HH:mm").isValid() ? time_range.split("-")[0] : null;
    const time_to = time_range && moment(time_range.split("-")[1], "HH:mm").isValid() ? time_range.split("-")[1] : null;

    return (
        <FormGroup>
            {label ?
                <ControlLabel className={"d-inline-block"}>{label}</ControlLabel>
                : ""}
            <InputGroup>
                <DatePicker
                    size="sm"
                    format="HH:mm"
                    preventOverflow={true}
                    ranges={[]}
                    appearance="subtle"
                    placeholder={"Select Time"}
                    value={time_from ? new Date(moment(time_from, "HH:mm").format()) : null}
                    onChange={(v) => {
                        handleFormChange({ ...item, [`${name}`]: `${moment(v).format("HH:mm")}-${time_to}` }, ftype)
                    }}

                />
                <InputGroup.Addon style={{ padding: "4px 10px" }}>
                    <Icon icon="angle-right" />
                </InputGroup.Addon>
                <DatePicker
                    size="sm"
                    format="HH:mm"
                    preventOverflow={true}
                    ranges={[]}
                    appearance="subtle"
                    placeholder={"Select Time"}
                    value={time_to ? new Date(moment(time_to, "HH:mm").format()) : null}
                    onChange={(v) => {
                        handleFormChange({ ...item, [`${name}`]: `${time_from}-${moment(v).format("HH:mm")}` }, ftype)
                    }}

                />
            </InputGroup>
            <FormControl type="hidden" name={name} errorMessage={error} size="sm" value={time_from && time_to ? `${time_from}-${time_to}` : ""} {...rest} />
            {message ?
                <HelpBlock tooltip>{message}</HelpBlock>
                : ""}
        </FormGroup>
    );
}


export const WeekdayRangeField = ({ name_from, name_to, message, label, error, item, handleFormChange, value_from, value_to, weekdays_data, ftype, ...rest }) => {

    return (
        <FormGroup className={error ? 'has-error' : ''}>
            {label ?
                <ControlLabel>{label}</ControlLabel>
                : ""}
            <InputGroup>
                <SelectPicker
                    searchable={false}
                    preventOverflow={true}
                    size="sm"
                    data={weekdays_data}
                    placeholder={"Select Weekday"}
                    value={value_from}
                    onChange={(v) => { handleFormChange({ ...item, [`${name_from}`]: v }, ftype) }}
                />
                <InputGroup.Addon style={{ padding: "4px 10px" }}>
                    <Icon icon="angle-right" />
                </InputGroup.Addon>

                <SelectPicker
                    searchable={false}
                    preventOverflow={true}
                    size="sm"
                    data={weekdays_data}
                    placeholder={"Select Weekday"}
                    value={value_to}
                    onChange={(v) => { handleFormChange({ ...item, [`${name_to}`]: v }, ftype) }}
                />

                <FormControl
                    type="hidden"
                    size="sm"
                    name={name_from}
                    errorMessage={error}
                    value={value_from}
                    {...rest}
                />
                <FormControl
                    type="hidden"
                    size="sm"
                    name={name_to}
                    errorMessage={error}
                    value={value_to}
                    {...rest}
                />
            </InputGroup>
            {message ?
                <HelpBlock tooltip>{message}</HelpBlock>
                : ""}
        </FormGroup>
    );
}




export const CustomField = ({ name, ctype, label, placeholder, selection_values, tooltip, default_value, value, error, ...rest }) => {

    return (
        <FormGroup className={error ? 'has-error' : ''}>

            {label && ctype !== "HIDDEN" ?
                <ControlLabel>{label}</ControlLabel>
                : ""}
            <FormControl
                name={name}
                size="sm"
                ctype={ctype}
                data={convertToSelectionData(selection_values)}
                //defaultValue={default_value}
                accepter={CFieldAccepter}
                errorMessage={error}
                value={formatCFValue(value, ctype)}
                placeholder={placeholder}
                //onChange={}
                {...rest}

            />
            {tooltip && ctype !== "HIDDEN" ?
                <HelpBlock tooltip>{tooltip}</HelpBlock>
                : ""}
        </FormGroup>
    );
}


const CFieldAccepter = ({ ...props }) => {
    switch (props.ctype) {
        case "TEXT":
            return <Input {...props} />
        case "TEXTAREA":
            return <Input componentClass="textarea" rows={3} {...props} />
        case "NUMBER":
            return <InputNumber value={"222222"} {...props} />
        case "DATE":
            return <DatePicker
                preventOverflow={true}
                ranges={[]}
                format={"DD/MM/YYYY"}
                {...props}
            />
        case "DATETIME":
            return <DatePicker
                preventOverflow={true}
                ranges={[]}
                format={"DD/MM/YYYY HH:mm"}
                {...props}
            />
        case "TIME":
            return <DatePicker
                preventOverflow={true}
                ranges={[]}
                format={"HH:mm"}
                {...props}
            />
        case "SELECT":
            return <SelectPicker  {...props} />
        case "MULTISELECT":
            return <CheckPicker {...props} />
        case "CHECKBOX":
            return (
                <CheckboxGroup
                    inline
                    name={props.name}
                    onChange={props.onChange}
                    value={props.value}
                >
                    {props.data.map((d, index) => (
                        <Checkbox key={"checkbox" + index} value={d.value}>{d.value}</Checkbox>
                    ))}
                </CheckboxGroup>
            )
        case "RADIO":
            return (
                <RadioGroup
                    inline
                    name={props.name}
                    onChange={props.onChange}
                    value={props.value}
                >
                    {props.data.map((d, index) => (
                        <Radio key={"checkbox" + index} value={d.value}>{d.value}</Radio>
                    ))}
                </RadioGroup>
            )
        case "HIDDEN":
            return <Input type="hidden" {...props} />
        default:
            return <Input {...props} />

    }
};

const formatCFValue = (value, ctype) => {
    switch (ctype) {
        case "TEXT":
        case "TEXTAREA":
        case "RADIO":
        case "HIDDEN":
        case "SELECT":
            return value ? value : "";
        case "NUMBER":
            return value ? value : undefined;
        case "DATE":
        case "DATETIME":
        case "TIME":
            return value ? new Date(value) : undefined;
        case "MULTISELECT":
        case "CHECKBOX":
            return Array.isArray(value) ? value : []
        default:
            return value ? value : "";
    }
}



