import React from 'react';
import { Range } from '@mobiscroll/react_v4';
//import '@mobiscroll/react_v4/dist/css/mobiscroll.min.css';
const CalendarRangePicker = ({ data, handleInit, handleMonthChange, selected_range, min_range, max_range, handleSelectedRange, handleStartEndFocus }) => {

    return (
        <>
            <Range
                labels={data.labels}
                data={data.available}
                invalid={data.disabled}
                display="inline"
                theme='material'
                firstDay={1}
                type="hidden"
                min={data.min}
                onSet={handleSelectedRange}
                onSetDate={handleStartEndFocus}
                onInit={handleInit}
                onPageChange={handleMonthChange}
                dateFormat={"dd/mm/yy"}
                timeFormat={"HH:mm"}
                showOuterDays={false}
                outerMonthChange={false}
                defaultValue={[selected_range.start, selected_range.end]}
                minRange={min_range ? parseInt(min_range) * 86400000 : undefined} //86400000-> 1day
                maxRange={min_range ? parseInt(max_range) * 86400000 : undefined} //86400000-> 1day
                calendarHeight={540}
            />


        </>
    )
}




export default CalendarRangePicker;