import React, { useCallback } from 'react';
import {
    Panel, FlexboxGrid, IconButton, Icon, Badge,
    Button, Progress, Placeholder
} from 'rsuite';
import { Item as ItemConst } from './items-const'
import Drawer from '../../_base/drawer/drawer-container';
import Modal from '../../_base/modal/modal-container';
import moment from 'moment-timezone';
import { getUserTz } from '../../../utils/utils'
import ItemsForm from './items-form';
import ItemsEmbedForm from './items-embed-form';
import CalendarContainer from '../calendars/calendars-container';

const Item = ({
    items, inv_id, inventory, current_user,
    handleDeleteItem, handleEditItem,
    handleShowModal, selected_account,
    handleFormChange, removeFormRow,
    addFormRow, handleSaveItem,
    handleCancelItem, users, itemModel,
    formError, handleBooking,
    handleGenerateEmbed, handleEditEmbed,
    embedModel, handleCancelEmbed
}) => {

    return (
        <>
            {inventory.items && inventory.items.length > 0 ? inventory.items.map((item, index) => (
                <div key={"item" + index}>
                    <Panel className="inventory-item show-action-icons-hover bg-white ml-2 mb-2" shaded bordered bodyFill key={"inv" + index}>
                        <Panel header={
                            <ItemPanelHeader
                                item={item}
                                handleEditItem={handleEditItem}
                                current_user={current_user}
                            />
                        }>
                            <div className="info">
                                {item.description ?
                                    <p className="pb-2">
                                        <small>{item.description}</small>
                                    </p>
                                    : ""}
                                {item.display.current_availability.visible ?
                                    item.reports ?
                                        <>
                                            <Badge className={"current-availability mb-2"} content={item.reports.current_availability} title="Current Availability" />
                                        </>
                                        : <Placeholder.Paragraph className="pb-2" rows={1} active={true} />
                                    : ""}
                                {item.display.availability_next_x_days_in_precentage.visible ?
                                    <FlexboxGrid className="pb-2">
                                        <FlexboxGrid.Item style={{ lineHeight: 1 }} >
                                            <small>
                                                Booked
                                                <br />
                                                <small>
                                                    (next {item.display.availability_next_x_days_in_precentage.days + (item.display.total_bookings_past_x_days.days > 1 ? " days" : " day")})
                                                </small>
                                            </small>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item className="flex-grow-1 pl-2" style={{ paddingTop: 6 }}>
                                            <Progress.Line
                                                percent={item.reports ? 100 - Math.round(item.reports.availability_next_x_days_in_precentage) : 0}
                                                strokeWidth={4}
                                                status='active'
                                            />
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                    : ""}

                                {item.display.next_availability.visible ?
                                    item.reports ?
                                        <p>
                                            <small>
                                                Next available date:
                                                {item.calendar_type === ItemConst.CalendarType.TIME_SLOTS && moment(item.reports.next_availability).isValid() ?
                                                    <strong> {moment.tz(item.reports.next_availability, getUserTz()).format("DD/MM/YYYY HH:mm")}</strong>
                                                    : moment(item.reports.next_availability).isValid() ?
                                                        <strong> {moment.tz(item.reports.next_availability, getUserTz()).format("DD/MM/YYYY")}</strong>
                                                        : " - "
                                                }
                                            </small>
                                        </p>
                                        :
                                        <Placeholder.Paragraph rows={1} active={true} />
                                    : ""}

                                {item.display.all_time_bookings.visible ?
                                    item.reports ?
                                        <p>
                                            <small>All time bookings: <strong>{item.reports.all_time_bookings}</strong> </small>
                                        </p>
                                        :
                                        <Placeholder.Paragraph rows={1} active={true} />
                                    : ""}

                                {item.display.total_bookings_past_x_days.visible ?
                                    item.reports ?
                                        <p>
                                            <small>
                                                Total bookings (last {item.display.total_bookings_past_x_days.days + (item.display.total_bookings_past_x_days.days > 1 ? " days" : " day")}):
                                            <strong> {item.reports.total_bookings_past_x_days}</strong>
                                            </small>
                                        </p>
                                        :
                                        <Placeholder.Paragraph rows={1} active={true} />
                                    : ""}


                            </div>
                            <FlexboxGrid className="action-icons action-bar">
                                <FlexboxGrid.Item className="flex-grow-1">
                                    {current_user.permissions && current_user.permissions.includes('obi:items:delete') ?
                                        <IconButton
                                            circle
                                            appearance="subtle"
                                            size="xs"
                                            color="red"
                                            onClick={() => handleShowModal("item_delete" + item.id)}
                                            title={"Delete Item"}
                                            icon={<Icon icon="trash2" />} />
                                        : ""}

                                    {current_user.permissions && current_user.permissions.includes('obi:items:update') ?
                                        <IconButton
                                            circle
                                            appearance="subtle"
                                            size="xs"
                                            //color="gray"
                                            onClick={() => handleEditEmbed(item)}
                                            title={"Generate Embed Code"}
                                            icon={<Icon icon="code" />} />
                                        : ""}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    {current_user.permissions && current_user.permissions.includes('obi:items:update') ?
                                        <IconButton
                                            circle
                                            appearance="subtle"
                                            size="xs"
                                            color="orange"
                                            onClick={() => handleEditItem(item)}
                                            title={"Edit Item"}
                                            icon={<Icon icon="edit2" />} />
                                        : ""}
                                    {current_user.permissions && current_user.permissions.includes('obi:bookings:create') && item.status === ItemConst.Status.ACTIVE ?
                                        <IconButton
                                            circle
                                            appearance="subtle"
                                            size="xs"
                                            color="green"
                                            onClick={() => handleBooking(item)}
                                            title={"Add New Booking"}
                                            icon={<Icon icon="calendar-plus-o" />} />
                                        : ""}
                                    <ItemDeleteModal
                                        handleDeleteItem={handleDeleteItem}
                                        item={item}
                                        inv_id={inv_id}
                                    />
                                    <ItemBookModal
                                        item={item}
                                    />
                                    <ItemEmbedModal
                                        item={item}
                                        items={items}
                                        selected_account={selected_account}
                                        handleFormChange={handleFormChange}
                                        handleGenerateEmbed={handleGenerateEmbed}
                                        handleCancelEmbed={handleCancelEmbed}
                                        embedModel={embedModel}
                                        formError={formError}
                                    />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </Panel>
                    </Panel>
                    <Drawer
                        toptitle={"Inventory Item"}
                        title={items.edit.name}
                        subtitle={"Last edited:  " + moment(items.edit.updated_at).format("DD/MM/YYYY HH:mm")}
                        size={"lg"}
                        className={"items"}
                        type={"edit" + item.id}
                        handleAction={() => handleSaveItem("edit")}
                        handleCloseAction={() => handleCancelItem("edit")}
                        loading={items.loading}
                    >
                        <ItemsForm
                            item={items.edit}
                            options={selected_account.options}
                            handleFormChange={handleFormChange}
                            removeFormRow={removeFormRow}
                            addFormRow={addFormRow}
                            ftype={"edit"}
                            users={users}
                            model={itemModel}
                            formError={formError}
                        />
                    </Drawer>
                </div>
            )) : <FlexboxGrid.Item className={"py-3 text-center flex-grow-1"}>
                    {"No items found for this inventory."}
                </FlexboxGrid.Item>
            }



            <Drawer
                title={"New Inventory Item"}
                size={"lg"}
                className={"items"}
                type={"new"}
                handleAction={() => handleSaveItem("new")}
                handleCloseAction={() => handleCancelItem("new")}
                loading={items.loading}
            >
                <ItemsForm
                    item={items.new}
                    options={selected_account.options}
                    handleFormChange={handleFormChange}
                    removeFormRow={removeFormRow}
                    addFormRow={addFormRow}
                    ftype={"new"}
                    handleShowModal={handleShowModal}
                    users={users}
                    model={itemModel}
                    formError={formError}
                />
            </Drawer>
        </>
    );
}

const ItemEmbedModal = ({ item, items, selected_account, handleFormChange, embedModel, formError, handleGenerateEmbed, handleCancelEmbed }) => {
    return (
        <Modal
            type={"embed" + item.id}
            title={"Generate Embed Code"}
            size="sm"
            overflow={false}
            hideFooter={true}
            //handleCloseAction={handleCancelEmbed}
            handleCloseAction={
                useCallback(() => {
                    handleCancelEmbed(item)
                }, [item, handleCancelEmbed])
            }
        >
            <ItemsEmbedForm
                item={items.embed}
                sources={selected_account.options.sources}
                handleFormChange={handleFormChange}
                ftype={"embed"}
                model={embedModel}
                formError={formError}
                handleGenerateEmbed={handleGenerateEmbed}
            />
        </Modal>
    )
}

const ItemBookModal = ({ item }) => {
    return (
        <Modal
            type={"booking_item" + item.id}
            title={"Book " + item.slot_label}
            size="sm"
            overflow={false}
            hideFooter={true}
        >
            <CalendarContainer />
        </Modal>
    )
}

const ItemDeleteModal = ({ item, handleDeleteItem, inv_id }) => {
    return (
        <Modal
            type={"item_delete" + item.id}
            title={"Delete"}
            handleAction={useCallback(() => {
                handleDeleteItem(inv_id, item.id)
            }, [inv_id, item, handleDeleteItem])}
            size="xs"
        >
            <div>
                <p>{`Are you sure you want to delete ${item.name}?`}</p>
                <p><strong>{`This will delete all bookings connected to this inventory item!!!`}</strong></p>
            </div>
        </Modal>
    )
}

const ItemPanelHeader = ({ item, handleEditItem, current_user }) => {
    return (
        <>
            <Badge className={"status-badge " + item.status.toLowerCase()} title="Item Status" />
            {/*<div className="item-type">{item.item_type}Test</div>*/}
            {current_user.permissions && current_user.permissions.includes('obi:items:update') ?
                <Button className="p-0 item-title" appearance="link" onClick={() => { handleEditItem(item) }}>{item.name}</Button>
                :
                <div>{item.name}</div>
            }
        </>
    )
}

export default Item;