export const LOADING_USERS = 'LOADING_USERS'
export const PERSIST_CHANGES_USERS = 'PERSIST_CHANGES_USERS'

export const GET_USERS = 'GET_USERS'
export const GET_USER = 'GET_USER'

export const NEW_USER = 'NEW_USER'
export const SET_ACCOUNT_ID = 'SET_ACCOUNT_ID'

export const User = {
    Roles: {
        SUPER_ADMIN: 'SUPER_ADMIN',
        ADMIN: 'ADMIN',
        ACCOUNT_ADMIN: 'ACCOUNT_ADMIN',
        OPERATOR: 'OPERATOR',
        INTEGRATION: 'INTEGRATION'
    },
    RoleTypes: [
        {
            value: "SUPER_ADMIN",
            label: "Super Admin",
            default_permissions:"obi:accounts:read obi:accounts:create obi:accounts:update obi:accounts:delete obi:inventories:read obi:inventories:create obi:inventories:update obi:inventories:delete obi:items:read obi:items:create obi:items:update obi:items:delete obi:bookings:read obi:bookings:create obi:bookings:update obi:bookings:delete obi:peoples:read obi:peoples:create obi:peoples:update obi:peoples:delete obi:users:read obi:users:create obi:users:update obi:users:delete",
            level: 0
        }, {
            value: "ADMIN",
            label: "Admin",
            default_permissions:"obi:accounts:read obi:accounts:create obi:accounts:update obi:inventories:read obi:inventories:create obi:inventories:update obi:inventories:delete obi:items:read obi:items:create obi:items:update obi:items:delete obi:bookings:read obi:bookings:create obi:bookings:update obi:bookings:delete obi:peoples:read obi:peoples:create obi:peoples:update obi:peoples:delete obi:users:read obi:users:create obi:users:update",
            level: 1
        }, {
            value: "ACCOUNT_ADMIN",
            label: "Account Admin",
            default_permissions:"obi:accounts:read obi:accounts:update obi:inventories:read obi:inventories:create obi:inventories:update obi:inventories:delete obi:items:read obi:items:create obi:items:update obi:items:delete obi:bookings:read obi:bookings:create obi:bookings:update obi:bookings:delete obi:peoples:read obi:peoples:create obi:peoples:update obi:peoples:delete obi:users:read obi:users:create obi:users:update",
            level: 2
        }, {
            value: "OPERATOR",
            label: "Operator",
            default_permissions:"obi:accounts:read obi:inventories:read obi:items:read obi:items:update obi:peoples:read obi:peoples:create obi:peoples:update obi:peoples:delete obi:bookings:read obi:bookings:create obi:bookings:update obi:bookings:delete obi:users:read", 
            level: 3
        }
    ],
    Status: {
        ACTIVE: 'ACTIVE',
        PENDING: 'PENDING',
        DISABLED: 'DISABLED',
        ARCHIVED: 'ARCHIVED'
    }
}



