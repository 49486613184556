import React from 'react';
import { Radio, FlexboxGrid, Panel } from 'rsuite';
import { TextField, RadioField, SelectField } from '../../../_common/form-fields';
import { getTzList, getUserTz } from '../../../../utils/utils';
import { Item } from '../items-const'


const PanelGeneral = ({ item, formError, options, ...rest }) => {
    return (
        <Panel header="General"
            collapsible
            defaultExpanded
            expanded={
                formError.item_type ||
                    formError.name ||
                    formError.status ||
                    formError.tz ||
                    formError.description
                    ? true : undefined
            }
            {...rest}
        >
            <FlexboxGrid>
                <FlexboxGrid.Item className="pr-4">
                    <TextField name="name" label="Name" />
                    <FlexboxGrid>
                        <FlexboxGrid.Item>
                            <SelectField
                                name="item_type_id"
                                label={"Type"}
                                placeholder="Select item type"
                                data={options.items_types}
                                valueKey={"id"}
                                labelKey={"name"}
                                value={item.item_type_id}
                                cleanable={false}
                                message={"Item types are globaly set for your account. If you don't see required type, please contact your account admin."}
                            />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className={"pl-3"}>
                            <SelectField
                                name="tz"
                                label={"Time Zone"}
                                placeholder="Select time zone"
                                data={getTzList()}
                                value={item.tz ? item.tz : getUserTz()}
                                cleanable={false}
                                message={"Select time zone based on item location. Example: If you doing setup for item located in Sydney, set tz Australia/Sydney."}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>

                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="pt-2">
                    <RadioField name="status" className="inline-radio" appearance="picker" inline>
                        <span>Status: </span>
                        <Radio value={Item.Status.ACTIVE}>Active</Radio>
                        <Radio value={Item.Status.DRAFT}>Draft</Radio>
                        <Radio value={Item.Status.DISABLED}>Disabled</Radio>
                        <Radio value={Item.Status.ARCHIVED}>Archived</Radio>
                    </RadioField>
                    <TextField name="description" label="Short description" componentClass="textarea" rows={3} value={item.description} />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Panel>
    )
}

export default PanelGeneral;