import {
    LOADING_PEOPLES,
    GET_PEOPLES,
    GET_PEOPLE,
    PERSIST_CHANGES_PEOPLES,
    SET_ACCOUNT_ID,
    SET_PEOPLE_TYPE_NEW,
    People
} from './peoples-const';

const PEOPLE_INITIAL_STATE = {
    account_id: 0,
    types: [],
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    status: People.Status.ACTIVE,
    related_people: null,
    related_users: null,
    details: {}
}

const INITIAL_STATE = {
    data: [],
    page: {
        curr: 0,
        items: 0,
        pages: 0
    },
    loading: false,
    filters: [],
    sorters: [],
    s: "",
    new: PEOPLE_INITIAL_STATE,
    edit: PEOPLE_INITIAL_STATE
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_PEOPLES:
            return {
                ...state,
                data: action.payload.data,
                page: action.payload.page,
                filters: action.payload.filters,
                sorters: action.payload.sorters
            };
        case GET_PEOPLE:
            return {
                ...state,
                edit: action.payload
            };

        case PERSIST_CHANGES_PEOPLES:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case LOADING_PEOPLES:
            return {
                ...state,
                loading: action.payload
            };
        case SET_ACCOUNT_ID:
            return {
                ...state,
                new: { ...state.new, account_id: action.payload }
            };
        case SET_PEOPLE_TYPE_NEW:
            return {
                ...state,
                new: { ...state.new, types: action.payload }
            };
        default:
            return state;
    }
}