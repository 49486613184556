import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getAvailabilities, changeMonth, changeRange, setHelpers, createPerson } from './calendars-action'
import { create, persistChanges, reset as resetBooking } from '../../bookings/bookings-action'
import { showModal, closeModal } from '../../_base/modal/modal-action';
import { closeDrawer } from '../../_base/drawer/drawer-action';
import { setPeopleType } from '../../_base/base-action'
import { getAll as getAllUsers } from '../../users/users-action'
import {
    getAll as getAllPeoples,
    openNew as openNewPerson,
    openEdit as openEditPerson,
    persistChanges as persistChangesPeople
} from '../../peoples/peoples-action'

import { User } from "../../users/users-const";
import { People } from "../../peoples/peoples-const";

import Calendars from './calendars';

import { formatErrors, parse, getUserTz } from '../../../utils/utils'

import { model } from '../../bookings/bookings-container'
import { model as peopleModel } from '../../peoples/peoples-container'

import moment from 'moment-timezone';

import _ from 'lodash'

const CalendarsContainer = () => {

    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const item = useSelector(state => state.calendars.item);
    const calendar_data = useSelector(state => state.calendars.calendar_data);
    const new_booking = useSelector(state => state.bookings.new);
    const selected_month = useSelector(state => state.calendars.selected_month);
    const selected_range = useSelector(state => state.calendars.selected_range);
    const helpers = useSelector(state => state.calendars.helpers);
    const selected_account = useSelector(state => state.base.selected_account);
    const users = useSelector(state => state.users.data);
    const peoples = useSelector(state => state.peoples);
    const selected_people_type = useSelector(state => state.base.selected_people_type);


    useEffect(() => {
        if (item.item_type_id && selected_account) {
            selected_account.options.items_types.forEach(it => {
                if (it.id === item.item_type_id) {
                    dispatch(setPeopleType(it.people_type_id, selected_account));
                }
            })
        }
    }, [dispatch, selected_account, item]);


    const handleGetAvailabilities = useCallback(
        (item, selected_month) => {
            dispatch(getAvailabilities(
                item.id,
                `${moment.tz(selected_month.start, getUserTz()).add(-1, 'days').format("DD/MM/YYYY")}-${moment.tz(selected_month.end, getUserTz()).add(1, 'days').format("DD/MM/YYYY")}`,
                getUserTz()
            ));
        }, [dispatch]
    )

    const handleInit = useCallback(
        (event, inst) => {
            inst.navigate(selected_month.start)
        }, [selected_month]
    )

    const handleMonthChange = useCallback(
        (event, inst) => {
            if (!moment.tz(event.firstDay, getUserTz()).startOf('month').isSame(moment(selected_month.start))) {
                const new_month = {
                    start: moment.tz(event.firstDay, getUserTz()).startOf('month').format(),
                    end: moment.tz(event.firstDay, getUserTz()).endOf('month').format()
                }
                dispatch(changeMonth(new_month));
                handleGetAvailabilities(item, new_month);
            }

        }, [dispatch, item, selected_month, handleGetAvailabilities]
    )


    const handleInitRange = useCallback(
        (event, inst) => {
            if (selected_range.start && !selected_range.end) {
                inst.setActiveDate('end')
            }
            inst.setVal([selected_range.start, selected_range.end])
            inst.navigate(selected_month.start)
        }, [selected_month, selected_range]
    )

    const handleMonthChangeRangeCalendar = useCallback(
        (event, inst) => {
            if (!moment.tz(event.firstDay, getUserTz()).startOf('month').isSame(moment(selected_month.start)) && !helpers.block_month_change) {
                const new_month = {
                    start: moment.tz(event.firstDay, getUserTz()).startOf('month').format(),
                    end: moment.tz(event.firstDay, getUserTz()).endOf('month').format()
                }
                dispatch(changeMonth(new_month));
                handleGetAvailabilities(item, new_month);
            }
        }, [dispatch, item, selected_month, handleGetAvailabilities, helpers]
    )

    const handleStartEndFocus = useCallback(
        (event, inst) => {
            if (event.active === "end" && event.control === "calendar") {
                dispatch(setHelpers({ block_month_change: true }))
                inst.setVal([selected_range.start, event.date])

            }
        }, [dispatch, selected_range]
    )

    const handleCancelBooking = useCallback(
        (type) => {
            dispatch(changeRange({ start: null, end: null }));
            dispatch(closeModal(type));
            dispatch(resetBooking());
            dispatch(setHelpers({ block_month_change: false }));
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )

    const handleBookRange = useCallback(
        (start, end, item) => {
            start = moment(start, "DD/MM/YYYY").startOf('day');
            end = moment(end, "DD/MM/YYYY").endOf('day');
            //time adjustment because calendar is forcing time with 00:00 and override actual availabilities
            const calendar_tz_offset = moment.tz.zone(item.tz).utcOffset(moment())
            const user_tz_offset = moment.tz.zone(getUserTz()).utcOffset(moment())
            let offset = 0;
            if (calendar_tz_offset < user_tz_offset)
                offset = calendar_tz_offset - user_tz_offset + 1440;
            else
                offset = calendar_tz_offset - user_tz_offset;
            dispatch(persistChanges({
                ...new_booking,
                item_id: item.id,
                booked_from: moment(start, "DD/MM/YYYY").add(offset, 'minutes').format(),
                booked_to: moment(end, "DD/MM/YYYY").add(offset, 'minutes').format()
            }, 'new'))
            dispatch(getAllUsers(selected_account.id, 0, null, User.Status.ACTIVE))
            dispatch(getAllPeoples(selected_people_type.id, selected_account.id, 0, null, People.Status.ACTIVE))
            handleShowModal('new_booking');
        }, [dispatch, handleShowModal, new_booking, selected_account, selected_people_type]
    )

    const handleSelectedRange = useCallback(
        (event, inst) => {
            const sr = event.valueText.split(" - ");
            dispatch(changeRange({
                start: moment.tz(sr[0], "DD/MM/YYYY", getUserTz()).startOf('day').format(),
                end: sr[1] ? moment.tz(sr[1], "DD/MM/YYYY", getUserTz()).endOf('day').format() : null
            }));
            if (!sr[1])
                inst.setActiveDate('end')

            if (sr[0] && sr[1]) {
                handleBookRange(sr[0], sr[1], item)
            }
        }, [dispatch, item, handleBookRange]
    )


    const handleFormChange = useCallback(
        (form) => {
            setFormError({})
            dispatch(persistChanges(parse(form), 'new'))
        }, [dispatch]
    )

    const handleSave = useCallback(
        () => {
            const err = formatErrors(model.check(new_booking));
            if (err) {
                setFormError(err)
                return;
            }
            dispatch(create(new_booking, item, selected_account, users, selected_month))
            dispatch(changeRange({ start: null, end: null }))
            //handleGetAvailabilities(item, selected_month);
            dispatch(closeModal("new_booking"))
            dispatch(setHelpers({ block_month_change: false }))
            dispatch(resetBooking());
        }, [dispatch, new_booking, item, selected_account, selected_month, users]
    )

    const handleBook = useCallback(
        (event) => {
            //time adjustment because calendar is forcing time with 00:00 and override actual availabilities
            const calendar_tz_offset = moment.tz.zone(item.tz).utcOffset(moment())
            const user_tz_offset = moment.tz.zone(getUserTz()).utcOffset(moment())
            let offset = 0;
            if (calendar_tz_offset < user_tz_offset)
                offset = calendar_tz_offset - user_tz_offset + 1440;
            else
                offset = calendar_tz_offset - user_tz_offset;

            dispatch(persistChanges({
                ...new_booking,
                item_id: item.id,
                booked_from: moment.tz(event.date, getUserTz()).add(offset, 'minutes').format(),
                booked_to: null
            }, 'new'))

            dispatch(getAllUsers(selected_account.id, 0, null, User.Status.ACTIVE))
            dispatch(getAllPeoples(selected_people_type.id, selected_account.id, 0, null, People.Status.ACTIVE))
            handleShowModal('new_booking');

        }, [dispatch, handleShowModal, item, new_booking, selected_account, selected_people_type]
    )

    const handleBookTimeSlot = useCallback(
        (event) => {
            if (event.event.start && !event.event.disabled && event.event.available_slots > 0) {
                dispatch(persistChanges({
                    ...new_booking,
                    item_id: item.id,
                    booked_from: event.event.start,
                    booked_to: event.event.end
                }, 'new'))
                dispatch(getAllUsers(selected_account.id, 0, null, User.Status.ACTIVE))
                dispatch(getAllPeoples(selected_people_type.id, selected_account.id, 0, null, People.Status.ACTIVE))
                handleShowModal('new_booking');
            }
        }, [dispatch, handleShowModal, item, new_booking, selected_account, selected_people_type]
    )

    const handleSearchPeople = useCallback(
        (s) => {
            let ids = [];
            new_booking.related_people.forEach(rp => {
                ids.push(rp.id)
            })
            dispatch(getAllPeoples(selected_people_type.id, selected_account.id, 0, s, People.Status.ACTIVE, ids))
        }, [dispatch, selected_account, selected_people_type, new_booking]
    )
    const handleSearchUsers = useCallback(
        (event) => {
            dispatch(getAllUsers(selected_account.id, 0, event, User.Status.ACTIVE))
        }, [dispatch, selected_account]
    )

    const handleNewPerson = () => {
        dispatch(openNewPerson(selected_account.id, selected_people_type))
    }

    const handleEditPerson = useCallback(
        id => {
            dispatch(openEditPerson(id))
        }, [dispatch]
    )

    const handleFormChangePeople = useCallback(
        (form, ftype) => {
            let data = _.cloneDeep(parse(form))
            if (_.isArray(data.permissions))
                data.permissions = data.permissions.join(' ')
            setFormError({})
            dispatch(persistChangesPeople(data, ftype))
        }, [dispatch]
    )
    const handleSavePeople = useCallback(
        (ftype) => {
            const err = formatErrors(peopleModel.check(peoples[`${ftype}`]));
            if (err) {
                setFormError(err)
                return;
            }

            if (ftype === "new")
                dispatch(createPerson(peoples.new, selected_account.id, selected_people_type.id, new_booking))

        }, [dispatch, peoples, selected_account, selected_people_type, new_booking]
    )

    const handleCancelPeople = useCallback(
        (ftype) => {
            dispatch(closeDrawer('people_' + ftype))
        }, [dispatch]
    )

    return (
        <Calendars
            calendar_data={calendar_data}
            item={item}
            selected_range={selected_range}
            selected_account={selected_account}
            selected_people_type={selected_people_type}
            users={users}
            peoples={peoples}
            handleInit={handleInit}
            handleInitRange={handleInitRange}
            handleSelectedRange={handleSelectedRange}
            handleMonthChange={handleMonthChange}
            handleMonthChangeRangeCalendar={handleMonthChangeRangeCalendar}
            handleBookRange={handleBookRange}
            handleStartEndFocus={handleStartEndFocus}
            handleCancelBooking={handleCancelBooking}
            sources={selected_account.options.sources}
            new_booking={new_booking}
            handleFormChange={handleFormChange}
            handleSave={handleSave}
            model={model}

            formError={formError}
            handleBook={handleBook}
            handleBookTimeSlot={handleBookTimeSlot}
            handleSearchPeople={handleSearchPeople}
            handleSearchUsers={handleSearchUsers}

            handleNewPerson={handleNewPerson}
            handleEditPerson={handleEditPerson}
            modelPeople={peopleModel}
            handleFormChangePeople={handleFormChangePeople}
            handleCancelPeople={handleCancelPeople}
            handleSavePeople={handleSavePeople}
        />
    )

}

export default CalendarsContainer;