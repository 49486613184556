import obiApi from '../services';

import { SET_NOTIFICATION } from "../_base/base-const";
import { User } from "../users/users-const";
import { People } from "../peoples/peoples-const";
import {
    LOADING_BOOKINGS, GET_BOOKINGS, GET_BOOKING,
    PERSIST_CHANGES_BOOKINGS, RESET_BOOKINGS, Booking
} from "./bookings-const";

import { showDrawer, closeDrawer } from "../_base/drawer/drawer-action";
import { getAvailabilities } from '../inventories/calendars/calendars-action'
import { setPeopleType } from '../_base/base-action'
import { getAll as getAllUsers } from '../users/users-action'
import { getAll as getAllPeoples } from '../peoples/peoples-action'

import moment from 'moment-timezone'
import { getUserTz, formatError } from '../../utils/utils';


export const getAll = (account_id, page, s = null, status = null) => dispatch => {

    dispatch({ type: LOADING_BOOKINGS, payload: true })

    obiApi.get('/bookings', {
        params: {
            account_id: account_id,
            page: page,
            s: s,
            status: status
        }
    })
        .then(res => {
            dispatch({ type: LOADING_BOOKINGS, payload: false })
            dispatch({
                type: GET_BOOKINGS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({ type: LOADING_BOOKINGS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const reset = () => dispatch => {
    dispatch({ type: RESET_BOOKINGS })
};

export const openEdit = (id, selected_account) => dispatch => {
    dispatch({ type: LOADING_BOOKINGS, payload: true })
    dispatch(showDrawer('booking_edit'))
    obiApi.get('/bookings/' + id)
        .then(res => {
            dispatch({ type: GET_BOOKING, payload: res.data })
            dispatch(getAllUsers(selected_account.id, 0, null, User.Status.ACTIVE))
            console.log('here')
            if (res.data.item.item_type_id && selected_account) {
                selected_account.options.items_types.forEach(it => {
                    if (it.id === res.data.item.item_type_id) {
                        dispatch(setPeopleType(it.people_type_id, selected_account));
                        let ids = [];
                        res.data.related_people.forEach(rp => {
                            ids.push(rp.id)
                        })
                        dispatch(getAllPeoples(it.people_type_id, selected_account.id, 0, null, People.Status.ACTIVE, ids))
                    }
                })
            }
            console.log('here2')
            dispatch({ type: LOADING_BOOKINGS, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_BOOKINGS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const openNew = () => dispatch => {
    dispatch(showDrawer('booking_new'));
};


export const persistChanges = (data, ftype) => dispatch => {
    dispatch({ type: PERSIST_CHANGES_BOOKINGS, payload: { data: data, ftype: ftype } })
};

export const create = (data, item, selected_account, users, selected_month) => dispatch => {
    if (!item.approval)
        data.status = Booking.Status.ACTIVE;

    dispatch({ type: LOADING_BOOKINGS, payload: true })
    obiApi.post('/bookings', data)
        .then(res => {
            dispatch({ type: LOADING_BOOKINGS, payload: false })

            //bookings have validation messages //TODO use avilable unitl date
            if (res.data.message) {
                dispatch({
                    type: SET_NOTIFICATION,
                    payload: { type: "error", message: res.data.message, autoclose: false }
                })
            } else {
                dispatch({
                    type: SET_NOTIFICATION,
                    payload: { type: "success", message: "Booking sucessfuly created", autoclose: true }
                })
                dispatch(getAvailabilities(
                    item.id,
                    `${moment.tz(selected_month.start, getUserTz()).add(-1, 'days').format("DD/MM/YYYY")}-${moment.tz(selected_month.end, getUserTz()).add(1, 'days').format("DD/MM/YYYY")}`,
                    getUserTz()
                ));
                if (item.approval)
                    dispatch(sendEmails(data, item, selected_account, users, 'pending', 'POST'))
                else
                    dispatch(sendEmails(data, item, selected_account, users, 'approved', 'POST'))
            }
        })
        .catch(err => {
            dispatch({ type: LOADING_BOOKINGS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};

export const update = (data, item, selected_account, users, status_change = false) => dispatch => {
    dispatch({ type: LOADING_BOOKINGS, payload: true })
    obiApi.patch('/bookings/' + data.id, data)
        .then(res => {
            dispatch(getAll(selected_account.id))
            dispatch(closeDrawer("booking_edit"));
            const booking = data;

            if (status_change && (booking.status === "ACTIVE" || booking.status === "CANCELED" || booking.status === "DECLINED" || booking.status === "RESCHEDULED")) {
                if (booking.status === "ACTIVE")
                    booking.status = "APPROVED" // override because of email labeles and flow
                dispatch(sendEmails(booking, item, selected_account, users, 'status-change', 'PATCH'))
            }
        })
        .catch(err => {
            dispatch({ type: LOADING_BOOKINGS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};

export const del = (id, account_id, page, s = null, status = null) => dispatch => {
    dispatch({ type: LOADING_BOOKINGS, payload: true })
    obiApi.delete('/bookings/' + id)
        .then(res => {
            dispatch(getAll(account_id, page))
            dispatch({ type: LOADING_BOOKINGS, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_BOOKINGS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const sendEmails = (booking, item, selected_account, users, template, method) => dispatch => {
    if (item.notify_users.length > 0) {
        let emails = []
        item.notify_users.forEach(user => {
            emails.push(user.email)
        })
        obiApi({
            method: method,
            url: '/bookings/email',
            data: {
                to: emails,
                template: template + '-admin',
                from: selected_account.name + '<no-reply@mirusobi.com>',
                locals: {
                    ...booking,
                    booked_from: moment.tz(booking.booked_from, item.tz).format("DD/MM/YYYY HH:mm"),
                    booked_to: moment(booking.booked_to).isValid() ? moment.tz(booking.booked_to, item.tz).format("DD/MM/YYYY HH:mm") : "Unknown",
                    item_name: item.name,
                    link: process.env.REACT_APP_URL + "/bookings"
                }
            }
        })
            .then(res => {
                dispatch({
                    type: SET_NOTIFICATION,
                    payload: { type: "success", message: "Email sent to all team members", autoclose: true }
                })
            }).catch(err => {
                dispatch({
                    type: SET_NOTIFICATION,
                    payload: { type: "error", message: formatError(err), autoclose: true }
                })
            });
    }

    //send to clients
    if (item.notify_clients && booking.related_people.length > 0) {
        booking.related_people.forEach(person => {
            if (person.email)
                obiApi({
                    method: method,
                    url: '/bookings/email',
                    data: {
                        to: person.email,
                        template: template,
                        from: selected_account.name + '<no-reply@mirusobi.com>',
                        locals: {
                            ...booking,
                            ...person,
                            booked_from_to:
                                moment(booking.booked_to).isValid() ?
                                    moment.tz(booking.booked_from, getUserTz()).format("DD/MM/YYYY HH:mm") + " - " + moment.tz(booking.booked_to, getUserTz()).format("DD/MM/YYYY HH:mm") :
                                    " from: " + moment.tz(booking.booked_from, getUserTz()).format("DD/MM/YYYY HH:mm"),

                            item_name: item.name,
                            name: person.first_name + " " + person.last_name,
                            account_name: selected_account.name,
                            link: process.env.REACT_APP_URL + "/bookings"
                        }
                    }
                })
                    .then(res => {
                        dispatch({
                            type: SET_NOTIFICATION,
                            payload: { type: "success", message: "Email sent to user", autoclose: true }
                        })
                    }).catch(err => {
                        dispatch({
                            type: SET_NOTIFICATION,
                            payload: { type: "error", message: formatError(err), autoclose: true }
                        })
                    });
        })
    }

};