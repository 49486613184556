import React from 'react';
import {
    Panel, IconButton, Icon, FormGroup, Radio, Divider, PanelGroup, FlexboxGrid
} from 'rsuite';

import { TextField, RadioField, SelectField } from '../../_common/form-fields';

const PanelBookingConfig = ({ account, formError, removeFormRow, addFormRow, ftype, handleShowModal, handleValidateCustomFields, ...rest }) => {

    return (
        <Panel header="Inventory Configuration"
            collapsible
            expanded={
                formError.items_types
                    ? true : undefined
            }
            {...rest}
        >

            <FlexboxGrid justify="space-between">
                <FlexboxGrid.Item colspan={14}>
                    <Divider className="mt-0">What will people book in your inventory?</Divider>
                    {account.options.items_types ?
                        <PanelGroup className="mt-1 small-panel" accordion defaultActiveKey={0} bordered>
                            {account.options.items_types.map((item_type, it_index) => (
                                <Panel eventKey={it_index} key={"ity" + it_index} header={item_type.name ? item_type.name : "Item Type" + (it_index + 1)} collapsible bordered>

                                    <FlexboxGrid className="pb-2" >
                                        <FlexboxGrid.Item>
                                            <TextField
                                                name={`options.items_types.${it_index}.name`}
                                                placeholder="Item Type"
                                                size="xs"
                                                value={item_type.name}
                                                message={"Examples of item types: Accommodation, Meeting, Tour, Room, Hair Cutting..."}
                                            />
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item className="pl-2">
                                            <SelectField
                                                name={`options.items_types.${it_index}.people_type.slug`}
                                                data={account.options.people_types}
                                                labelKey={'name'}
                                                valueKey={'slug'}
                                                placeholder="Related Person Type"
                                                value={item_type.people_type && item_type.people_type.slug ? item_type.people_type.slug : undefined}
                                                size={"sm"}
                                                message={"Who is booking this inventory item? All bookings will be inserted under this person type. You first need to enter person types under `Booking Configuration`."}
                                            />
                                            <TextField type="hidden" name={`options.items_types.${it_index}.people_type`} />

                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item>
                                            {account.options.items_types.length > 1 ?
                                                <IconButton
                                                    className="ml-1"
                                                    size="xs"
                                                    placement="left"
                                                    onClick={() => removeFormRow(`options.items_types`, it_index, ftype)}
                                                    icon={<Icon icon="trash" />}
                                                    title={"Delete Item Type"}
                                                />
                                                : ""}
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>

                                    {item_type.availabilities_status ?
                                        item_type.availabilities_status.map((availability, index) => (
                                            <FlexboxGrid key={"fgav" + index}>
                                                <FlexboxGrid.Item>
                                                    <RadioField
                                                        name={`options.items_types.${it_index}.availabilities_status.${index}.booking_allowed`}
                                                        className="inline-radio"
                                                        appearance="picker"
                                                        inline
                                                        value={availability.booking_allowed}
                                                    >
                                                        <span>Booking Status:</span>
                                                        <Radio value={true}>Active</Radio>
                                                        <Radio value={false}>Disabled</Radio>
                                                    </RadioField>
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item>
                                                    <TextField
                                                        name={`options.items_types.${it_index}.availabilities_status.${index}.booking_allowed_label`}
                                                        placeholder="Label for status"
                                                        size="xs"
                                                        value={availability.booking_allowed_label}
                                                        message={"Examples: Available, Not Available, Under Construction, Closed, Open,.."}
                                                        width={100}
                                                    />
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item>
                                                {item_type.availabilities_status.length > 1 ?
                                                        <IconButton
                                                            className={"delete-sub-item"}
                                                            size="xs"
                                                            placement="left"
                                                            onClick={() => removeFormRow(`options.items_types[${it_index}].availabilities_status`, index, ftype)}
                                                            icon={<Icon icon="trash" />}
                                                            title={"Delete Booking Status"}
                                                        />
                                                        : ""}
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        ))
                                        :
                                        ""
                                    }

                                    <IconButton
                                        size="xs"
                                        placement="left"
                                        onClick={() => addFormRow(`options.items_types[${it_index}].availabilities_status`, ftype)}
                                        icon={<Icon icon="plus-square-o" />}
                                    >Add Booking Label</IconButton>
                                </Panel>
                            ))
                            }
                        </PanelGroup>
                        :
                        ""
                    }
                    <FormGroup className="pt-3 pb-3">
                        <IconButton
                            size="xs"
                            placement="left"
                            onClick={() => addFormRow(`options.items_types`, ftype)}
                            icon={<Icon icon="plus-square-o" />}
                        > Add Inventory Item Type</IconButton>

                    </FormGroup>
                </FlexboxGrid.Item>
            </FlexboxGrid>

        </Panel>

    )
}

export default PanelBookingConfig;