import {
    LOADING_USERS,
    GET_USERS,
    GET_USER,
    PERSIST_CHANGES_USERS,
    SET_ACCOUNT_ID,
    User
} from './users-const';

const USER_INITIAL_STATE = {
    status: User.Status.PENDING,
    first_name: "",
    last_name: "",
    role: User.Roles.OPERATOR,
    permissions: User.RoleTypes[3].default_permissions
}

const INITIAL_STATE = {
    data: [],
    page: {
        curr: 0,
        items: 0,
        pages: 0
    },
    loading: false,
    filters: [],
    sorters: [],
    s: "",
    new: USER_INITIAL_STATE,
    edit: USER_INITIAL_STATE

};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                data: action.payload.data,
                page: action.payload.page,
                filters: action.payload.filters,
                sorters: action.payload.sorters
            };
        case GET_USER:
            return {
                ...state,
                edit: action.payload
            };

        case PERSIST_CHANGES_USERS:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case LOADING_USERS:
            return {
                ...state,
                loading: action.payload
            };
        case SET_ACCOUNT_ID:
            return {
                ...state,
                new: { ...state.new, account_id: action.payload }
            };
        default:
            return state;
    }
}