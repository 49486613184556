import React, { useCallback } from 'react';
import Modal from '../../_base/modal/modal-container';
import BookingForm from './calendar-booking-form';
import { Item } from '../items/items-const'

import Drawer from '../../_base/drawer/drawer-container';
import PeoplesForm from '../../peoples/peoples-form'

import CalendarSinglePicker from "./calendar-single-date-picker";
import CalendarRangePicker from "./calendar-range-picker";
import CalendarTimeSlotPicker from "./calendar-time-slots-picker";
import moment from 'moment-timezone';

import '@mobiscroll/react_v4/dist/css/mobiscroll.min.css';


const Calendars = ({
    calendar_data, item, sources,
    users, peoples, selected_account,
    selected_people_type,
    handleInit, handleMonthChange,
    handleSave, new_booking,
    handleCancelBooking,
    handleFormChange, model,
    formError, handleBook,
    handleBookTimeSlot,
    handleMonthChangeRangeCalendar,
    handleInitRange,
    handleSelectedRange,
    handleBookRange,
    selected_range,
    //handleSelectedRangeChange,
    handleStartEndFocus,
    handlePageLoading,
    handleSearchPeople,
    handleSearchUsers,
    handleNewPerson,
    handleEditPerson,
    modelPeople,
    handleFormChangePeople,
    handleCancelPeople,
    handleSavePeople
}) => {
    return (
        <>
            <Modal
                type={"new_booking"}
                title={"Add New Booking"}
                handleAction={useCallback(() => {
                    handleSave(new_booking)
                }, [new_booking, handleSave])}
                handleCloseAction={
                    useCallback((type) => {
                        handleCancelBooking(type)
                    }, [handleCancelBooking])
                }
                autoCloseConfirm={false}
                size="xs"
            >
                <BookingForm
                    booking={new_booking}
                    sources={sources}
                    users={users}
                    peoples={peoples}
                    handleFormChange={handleFormChange}
                    handleSearchPeople={handleSearchPeople}
                    handleSearchUsers={handleSearchUsers}
                    handleSave={handleSave}
                    handleNewPerson={handleNewPerson}
                    handleEditPerson={handleEditPerson}
                    model={model}
                    formError={formError}
                />
            </Modal>

            <Drawer
                title={"New " + selected_people_type.name}
                className={"peoples peoples-drawer"}
                type={"people_new"}
                handleAction={() => handleSavePeople("new")}
                handleCloseAction={() => handleCancelPeople("new")}
                loading={peoples.loading}
            >
                <PeoplesForm
                    people={peoples.new}
                    selected_people_type={selected_people_type}
                    handleFormChange={handleFormChangePeople}
                    handleSave={handleSavePeople}
                    handleCancel={handleCancelPeople}                    
                    ftype={"new"}
                    loading={peoples.loading}
                    model={modelPeople}
                    formError={formError}
                    selected_account={selected_account}
                />
            </Drawer>


            <Drawer
                toptitle={selected_people_type.name}
                title={peoples.edit.first_name + " " + peoples.edit.last_name}
                subtitle={"Last edited:  " + moment(peoples.edit.updated_at).format("DD/MM/YYYY HH:mm")}
                className={"peoples peoples-drawer"}
                type={"people_edit"}
                handleAction={() => handleSavePeople("edit")}
                handleCloseAction={() => handleCancelPeople("edit")}
                loading={peoples.loading}
            >
                <PeoplesForm
                    people={peoples.edit}
                    selected_people_type={selected_people_type}
                    handleFormChange={handleFormChangePeople}                
                    ftype={"edit"}
                    handleSave={handleSavePeople}
                    handleCancel={handleCancelPeople}
                    loading={peoples.loading}
                    model={modelPeople}
                    formError={formError}
                    selected_account={selected_account}
                />
            </Drawer>



            {item.calendar_type === Item.CalendarType.SINGLE_DATE ?
                <CalendarSinglePicker
                    data={calendar_data}
                    handleInit={handleInit}
                    handleMonthChange={handleMonthChange}
                    handleBook={handleBook}
                />
                : ""}
            {item.calendar_type === Item.CalendarType.DATES_RANGE ?
                <CalendarRangePicker
                    data={calendar_data}
                    handleInit={handleInitRange}
                    handleMonthChange={handleMonthChangeRangeCalendar}
                    handleSelectedRange={handleSelectedRange}
                    selected_range={selected_range}
                    handleBookRange={handleBookRange}
                    handleSave={handleSave} 
                    handlePageLoading={handlePageLoading}
                    handleStartEndFocus={handleStartEndFocus}
                    min_range={item.slot_min}
                    max_range={item.slot_max}
                />
                : ""}
            {item.calendar_type === Item.CalendarType.TIME_SLOTS ?
                <CalendarTimeSlotPicker
                    data={calendar_data}
                    handleInit={handleInit}
                    handleMonthChange={handleMonthChange}
                    new_booking={new_booking}
                    handleFormChange={handleFormChange}
                    handleSave={handleSave}
                    model={model}
                    formError={formError}
                    handleBookTimeSlot={handleBookTimeSlot}
                />
                : ""}

        </>
    )
}




export default Calendars;