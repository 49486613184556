import React, { useCallback } from 'react';
import {
    Panel, Table, Header, FlexboxGrid, IconButton, Icon
} from 'rsuite';

import { StatusCell, DateCell } from '../_common/table-cells';
import Drawer from '../_base/drawer/drawer-container';
import Modal from '../_base/modal/modal-container';
import UsersForm from './users-form'
import moment from 'moment-timezone';

const { Column, HeaderCell, Cell, Pagination } = Table;


const Users = ({
    users, current_user, handlePageChange, handleEdit,
    handleDelete, handleNew, handleCancel,
    handleFormChange, model, formError,
    handleSave, removeFormRow, addFormRow,
    handleShowModal
}) => {

    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>Users</h4>
                        </FlexboxGrid.Item>
                        {current_user.permissions && current_user.permissions.includes('obi:users:create') ?
                            <FlexboxGrid.Item className="text-right" colspan={12}>
                                <IconButton
                                    size="xs"
                                    placement="left"
                                    onClick={() => handleNew()}
                                    icon={<Icon icon="plus-square-o" />}
                                >
                                    Add New
                            </IconButton>
                            </FlexboxGrid.Item>
                            : ""}
                    </FlexboxGrid>
                </Header>
                <Table loading={users.loading} height={600} data={users.data}>
                    {/*<Column width={50} align="center">
                        <HeaderCell>Id</HeaderCell>
                        <Cell dataKey="id" />
                     </Column>*/}
                    <Column width={80} >
                        <HeaderCell>Status</HeaderCell>
                        <StatusCell dataKey="status" />
                    </Column>
                    <Column width={150} /*flexGrow={1}*/>
                        <HeaderCell>First Name</HeaderCell>
                        <Cell dataKey="first_name" />
                    </Column>
                    <Column width={150} /*flexGrow={1}*/>
                        <HeaderCell>Last Name</HeaderCell>
                        <Cell dataKey="last_name" />
                    </Column>
                    <Column width={200} >
                        <HeaderCell>Email</HeaderCell>
                        <Cell dataKey="email" />
                    </Column>
                    <Column width={200} >
                        <HeaderCell>Last Login</HeaderCell>
                        <DateCell dataKey="last_login" />
                    </Column>

                    <Column width={100} fixed={"right"}>
                        <HeaderCell>Actions</HeaderCell>
                        <ActionCell
                            dataKey="id"
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handleShowModal={handleShowModal}
                            current_user={current_user}
                        />
                    </Column>
                </Table>
                <Pagination
                    activePage={parseInt(users.page.curr)}
                    total={parseInt(users.page.items)}
                    displayLength={100}
                    showLengthMenu={false}
                    onChangePage={handlePageChange}
                />
            </Panel>


            <Drawer
                toptitle={"User"}
                title={users.edit.first_name + " " + users.edit.last_name}
                subtitle={"Last edited:  " + moment(users.edit.updated_at).format("DD/MM/YYYY HH:mm")}
                className={"users"}
                type={"user_edit"}
                handleAction={() => handleSave("edit")}
                handleCloseAction={() => handleCancel("edit")}
            >
                <UsersForm
                    user={users.edit}
                    handleFormChange={handleFormChange}
                    ftype={"edit"}
                    loading={users.loading}
                    model={model}
                    formError={formError}
                    current_user={current_user}
                />
            </Drawer>

            <Drawer
                title={"New User"}
                className={"users"}
                type={"user_new"}
                handleAction={() => handleSave("new")}
                handleCloseAction={() => handleCancel("new")}
            >
                <UsersForm
                    user={users.new}
                    handleFormChange={handleFormChange}
                   
                    ftype={"new"}
                    loading={users.loading}
                    model={model}
                    formError={formError}
                    current_user={current_user}
                />
            </Drawer>

        </>
    );

}


const ActionCell = ({ rowData, dataKey, handleEdit, handleDelete, handleShowModal, current_user, ...props }) => {
    return (
        <Cell {...props} className="link-group">
            {current_user.permissions && current_user.permissions.includes('obi:users:update') ?
                <IconButton
                    circle
                    size="xs"
                    appearance="subtle"
                    color="orange"
                    onClick={() => handleEdit(rowData.id)}
                    icon={<Icon icon="edit2" />}
                />
                : ""}
            {current_user.permissions && current_user.permissions.includes('obi:users:delete') ?
                <IconButton
                    circle
                    size="xs"
                    appearance="subtle"
                    color="red"
                    onClick={() => handleShowModal('user_delete' + rowData.id)}
                    icon={<Icon icon="trash2" />}
                    className="ml-1"
                />
                : ""}
            <Modal
                type={"user_delete" + rowData.id}
                title={"Delete"}
                handleAction={useCallback(() => {
                    handleDelete(rowData.id)
                }, [rowData, handleDelete])}
                size="xs"
            >
                {`Are you sure you want to delete user ${rowData.name}?`}
            </Modal>
        </Cell>
    );
};


export default Users;