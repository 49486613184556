import {
    LOADING_BOOKINGS,
    GET_BOOKINGS,
    GET_BOOKING,
    PERSIST_CHANGES_BOOKINGS,
    RESET_BOOKINGS,
    Booking
} from './bookings-const';

const BOOKING_INITIAL_STATE = {
    item_id: 0,
    //details: [],
    booked_from: null,
    booked_to: null,
    booking_source: "",
    related_people: [],
    related_users: [],
    status: Booking.Status.PENDING,
    details: {}
}

const INITIAL_STATE = {
    data: [],
    page: {
        curr: 0,
        items: 0,
        pages: 0
    },
    loading: false,
    filters: [],
    sorters: [],
    s: "",
    new: BOOKING_INITIAL_STATE,
    edit: BOOKING_INITIAL_STATE
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case GET_BOOKINGS:
            return {
                ...state,
                data: action.payload.data,
                page: action.payload.page,
                filters: action.payload.filters,
                sorters: action.payload.sorters
            };
        case GET_BOOKING:
            return {
                ...state,
                edit: action.payload
            };

        case PERSIST_CHANGES_BOOKINGS:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case LOADING_BOOKINGS:
            return {
                ...state,
                loading: action.payload
            };
        case RESET_BOOKINGS:
            return {
                ...state,
                new: { ...BOOKING_INITIAL_STATE },
                edit: { ...BOOKING_INITIAL_STATE }
            };
        default:
            return state;
    }
}