import {
    LOADING_ITEMS,
    GET_INVENTORY_ITEM,
    PERSIST_CHANGES_ITEMS,
    RESET_CHANGES_ITEMS,
    SET_INVENTORY_ID,
    //SET_ACCOUNT_ID,
    Item,
    GET_EMBED_CODE
} from './items-const';
import { getUserTz } from '../../../utils/utils';


const INVENTORY_ITEM_INITIAL_STATE = {
    inventory_id: 0,
    item_type_id: null,
    name: "",
    status: Item.Status.DRAFT,
    calendar_type: Item.CalendarType.SINGLE_DATE,
    slot_type: Item.SlotType.DAILY,
    slot_interval: 1,
    slot_label: "",
    slot_min: 0,
    slot_max: 0,
    max_bookings_allowed: 0,
    booking_after: {
        value: 0,
        period: 'days'
    },
    approval: true,
    notify_clients: true,
    notify_users: [],
    description: "",
    availabilities: [],
    tz: getUserTz(),
    display: {
        all_time_bookings: {
            visible: true
        },
        total_bookings_past_x_days: {
            visible: true,
            days: 30
        },
        next_availability: {
            visible: true
        },
        availability_next_x_days_in_precentage: {
            visible: true,
            days: 30
        },
        current_availability: {
            visible: true
        }
    }

}

const INITIAL_STATE = {
    loading: false,
    new: INVENTORY_ITEM_INITIAL_STATE,
    edit: INVENTORY_ITEM_INITIAL_STATE,
    embed: {
        item_id: 0,
        inventory_id: 0,
        booking_source: "",
        code: ""
    }
};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case GET_INVENTORY_ITEM:
            return {
                ...state,
                edit: action.payload
            };
        case GET_EMBED_CODE:
            return {
                ...state,
                embed: {
                    ...state.embed,
                    code: action.payload
                }
            };
        case PERSIST_CHANGES_ITEMS:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };
        case RESET_CHANGES_ITEMS:
            return {
                ...state,
                [`${action.payload.ftype}`]: { ...INVENTORY_ITEM_INITIAL_STATE }
            };
        case SET_INVENTORY_ID:
            return {
                ...state,
                new: { ...state.new, inventory_id: action.payload }
            };

        case LOADING_ITEMS:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return state;
    }
}