import React from 'react';
import {
    Panel, IconButton, Icon,
    FormGroup,
    Divider,
    FlexboxGrid
} from 'rsuite';
import Modal from '../../_base/modal/modal-container';
import CustomFields from './_custom-fields'
import { PEOPLE_TYPE_INITIAL_STATE } from '../accounts-const'
import { TextField } from '../../_common/form-fields';

const PanelBookingConfig = ({ account, formError, removeFormRow, addFormRow, ftype, handleShowModal, handleValidateCustomFields, ...rest }) => {

    return (
        <Panel header="Booking Configuration"
            collapsible
            expanded={
                formError.people_types ||
                    formError.sources
                    ? true : undefined
            }
            {...rest}
        >
            <FlexboxGrid justify="space-between">
                <FlexboxGrid.Item colspan={11}>
                    <Divider className={"mt-0"}>Who will book your inventory?</Divider>
                    <div></div>
                    {account.options.people_types ? account.options.people_types.map((ptype, p_index) => (
                        <FlexboxGrid className="pb-2" key={'source' + p_index}>
                            <FlexboxGrid.Item>
                                <TextField
                                    name={`options.people_types.${p_index}.name`}
                                    placeholder="Person Type"
                                    size="xs"
                                    value={ptype.name}
                                    onChange={(e) => {
                                    }}
                                    message={"Examples of person types: Visitors, Residents, Guests, Participants..."}
                                /></FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                <IconButton
                                    className="ml-1"
                                    size="xs"
                                    placement="left"
                                    title="Add/Edit custom fields"
                                    onClick={() => handleShowModal("custom_fields_people" + p_index)}
                                    icon={<Icon icon="edit" />}
                                />
                                <Modal
                                    type={"custom_fields_people" + p_index}
                                    title={ptype.name + " - Custom Fields"}
                                    handleAction={() => {
                                        handleValidateCustomFields(ftype, "custom_fields_people" + p_index)
                                    }}
                                    handleCloseAction={() => {
                                        handleValidateCustomFields(ftype, "custom_fields_people" + p_index)
                                    }}
                                    autoCloseConfirm={false}
                                    size="lg"
                                >
                                    <CustomFields
                                        custom_fields={ptype.custom_fields ? ptype.custom_fields : []}
                                        option_path={`options.people_types.${p_index}.custom_fields`}
                                        removeFormRow={removeFormRow}
                                        addFormRow={addFormRow}
                                        ftype={ftype}
                                        handleValidateCustomFields={handleValidateCustomFields}
                                    />

                                </Modal>

                                {account.options.people_types.length > 1 ?
                                    <IconButton
                                        className="ml-1"
                                        size="xs"
                                        placement="left"
                                        onClick={() => removeFormRow(`options.people_types`, p_index, ftype)}
                                        title="Remove person type"
                                        icon={<Icon icon="trash" />}
                                    />
                                    : ""}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                    ))
                        : ""}
                    <FormGroup className="pt-1">
                        <IconButton
                            size="xs"
                            placement="left"
                            onClick={() => addFormRow(`options.people_types`, ftype, PEOPLE_TYPE_INITIAL_STATE)}
                            icon={<Icon icon="plus-square-o" />}
                        >
                            Add Person Type
                            </IconButton>
                    </FormGroup>

                </FlexboxGrid.Item>

                <FlexboxGrid.Item colspan={11}>

                    <Divider className="mt-0">Booking Sources</Divider>
                    {account.options.sources ? account.options.sources.map((source, s_index) => (
                        <FlexboxGrid className="pb-2" key={'source' + s_index}>
                            <FlexboxGrid.Item>
                                <TextField
                                    name={`options.sources.${s_index}.name`}
                                    placeholder="Booking Source"
                                    size="xs"
                                    value={source.name}
                                    onChange={(e) => {
                                    }}
                                    message={"Examples of booking sources: Website, Phone, Walk In, Event, Email..."}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                            {account.options.sources.length > 1 ?
                                    <IconButton
                                        className="ml-1"
                                        size="xs"
                                        placement="left"
                                        title="Remove booking source"
                                        onClick={() => removeFormRow(`options.sources`, s_index, ftype)}
                                        icon={<Icon icon="trash" />}
                                    />
                                    : ""}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                    ))
                        : ""}
                    <FormGroup className="pt-1">
                        <IconButton
                            size="xs"
                            placement="left"
                            onClick={() => addFormRow(`options.sources`, ftype)}
                            icon={<Icon icon="plus-square-o" />}
                        >
                            Add Source
                            </IconButton>
                    </FormGroup>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Panel>

    )
}

export default PanelBookingConfig;