import {
    SET_CSRF_ACQUIRED,
    SET_CURRENT_USER,
    SET_NOTIFICATION,
    SET_MENU_EXPAND,
    SET_SELECTED_ACCOUNT,
    SET_PEOPLE_TYPE,
    GET_AVAILABLE_ACCOUNTS,
    REMOVE_NOTIFICATION,
    SHOW_DRAWER,
    CLOSE_DRAWER,
    SHOW_MODAL,
    CLOSE_MODAL
} from './base-const';
import { isAdmin } from '../../utils/utils';
import _ from 'lodash'

const INITIAL_STATE = {
    user: {},
    notification: {
        type: "",
        message: "",
        autoclose: true
    },
    menu: {
        expand: true
    },
    drawers: [],
    modals: [],
    selected_account: {},
    available_accounts: [],
    selected_people_type: {
        name: "",
        slug: "",
        custom_fields: []
    },
    admin: false,
    loading: false,
    csrf_token_acquired: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case SET_CSRF_ACQUIRED:
            return {
                ...state,
                csrf_token_acquired: true
            };
        case SET_CURRENT_USER:
            return {
                ...state,
                user: action.payload,
                admin: isAdmin(action.payload)
            };
        case SET_SELECTED_ACCOUNT:
            return {
                ...state,
                selected_account: action.payload
            };
        case SET_PEOPLE_TYPE:
            return {
                ...state,
                selected_people_type: action.payload
            };
        case SET_NOTIFICATION:
            return {
                ...state,
                notification: action.payload
            };
        case REMOVE_NOTIFICATION:
            return {
                ...state,
                notification: {}
            };
        case SET_MENU_EXPAND:
            return {
                ...state,
                menu: {
                    ...state.menu,
                    expand: action.payload
                }
            };
        case GET_AVAILABLE_ACCOUNTS:
            return {
                ...state,
                available_accounts: [
                    ...action.payload.data
                ]
            };

        case SHOW_DRAWER:
            return {
                ...state,
                drawers: [
                    ...state.drawers,
                    action.payload
                ]
            };

        case CLOSE_DRAWER:
            let drawers = _.cloneDeep(state.drawers)
            return {
                ...state,
                drawers: [
                    ...drawers.filter((drawer) => {
                        return action.payload.type !== drawer.type;
                    })
                ]
            };

        case SHOW_MODAL:
            return {
                ...state,
                modals: [
                    ...state.modals,
                    action.payload
                ]
            };
        case CLOSE_MODAL:
            let modals = _.cloneDeep(state.modals)
            return {
                ...state,
                modals: [
                    ...modals.filter((modal) => {
                        return action.payload.type !== modal.type;
                    })
                ]
            };
        default:
            return state;
    }
}