import React from 'react';
import { Panel, Radio, FlexboxGrid } from 'rsuite';

import { TextField, RadioField, SelectField } from '../../_common/form-fields';

import { Account } from '../accounts-const'
import { User } from '../../users/users-const'
import { getRoleLevel } from '../../../utils/utils'


const PanelGeneral = ({ account, formError, current_user, available_accounts, ...rest }) => {

    return (
        <Panel header="General"
            collapsible
            defaultExpanded
            expanded={
                formError.status ||
                    formError.name
                    ? true : undefined
            }
            {...rest}
        >
            <FlexboxGrid>
                <FlexboxGrid.Item className="pr-4">
                    <TextField name="name" label="Name" disabled={getRoleLevel(current_user, User.RoleTypes) > 1} />

                    <SelectField
                        label={"Parent Account"}
                        name="parent_id"
                        placeholder="Select Account"
                        data={available_accounts}
                        labelKey={'name'}
                        valueKey={'id'}
                        value={account.parent_id ? account.parent_id : ""}
                        size={"sm"}
                        disabled={getRoleLevel(current_user, User.RoleTypes) > 1}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="pt-2">
                    <RadioField name="status" className="inline-radio" appearance="picker" inline>
                        <span>Status: </span>
                        <Radio value={Account.Status.ACTIVE}>Active</Radio>
                        <Radio value={Account.Status.DRAFT}>Draft</Radio>
                        <Radio value={Account.Status.DISABLED}>Disabled</Radio>
                        <Radio value={Account.Status.ARCHIVED}>Archived</Radio>
                    </RadioField>
                    <TextField name="domains" label="Allowed domains for connection to API (optional)" message="For multiple domains divide them by space" />
                </FlexboxGrid.Item>

            </FlexboxGrid>






        </Panel>

    )
}

export default PanelGeneral;