import React from 'react';

import {
    FlexboxGrid, IconButton, Icon,
    Divider, Input, TagGroup, Tag,
    FormGroup, ControlLabel, Radio
} from 'rsuite';

import { TextField, SelectField, RadioField } from '../../_common/form-fields';
import { Account, CUSTOM_FIELD_INITIAL_STATE } from '../accounts-const'


const CustomFields = ({ option_path, custom_fields, ftype, addFormRow, removeFormRow }) => {

    return (
        <div className={"custom-fields"}>
            {custom_fields ? custom_fields.map((cfield, index) => (
                <div className="custom-field" key={"cf" + index}>
                    <FlexboxGrid className="pb-2">
                        <FlexboxGrid.Item className={"pr-3"}>
                            <TextField
                                name={`${option_path}.${index}.name`}
                                label="Field Unique Name"
                                value={cfield.name}
                                message={"Use only small letters without space. If you want to separate words use underscore sign `_` "}
                                style={{ width: 150 }}
                            />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className={"pr-3"}>
                            <TextField
                                name={`${option_path}.${index}.label`}
                                label="Label"
                                value={cfield.label}
                                message={"Leave empty if you don`t want label to show on form"}
                                style={{ width: 150 }}
                            />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className={"pr-3"}>
                            <TextField
                                name={`${option_path}.${index}.placeholder`}
                                label="Placeholder"
                                value={cfield.placeholder}
                                style={{ width: 150 }}
                            />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className={"pr-3 pt-2"}>
                            <RadioField
                                name={`${option_path}.${index}.show_booking_form`}
                                className="inline-radio"
                                appearance="picker"
                                inline
                                value={cfield.show_booking_form}
                            >
                                <span>Include on booking form: </span>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </RadioField>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FlexboxGrid className="pb-2">
                        <FlexboxGrid.Item className={"pr-3"}>
                            <SelectField
                                name={`${option_path}.${index}.type`}
                                label="Type"
                                placeholder={"Select Field Type"}
                                value={cfield.type}
                                data={Account.CustomFields.Selection}
                            />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className={"pr-3"}>
                            <TextField
                                name={`${option_path}.${index}.default_value`}
                                label="Default value"
                                value={cfield.default_value}
                                style={{ width: 150 }}
                            />

                        </FlexboxGrid.Item>
                        {
                            cfield.type === Account.CustomFields.Types.SELECT ||
                                cfield.type === Account.CustomFields.Types.MULTISELECT ||
                                cfield.type === Account.CustomFields.Types.CHECKBOX ||
                                cfield.type === Account.CustomFields.Types.RADIO
                                ?

                                <FlexboxGrid.Item className={"pr-3"}>
                                    <FormGroup>
                                        <ControlLabel>Selection values</ControlLabel>
                                        <SelectOptions
                                            selection_values={cfield.selection_values}
                                            addFormRow={addFormRow}
                                            removeFormRow={removeFormRow}
                                            option_path={`${option_path}.${index}.selection_values`}
                                            ftype={ftype}
                                        />
                                    </FormGroup>
                                </FlexboxGrid.Item>
                                : ""}


                        <FlexboxGrid.Item className={"pr-3"}>
                            <TextField
                                name={`${option_path}.${index}.tooltip`}
                                label="Tooltip"
                                value={cfield.tooltip}
                                style={{ width: 150 }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>

                    <IconButton
                        className="remove-custom-field"
                        size="xs"
                        placement="left"
                        onClick={() => removeFormRow(`${option_path}`, index, ftype)}
                        title="Remove custom field"
                        icon={<Icon icon="trash" />}
                    />
                    <Divider />
                </div>
            ))
                : ""
            }

            <IconButton
                className="mt-3"
                size="xs"
                placement="left"
                onClick={() => addFormRow(`${option_path}`, ftype, CUSTOM_FIELD_INITIAL_STATE)}
                icon={<Icon icon="plus-square-o" />}
            >Add Custom Field</IconButton>



        </div>
    )

}

//TODO reconstruct this part of code to be reusable and with hooks
class SelectOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            typing: false,
            inputValue: '',
            tags: props.selection_values
        };
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputConfirm = this.handleInputConfirm.bind(this);
    }
    handleButtonClick() {
        this.setState(
            {
                typing: true
            },
            () => {
                this.input.focus();
            }
        );
    }
    handleInputChange(inputValue) {
        this.setState({ inputValue });
    }
    handleInputConfirm() {
        const { inputValue, tags } = this.state;
        const nextTags = inputValue ? [...tags, inputValue] : tags;
        this.setState({
            tags: nextTags,
            typing: false,
            inputValue: ''
        });
        this.props.addFormRow(`${this.props.option_path}`, this.props.ftype, inputValue)
    }
    handleTagRemove(tag, index) {
        const { tags } = this.state;
        const nextTags = tags.filter(item => item !== tag);
        this.setState({
            tags: nextTags
        });
        this.props.removeFormRow(`${this.props.option_path}`, index, this.props.ftype)
    }
    renderInput() {
        const { typing, inputValue } = this.state;

        if (typing) {
            return (
                <Input
                    className="tag-input"
                    inputRef={ref => {
                        this.input = ref;
                    }}
                    size="xs"
                    style={{ width: 70 }}
                    value={inputValue}
                    onChange={this.handleInputChange}
                    onBlur={this.handleInputConfirm}
                    onPressEnter={this.handleInputConfirm}
                />
            );
        }

        return (
            <IconButton
                className="tag-add-btn mt-1"
                onClick={this.handleButtonClick}
                icon={<Icon icon="plus" />}
                //appearance="ghost"
                size="xs"
            >Add Option</IconButton>
        );
    }
    render() {
        const { tags } = this.state;
        return (
            <TagGroup className="pl-1 pt-1">
                {tags ? tags.map((item, index) => (
                    <Tag
                        key={index}
                        closable
                        onClose={() => {
                            this.handleTagRemove(item, index);
                        }}
                    >
                        {item}
                    </Tag>
                )) : ""}
                {this.renderInput()}
            </TagGroup>
        );
    }
}

export default CustomFields;