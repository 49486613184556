import {
    LOADING_INVENTORIES,
    GET_INVENTORIES,
    GET_INVENTORY,
    PERSIST_CHANGES_INVENTORIES,
    SET_ACCOUNT_ID,
    SET_ITEM_DISPLAY_REPORTS,
    Inventory
} from './inventories-const';

import { cloneDeep } from "lodash";

const INVENTORY_INITIAL_STATE = {
    name: "",
    status: Inventory.Status.DRAFT
}

const INITIAL_STATE = {
    data: [],
    page: {
        curr: 0,
        items: 0,
        pages: 0
    },
    loading: false,
    filters: [],
    sorters: [],
    s: "",
    new: INVENTORY_INITIAL_STATE,
    edit: INVENTORY_INITIAL_STATE
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case GET_INVENTORIES:
            return {
                ...state,
                data: action.payload.data,
                page: action.payload.page,
                filters: action.payload.filters,
                sorters: action.payload.sorters
            };

        case GET_INVENTORY:
            return {
                ...state,
                edit: action.payload
            };

        case PERSIST_CHANGES_INVENTORIES:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case SET_ACCOUNT_ID:
            return {
                ...state,
                new: { ...state.new, account_id: action.payload }
            };

        case LOADING_INVENTORIES:
            return {
                ...state,
                loading: action.payload
            };

        case SET_ITEM_DISPLAY_REPORTS:
            let inventories = cloneDeep(state.data)
            inventories = inventories.map((inv) => {
                if (inv.id === action.payload.inv_id)
                    inv.items = inv.items.map((item) => {
                        if (item.id === action.payload.item_id)
                            item["reports"] = action.payload.data
                        return item;
                    })
                return inv;
            })
            return {
                ...state,
                data: [...inventories]
            };
        default:
            return state;
    }
}