import React from 'react';
import {
    Form, FormGroup,
    Radio, Divider, CheckboxGroup, Checkbox,
    FormControl, ControlLabel, FlexboxGrid
} from 'rsuite';

import { TextField, RadioField, SelectField } from '../_common/form-fields';

import { User } from './users-const'
import { getRoleLevel, allowedRoleTypes } from '../../utils/utils'
import _ from 'lodash'

const UsersForm = ({ user, current_user, model, handleFormChange, ftype, loading, formError }) => {

    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={user}
            >
                <Divider className="mt-1">General</Divider>

                <RadioField name="status" className="inline-radio" appearance="picker" inline>
                    <span>Status: </span>
                    <Radio value={User.Status.ACTIVE}>Active</Radio>
                    <Radio value={User.Status.PENDING}>Pending</Radio>
                    <Radio value={User.Status.DISABLED}>Disabled</Radio>
                    <Radio value={User.Status.ARCHIVED}>Archived</Radio>
                </RadioField>

                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <TextField name="first_name" label="First Name" style={{ width: 200 }} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <TextField name="last_name" label="Last Name" style={{ width: 200 }} />
                    </FlexboxGrid.Item>
                </FlexboxGrid>


                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item>
                        <TextField name="email" label="Email" disabled={ftype === "new" ? false : true} style={{ width: 200 }} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pl-2">
                        <SelectField
                            name={"role"}
                            placeholder="Select User Role"
                            searchable={false}
                            data={allowedRoleTypes(current_user, User.RoleTypes)}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            label={"User Role"}
                            onChange={(e) => {
                                User.RoleTypes.forEach(role => {
                                    if (role.value === e) {
                                        const data = _.cloneDeep(user)
                                        data.permissions = role.default_permissions
                                        data.role = e
                                        handleFormChange(data, ftype)
                                    }
                                })
                            }
                            }
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <div className={getRoleLevel(current_user, User.RoleTypes) > 2 ? "d-none" : ""}>
                    <Divider className="pt-3">Permissions</Divider>
                    <FormGroup>
                        <FormControl
                            name="permissions"
                            accepter={CheckboxGroup}
                            value={user.permissions.split(" ")}
                        >
                            <FlexboxGrid className={"align-items-center"}>
                                <FlexboxGrid.Item style={{ width: 120 }}>
                                    <ControlLabel>Accounts</ControlLabel>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    <Checkbox value="obi:accounts:read" disabled={getRoleLevel(user, User.RoleTypes) > 0}>View</Checkbox>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="pl-2">
                                    <Checkbox value="obi:accounts:create" disabled={getRoleLevel(user, User.RoleTypes) > 1}>Create</Checkbox>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="pl-2">
                                    <Checkbox value="obi:accounts:update" disabled={getRoleLevel(user, User.RoleTypes) > 2}>Edit</Checkbox>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="pl-2">
                                    <Checkbox value="obi:accounts:delete" disabled={getRoleLevel(user, User.RoleTypes) > 0}>Delete</Checkbox>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>

                            <FlexboxGrid className={"align-items-center"}>
                                <FlexboxGrid.Item style={{ width: 120 }}>
                                    <ControlLabel>Inventories</ControlLabel>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item >
                                    <Checkbox value="obi:inventories:read" disabled={getRoleLevel(user, User.RoleTypes) > 0}>View</Checkbox>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="pl-2">
                                    <Checkbox value="obi:inventories:create">Create</Checkbox>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="pl-2">
                                    <Checkbox value="obi:inventories:update">Edit</Checkbox>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="pl-2">
                                    <Checkbox value="obi:inventories:delete" disabled={getRoleLevel(user, User.RoleTypes) > 2}>Delete</Checkbox>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>


                            <FlexboxGrid className={"align-items-center"}>
                                <FlexboxGrid.Item style={{ width: 120 }}>
                                    <ControlLabel>Items</ControlLabel>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    <Checkbox value="obi:items:read" disabled={getRoleLevel(user, User.RoleTypes) > 0}>View</Checkbox>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="pl-2">
                                    <Checkbox value="obi:items:create">Create</Checkbox>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="pl-2">
                                    <Checkbox value="obi:items:update">Edit</Checkbox>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="pl-2">
                                    <Checkbox value="obi:items:delete" disabled={getRoleLevel(user, User.RoleTypes) > 2}>Delete</Checkbox>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>



                            <FlexboxGrid className={"align-items-center"}>
                                <FlexboxGrid.Item style={{ width: 120 }}>
                                    <ControlLabel>Bookings</ControlLabel>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    <Checkbox value="obi:bookings:read" disabled={getRoleLevel(user, User.RoleTypes) > 0}>View</Checkbox>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="pl-2">
                                    <Checkbox value="obi:bookings:create">Create</Checkbox>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="pl-2">
                                    <Checkbox value="obi:bookings:update">Edit</Checkbox>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="pl-2">
                                    <Checkbox value="obi:bookings:delete">Delete</Checkbox>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>

                            <FlexboxGrid className={"align-items-center"}>
                                <FlexboxGrid.Item style={{ width: 120 }}>
                                    <ControlLabel>Peoples</ControlLabel>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    <Checkbox value="obi:peoples:read" disabled={getRoleLevel(user, User.RoleTypes) > 0}>View</Checkbox>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="pl-2">
                                    <Checkbox value="obi:peoples:create">Create</Checkbox>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="pl-2">
                                    <Checkbox value="obi:peoples:update">Edit</Checkbox>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="pl-2">
                                    <Checkbox value="obi:peoples:delete">Delete</Checkbox>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>


                            <FlexboxGrid className={"align-items-center"}>
                                <FlexboxGrid.Item style={{ width: 120 }}>
                                    <ControlLabel>Users</ControlLabel>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    <Checkbox value="obi:users:read">View</Checkbox>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="pl-2">
                                    <Checkbox value="obi:users:create">Create</Checkbox>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="pl-2">
                                    <Checkbox value="obi:users:update">Edit</Checkbox>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item className="pl-2">
                                    <Checkbox value="obi:users:delete" disabled={getRoleLevel(user, User.RoleTypes) > 0}>Delete</Checkbox>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>


                            <FlexboxGrid className="d-none">
                                <FlexboxGrid.Item>
                                    <ControlLabel className="pt-2 d-none" >Emails</ControlLabel>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    <Checkbox value="obi:mail:send" disabled={true}>Send Emails</Checkbox>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>

                        </FormControl>
                    </FormGroup>

                </div>
                

            </Form>
        </>
    );

}




export default UsersForm;