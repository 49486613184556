import obiApi from '../services';
import { SET_NOTIFICATION } from "../_base/base-const";
import { sendWelcomeEmail } from "../_base/base-action";
import { showDrawer, closeDrawer } from "../_base/drawer/drawer-action";
import {
    LOADING_USERS, GET_USERS, GET_USER,
    PERSIST_CHANGES_USERS, SET_ACCOUNT_ID
} from "./users-const";
import { formatError } from '../../utils/utils'

export const getAll = (account_id, page, s = null, status = null) => dispatch => {

    dispatch({ type: LOADING_USERS, payload: true })

    obiApi.get('/users', {
        params: {
            account_id: account_id,
            page: page,
            s: s,
            status: status
        }
    })
        .then(res => {
            dispatch({ type: LOADING_USERS, payload: false })
            dispatch({
                type: GET_USERS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({ type: LOADING_USERS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const openEdit = (id) => dispatch => {
    dispatch({ type: LOADING_USERS, payload: true })
    dispatch(showDrawer('user_edit'));

    obiApi.get('/users/' + id)
        .then(res => {
            dispatch({ type: GET_USER, payload: res.data })
            dispatch({ type: LOADING_USERS, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_USERS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const openNew = (acc_id) => dispatch => {
    dispatch({ type: SET_ACCOUNT_ID, payload: acc_id })
    dispatch(showDrawer('user_new'));
};


export const persistChanges = (data, ftype) => dispatch => {
    dispatch({ type: PERSIST_CHANGES_USERS, payload: { data: data, ftype: ftype } })
};

export const create = (data, account_id) => dispatch => {

    dispatch({ type: LOADING_USERS, payload: true })
    obiApi.post('/users', data)
        .then(res => {
            dispatch(sendWelcomeEmail(res.data[0]))
            dispatch({ type: LOADING_USERS, payload: false })
            dispatch(closeDrawer("user_new"));
            dispatch(getAll(account_id))

        })
        .catch(err => {
            dispatch({ type: LOADING_USERS, payload: false })
            dispatch(closeDrawer("user_new"));
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};

export const update = (data, account_id) => dispatch => {
    dispatch({ type: LOADING_USERS, payload: true })
    obiApi.patch('/users/' + data.id, data)
        .then(res => {
            //dispatch({ type: LOADING_USERS, payload: false })
            dispatch(getAll(account_id))
            dispatch(closeDrawer("user_edit"));
        })
        .catch(err => {
            dispatch({ type: LOADING_USERS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};

export const del = (account_id, id, page, s = null, status = null) => dispatch => {
    dispatch({ type: LOADING_USERS, payload: true })
    obiApi.delete('/users/' + id)
        .then(res => {
            dispatch(getAll(account_id, page))
            dispatch({ type: LOADING_USERS, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_USERS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};