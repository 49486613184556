export const LOADING_ACCOUNTS = 'LOADING_ACCOUNTS'
export const RESET_ACCOUNTS = 'RESET_ACCOUNTS'
export const PERSIST_CHANGES_ACCOUNTS = 'PERSIST_CHANGES_ACCOUNTS'

export const GET_ACCOUNTS = 'GET_ACCOUNTS'
export const GET_ACCOUNT = 'GET_ACCOUNT'

export const NEW_ACCOUNT = 'NEW_ACCOUNT'

export const Account = {
    Status: {
        ACTIVE: 'ACTIVE',
        DRAFT: 'DRAFT',
        DISABLED: 'DISABLED',
        ARCHIVED: 'ARCHIVED'
    },
    CustomFields: {
        Types: {
            TEXT: "TEXT",
            TEXTAREA: "TEXTAREA",
            NUMBER: "NUMBER",
            DATE: "DATE",
            DATETIME: "DATETIME",
            SELECT: "SELECT",
            MULTISELECT: "MULTISELECT",
            CHECKBOX: "CHECKBOX",
            RADIO: "RADIO",
            HIDDEN: "HIDDEN"
        },
        Selection: [
            {
                value: "TEXT",
                label: "Text - One line input"
            },
            {
                value: "TEXTAREA",
                label: "Text - Multiline input"
            },
            {
                value: "NUMBER",
                label: "Number input"
            },
            {
                value: "DATE",
                label: "Date Picker"
            },
            {
                value: "DATETIME",
                label: "Date Time Picker"
            },
            {
                value: "TIME",
                label: "Time Picker"
            },
            {
                value: "SELECT",
                label: "Dropdown"
            },
            {
                value: "MULTISELECT",
                label: "Dropdown Multi-choice"
            },
            {
                value: "CHECKBOX",
                label: "Checkboxes Multi-choice"
            },
            {
                value: "RADIO",
                label: "Radio-Check Single-Choice"
            },
            {
                value: "HIDDEN",
                label: "Hidden"
            }
        ]

    }

}

export const CUSTOM_FIELD_INITIAL_STATE = {
    name: "",
    type: "",
    label: "",
    placeholder: "",
    selection_values: [],
    default_value: "",
    show_booking_form: true
}

export const PEOPLE_TYPE_INITIAL_STATE = {
    name: "",
    slug: "",
    custom_fields: []
}

export const ITEM_TYPE_INITIAL_STATE = {
    name: "",
    related_person_type: "",
    custom_fields: [],
    availabilities_status: [
        {
            booking_allowed: true,
            booking_allowed_label: ""
        }
    ]
}

export const FIELD_TYPES = {

}



