import obiApi from '../services';
import { SET_NOTIFICATION } from "../_base/base-const";
import { showDrawer, closeDrawer } from "../_base/drawer/drawer-action";
import {
    LOADING_INVENTORIES, GET_INVENTORIES, GET_INVENTORY,
    PERSIST_CHANGES_INVENTORIES, SET_ACCOUNT_ID,
    SET_ITEM_DISPLAY_REPORTS
} from "./inventories-const";
import { formatError } from '../../utils/utils'

export const getAllInventories = (account_id, page = 0, s = null, status = null) => dispatch => {
    dispatch({ type: LOADING_INVENTORIES, payload: true })
    obiApi.get('/inventories', {
        params: {
            page: page,
            s: s,
            status: status,
            account_id: account_id
        }
    })
        .then(res => {
            dispatch({ type: LOADING_INVENTORIES, payload: false })
            dispatch({
                type: GET_INVENTORIES,
                payload: res.data
            })
            dispatch(getItemsReports(res.data.data))
        })
        .catch(err => {
            dispatch({ type: LOADING_INVENTORIES, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const getItemsReports = (inventories) => dispatch => {
    for (const inv of inventories) {
        if (inv.items)
            for (const item of inv.items) {
                obiApi.post('/reports/inventories/' + inv.id + '/items/' + item.id, item.display)
                    .then(res => {

                        dispatch({
                            type: SET_ITEM_DISPLAY_REPORTS,
                            payload: {
                                data: res.data,
                                inv_id: inv.id,
                                item_id: item.id
                            }
                        })
                    })
                    .catch(err => {
                        dispatch({
                            type: SET_NOTIFICATION,
                            payload: { type: "error", message: formatError(err), autoclose: true }
                        })
                    });

            }

    }

};

export const openNewInventory = (acc_id) => dispatch => {
    dispatch({ type: SET_ACCOUNT_ID, payload: acc_id })
    dispatch(showDrawer('inventory_new'));
};

export const createInventory = (data, account_id) => dispatch => {
    dispatch({ type: LOADING_INVENTORIES, payload: true })
    obiApi.post('/inventories', data)
        .then(res => {
            dispatch({ type: LOADING_INVENTORIES, payload: false })
            dispatch(closeDrawer("inventory_new"));
            dispatch(getAllInventories(account_id))
        })
        .catch(err => {
            dispatch({ type: LOADING_INVENTORIES, payload: false })
            dispatch(closeDrawer("inventory_new"));
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const openEditInventory = (id) => dispatch => {
    dispatch({ type: LOADING_INVENTORIES, payload: true })
    dispatch(showDrawer('inventory_edit'))
    obiApi.get('/inventories/' + id)
        .then(res => {
            dispatch({ type: GET_INVENTORY, payload: res.data })

            dispatch({ type: LOADING_INVENTORIES, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_INVENTORIES, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};


export const updateInventory = (data, account_id) => dispatch => {
    dispatch({ type: LOADING_INVENTORIES, payload: true })
    obiApi.patch('/inventories/' + data.id, data)
        .then(res => {
            //dispatch({ type: LOADING_INVENTORIES, payload: false })
            dispatch(getAllInventories(account_id))
            dispatch(closeDrawer("inventory_edit"));
        })
        .catch(err => {
            dispatch({ type: LOADING_INVENTORIES, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};


export const deleteInventory = (id, account_id) => dispatch => {
    dispatch({ type: LOADING_INVENTORIES, payload: true })
    obiApi.delete('/inventories/' + id)
        .then(res => {
            dispatch(getAllInventories(account_id))
            dispatch({ type: LOADING_INVENTORIES, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_INVENTORIES, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const persistChanges = (data, ftype) => dispatch => {
    dispatch({ type: PERSIST_CHANGES_INVENTORIES, payload: { data: data, ftype: ftype } })
};