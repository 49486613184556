import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Accounts from "./accounts";
import { Schema } from 'rsuite';
import _ from 'lodash'
import { formatErrors, parse, slugify } from '../../utils/utils'

import { getAll, openEdit, openNew, persistChanges, create, update, del, reset } from './accounts-action'
import { closeDrawer } from '../_base/drawer/drawer-action';
import { showModal, closeModal } from '../_base/modal/modal-action';

const AccountsContainer = () => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const accounts = useSelector(state => state.accounts);
    const selected_account = useSelector(state => state.base.selected_account);
    const current_user = useSelector(state => state.base.user);
    const available_accounts = useSelector(state => state.base.available_accounts);

    useEffect(() => {
        dispatch(reset());
        dispatch(getAll());
    }, [dispatch]);

    const { StringType, ObjectType, ArrayType, NumberType, BooleanType } = Schema.Types;

    const model = Schema.Model({
        name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
        status: StringType().isRequired('This field is required.'),
        domains: StringType(),
        options: ObjectType().shape({
            items_types: ArrayType().isRequired('This field is required.'),
            sources: ArrayType().isRequired('This field is required.'),
            people_types: ArrayType().isRequired('This field is required.')
        }),
        parent_id: NumberType().isInteger("Only integer number allowed")
    });

    const optionsItemTypesModel = Schema.Model({
        name: StringType().isRequired('Item Type name is required.'),
        people_type: ObjectType().shape({
            slug: StringType().isRequired('Person Type is required.')
        }),
        availabilities_status: ArrayType().isRequired('Required.')
    });

    const availabilitiesStatusModel = Schema.Model({
        booking_allowed: BooleanType().isRequired('This field is required.'),
        booking_allowed_label: StringType().isRequired('This field is required.').rangeLength(2, 255, 'The number of characters must be between 2 and 255')
    });

    const optionsSourcesModel = Schema.Model({
        name: StringType().isRequired('This field is required.')
    });

    const optionsPeopleModel = Schema.Model({
        name: StringType().isRequired('This field is required.'),
        slug: StringType().isRequired('This field is required.')
    });

    const customFieldsModel = Schema.Model({
        name: StringType().isRequired('This field is required.'), //needs to be unique
        type: StringType().isRequired('This field is required.'),
        label: StringType().isRequired('This field is required.'),
        placeholder: StringType().isRequired('This field is required.'),
        selection_values: ArrayType(),
        default_value: StringType(),
        tooltip: StringType(),
        show_booking_form: BooleanType().isRequired('This field is required.')
    });


    const handleSave = useCallback(
        (ftype) => {
            let err = formatErrors(model.check(accounts[`${ftype}`]));
            err = !err ? {} : err;
            accounts[`${ftype}`].options.items_types.forEach((it, it_index) => {
                let terr = formatErrors(optionsItemTypesModel.check(it))
                if (terr) {
                    for (let tkey in terr) {
                        err[`options.items_types.${it_index}.${tkey}`] = terr[tkey]
                    }
                    err[`items_types`] = "Error"
                }
                if (it.availabilities_status)
                    it.availabilities_status.forEach((avs, av_index) => {
                        let aserr = formatErrors(availabilitiesStatusModel.check(avs))
                        if (aserr)
                            for (let akey in aserr) {
                                err[`options.items_types.${it_index}.availabilities_status.${av_index}.${akey}`] = aserr[akey]
                            }
                    });
                else
                    err[`options.items_types.${it_index}.name`] = "You need to add at least one Booking Labels";
            });
            accounts[`${ftype}`].options.sources.forEach((source, index) => {
                let terr = formatErrors(optionsSourcesModel.check(source))
                if (terr) {
                    for (let key in terr) {
                        err[`options.sources.${index}.${key}`] = terr[key]
                    }
                    err[`sources`] = "Error"
                }
            });
            accounts[`${ftype}`].options.people_types.forEach((ptype, index) => {
                ptype.slug = ptype.name ? slugify(ptype.name) : "";
                let terr = formatErrors(optionsPeopleModel.check(ptype))
                if (terr) {
                    for (let key in terr) {
                        err[`options.people_types.${index}.${key}`] = terr[key]
                    }
                    err[`people_types`] = "Error"
                }
            });
            if (Object.keys(err).length > 0) {
                setFormError(err)
                return
            }
            if (ftype === "new")
                dispatch(create(accounts.new))
            else if (ftype === "edit") {
                delete (accounts.edit.parent);
                dispatch(update(accounts.edit, selected_account.id))
            }
        }, [dispatch, accounts, model, selected_account, availabilitiesStatusModel, optionsSourcesModel, optionsItemTypesModel, optionsPeopleModel]
    )

    const handlePageChange = useCallback(
        page => {
            dispatch(getAll(page))
        }, [dispatch]
    )

    const handleEdit = useCallback(
        id => {
            dispatch(openEdit(id))
        }, [dispatch]
    )

    const handleDelete = useCallback(
        id => {
            dispatch(del(id, accounts.page.curr))
        }, [dispatch, accounts]
    )

    const handleNew = useCallback(
        () => {
            dispatch(openNew())
        }, [dispatch]
    )

    const handleFormChange = useCallback(
        (form, ftype) => {
            setFormError({})
            dispatch(persistChanges(parse(form), ftype))
        }, [dispatch]
    )

    const removeFormRow = useCallback(
        (key, index, ftype) => {
            const data = _.cloneDeep(accounts[`${ftype}`]);
            const arr = _.get(data, key);

            //remove people type from item type if its matching
            if (key === "options.people_types") {
                const pt = arr[index];
                data.options.items_types.forEach((it, index) => {
                    if (it.people_type.slug === pt.slug || it.people_type.id === pt.id)
                        data.options.items_types[index].people_type = {
                            slug: ''
                        }
                })
            }

            arr.splice(index, 1);

            dispatch(persistChanges(data, ftype))
        }, [dispatch, accounts]
    )

    const addFormRow = useCallback(
        (key, ftype, initial_state = {}) => {
            const data = _.cloneDeep(accounts[`${ftype}`]);
            let arr = _.get(data, key);
            if (arr) {
                arr.push(initial_state);
            } else {
                _.set(data, key, []);
                arr = _.get(data, key)
                arr.push(initial_state);
            }
            dispatch(persistChanges(data, ftype))
        }, [dispatch, accounts]
    )



    const handleCancel = useCallback(
        (ftype) => {
            dispatch(closeDrawer('account_' + ftype))
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )

    const handleValidateCustomFields = useCallback(
        (ftype, mtype) => {
            let err = {};
            accounts[`${ftype}`].options.people_types.forEach((ptype, index) => {
                if (ptype.custom_fields)
                    ptype.custom_fields.forEach((cfield, cfield_index) => {
                        let cserr = formatErrors(customFieldsModel.check(cfield))
                        if (cserr)
                            for (let ckey in cserr) {
                                err[`options.people_types.${index}.custom_fields.${cfield_index}.${ckey}`] = cserr[ckey]
                            }
                    });
            });
            if (Object.keys(err).length > 0) {
                setFormError(err)
                return
            } else {
                dispatch(closeModal(mtype))
            }

        }, [dispatch, accounts, customFieldsModel]
    )
    return (
        <>
            <Accounts
                accounts={accounts}
                available_accounts={available_accounts}
                current_user={current_user}
                handlePageChange={handlePageChange}
                handleEdit={handleEdit}
                handleNew={handleNew}
                handleDelete={handleDelete}
                handleFormChange={handleFormChange}
                removeFormRow={removeFormRow}
                addFormRow={addFormRow}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleShowModal={handleShowModal}
                handleValidateCustomFields={handleValidateCustomFields}
                formError={formError}
                model={model}
            />
        </>
    )
}

export default AccountsContainer;