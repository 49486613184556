import React from 'react';
import { Calendar } from '@mobiscroll/react_v4';
//import '@mobiscroll/react_v4/dist/css/mobiscroll.min.css';

const CalendarSinglePicker = ({ data, handleInit, handleMonthChange, handleBook }) => {
    return (
        <>
            <Calendar
                labels={data.labels}
                data={data.available}
                invalid={data.disabled}
                display="inline"
                theme='material'
                themeVariant='light'
                firstDay={1}
                type="hidden"
                min={data.min}
                onDayChange={handleBook}
                onInit={handleInit}
                onPageChange={handleMonthChange}
                dateFormat={"dd/mm/yy"}
                timeFormat={"HH:mm"}
                showOuterDays={false}
                calendarHeight={540}
            />
        </>
    )
}




export default CalendarSinglePicker;