import React, { useCallback } from 'react';
import {
    Panel, Table, Header, FlexboxGrid, IconButton, Icon, Button
} from 'rsuite';

import { StatusCell, DateCell, StartEndDateCell } from '../_common/table-cells';
import Drawer from '../_base/drawer/drawer-container';
import Modal from '../_base/modal/modal-container';
import BookingsForm from './bookings-form'
import PeoplesForm from '../peoples/peoples-form'
import moment from 'moment-timezone';

import { flattenData, unFlattenData } from '../../utils/utils'

import { Booking } from './bookings-const'


const { Column, HeaderCell, Cell, Pagination } = Table;


const Bookings = ({
    bookings, handlePageChange, handleEdit,
    handleDelete, sources, handleCancel,
    handleFormChange, model, formError,
    handleSave, removeFormRow, addFormRow,
    handleStatusChange, handleShowModal,
    current_user, peoples, users,
    handleSearchPeople, handleSearchUsers,
    handleEditPerson, selected_people_type,
    handleFormChangePeople, handleSavePeople,
    handleNewPerson, handleCancelPeople,
    peopleModel, selected_account
}) => {

    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>Bookings</h4>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Header>
                <Table loading={bookings.loading} height={600} data={flattenData(bookings.data)} wordWrap>
                    <Column width={90} >
                        <HeaderCell>Status</HeaderCell>
                        <StatusCell dataKey="status" />
                    </Column>
                    <Column width={200} >
                        <HeaderCell>Inventory</HeaderCell>
                        <Cell dataKey="inventory.name" />
                    </Column>
                    <Column width={200} >
                        <HeaderCell>Item</HeaderCell>
                        <Cell dataKey="item.name" />
                    </Column>
                    <Column width={200}>
                        <HeaderCell>People</HeaderCell>
                        <RelatedPeople dataKey="related_people" handleEditPerson={handleEditPerson} />
                    </Column>

                    <Column width={130} >
                        <HeaderCell>Start / End</HeaderCell>
                        <StartEndDateCell
                            dataKeyStart={"booked_from"}
                            dataKeyEnd={"booked_to"}
                        />
                    </Column>
                    {/*<Column width={130} >
                        <HeaderCell>End</HeaderCell>
                        <DateCell dataKey="booked_to" />
                    </Column>*/}

                    <Column width={100} >
                        <HeaderCell>Source</HeaderCell>
                        <Cell dataKey="booking_source" />
                    </Column>
                    <Column width={200} fixed={"right"}>
                        <HeaderCell className="text-center">Actions</HeaderCell>
                        <ActionCellBookings
                            dataKey="id"
                            Booking={Booking}
                            handleEditAction={handleEdit}
                            handleDeleteAction={handleDelete}
                            handleShowModal={handleShowModal}
                            handleStatusChange={handleStatusChange}
                            current_user={current_user}
                        />
                    </Column>

                </Table>
                <Pagination
                    activePage={parseInt(bookings.page.curr)}
                    total={parseInt(bookings.page.items)}
                    displayLength={50}
                    showLengthMenu={false}
                    onChangePage={handlePageChange}
                />
            </Panel>

            <Drawer
                toptitle={"Booking"}
                title={(bookings.edit.first_name ? bookings.edit.first_name : "") + " " + (bookings.edit.last_name ? bookings.edit.last_name : "")}
                subtitle={"Last edited:  " + moment(bookings.edit.updated_at).format("DD/MM/YYYY HH:mm")}
                className={"bookings"}
                type={"booking_edit"}
                handleAction={() => handleSave("edit")}
                handleCloseAction={() => handleCancel("edit")}
                loading={bookings.loading}
            >
                <BookingsForm
                    booking={bookings.edit}
                    sources={sources}
                    peoples={peoples}
                    handleFormChange={handleFormChange}
                    removeFormRow={removeFormRow}
                    addFormRow={addFormRow}
                    ftype={"edit"}
                    model={model}
                    formError={formError}
                    handleSearchPeople={handleSearchPeople}
                    handleSearchUsers={handleSearchUsers}
                    handleEditPerson={handleEditPerson}
                    handleNewPerson={handleNewPerson}
                    users={users}
                />
            </Drawer>


            <Drawer
                title={"New " + selected_people_type.name}
                className={"peoples peoples-drawer"}
                type={"people_new"}
                handleAction={() => handleSavePeople("new")}
                handleCloseAction={() => handleCancelPeople("new")}
                loading={peoples.loading}
            >
                <PeoplesForm
                    people={peoples.new}
                    selected_people_type={selected_people_type}
                    handleFormChange={handleFormChangePeople}
                    ftype={"new"}
                    model={peopleModel}
                    formError={formError}
                    selected_account={selected_account}
                />
            </Drawer>


            <Drawer
                toptitle={selected_people_type.name}
                title={peoples.edit.first_name + " " + peoples.edit.last_name}
                subtitle={"Last edited:  " + moment(peoples.edit.updated_at).format("DD/MM/YYYY HH:mm")}
                className={"peoples peoples-drawer"}
                type={"people_edit"}
                handleAction={() => handleSavePeople("edit")}
                handleCloseAction={() => handleCancelPeople("edit")}
                loading={peoples.loading}
            >
                <PeoplesForm
                    people={peoples.edit}
                    selected_people_type={selected_people_type}
                    handleFormChange={handleFormChangePeople}
                    ftype={"edit"}
                    model={peopleModel}
                    formError={formError}
                    selected_account={selected_account}
                />
            </Drawer>

        </>
    );

}

const RelatedPeople = ({ rowData, rowIndex, handleEditPerson, ...props }) => {
    let booking = unFlattenData(rowData);
    return (
        <Cell {...props}>
            {booking.related_people ? booking.related_people.map((person, index) => (
                <div key={"rp" + index}>
                    <Button className="p-0" appearance="link" onClick={() => handleEditPerson(person.id)}>{person.last_name + " " + person.first_name}</Button>
                </div>
            )) : ""}
        </Cell>
    );
};

const ActionCellBookings = ({ rowData, Booking, handleDeleteAction, handleEditAction, handleShowModal, handleStatusChange, current_user, ...props }) => {

    return (
        <Cell {...props} className="link-group">
            {current_user.permissions && current_user.permissions.includes('obi:bookings:update') ?
                <IconButton
                    circle
                    size="xs"
                    appearance="subtle"
                    color="green"
                    onClick={() => handleStatusChange(rowData, Booking.Status.ACTIVE)}
                    icon={<Icon icon="check" />}
                    //className="ml-1"
                    title={"Approve"}
                    disabled={
                        rowData.status !== Booking.Status.PENDING
                    }
                />
                : ""}
            {current_user.permissions && current_user.permissions.includes('obi:bookings:update') ?
                <IconButton
                    circle
                    size="xs"
                    appearance="subtle"
                    color="red"
                    onClick={() => handleShowModal("booking_cancel" + rowData.id)}
                    icon={<Icon icon="close" />}
                    //className="ml-1"
                    title={rowData.status === Booking.Status.PENDING ? "Decline" : "Cancel"}
                    disabled={
                        rowData.status !== Booking.Status.PENDING &&
                        rowData.status !== Booking.Status.ACTIVE
                    }
                />
                : ""}
            {current_user.permissions && current_user.permissions.includes('obi:bookings:update') ?
                <IconButton
                    circle
                    size="xs"
                    appearance="subtle"
                    color="blue"
                    onClick={() => handleShowModal("booking_resheduled" + rowData.id)}
                    icon={<Icon icon="clock-o" />}
                    //className="ml-1"
                    title={"Reschedule"}
                    disabled={
                        rowData.status !== Booking.Status.PENDING &&
                        rowData.status !== Booking.Status.ACTIVE
                    }
                />
                : ""}

            {current_user.permissions && current_user.permissions.includes('obi:bookings:update') ?
                <IconButton
                    circle
                    size="xs"
                    appearance="subtle"
                    color="orange"
                    onClick={() => handleEditAction(rowData.id)}
                    icon={<Icon icon="edit2" />}
                    className="ml-3"
                    title={"Edit"}
                />
                : ""}
            {current_user.permissions && current_user.permissions.includes('obi:bookings:update') ?
                <IconButton
                    circle
                    size="xs"
                    appearance="subtle"
                    color="yellow"
                    onClick={() => handleShowModal("booking_archive" + rowData.id)}
                    icon={<Icon icon="archive" />}
                    title={"Archive"}
                    disabled={
                        rowData.status === Booking.Status.ARCHIVED
                    }
                />
                : ""}
            {current_user.permissions && current_user.permissions.includes('obi:bookings:delete') ?
                <IconButton
                    circle
                    size="xs"
                    appearance="subtle"
                    color="red"
                    onClick={() => handleShowModal("booking_delete" + rowData.id)}
                    icon={<Icon icon="trash2" />}
                    title={"Delete"}
                />
                : ""}

            <Modal
                type={"booking_cancel" + rowData.id}
                title={"Cancel Booking"}
                handleAction={useCallback(() => {
                    handleStatusChange(rowData, rowData.status === Booking.Status.PENDING ? Booking.Status.DECLINED : Booking.Status.CANCELED)
                }, [rowData, Booking, handleStatusChange])}
                size="xs"
            >
                {`Are you sure you want to cancel/decline this booking?`}
            </Modal>
            <Modal
                type={"booking_resheduled" + rowData.id}
                title={"Reschedule Booking"}
                handleAction={useCallback(() => {
                    handleStatusChange(rowData, Booking.Status.RESCHEDULED)
                }, [rowData, Booking, handleStatusChange])}
                size="xs"
            >
                {`Are you sure you want to reschedule this booking?`}
            </Modal>
            <Modal
                type={"booking_archive" + rowData.id}
                title={"Archive Booking"}
                handleAction={useCallback(() => {
                    handleStatusChange(rowData, Booking.Status.ARCHIVED)
                }, [rowData, Booking, handleStatusChange])}
                size="xs"
            >
                {`Are you sure you want to archive this booking?`}
            </Modal>
            <Modal
                type={"booking_delete" + rowData.id}
                title={"Delete Booking"}
                handleAction={useCallback(() => {
                    handleDeleteAction(rowData.id)
                }, [rowData, handleDeleteAction])}
                size="xs"
            >
                {`Are you sure you want to delete this booking?`}
            </Modal>


        </Cell>
    );
};



export default Bookings;