import obiApi from '../services';
import { SET_CURRENT_USER, SET_NOTIFICATION, SET_SELECTED_ACCOUNT, SET_PEOPLE_TYPE, SET_CSRF_ACQUIRED } from "./base-const";
import { sessionStorageAdapter } from "../../utils/sessionStorage"
import { formatError } from '../../utils/utils'

export const getCsrfToken = () => dispatch => {
    sessionStorageAdapter.removeItem("csrfToken");
    obiApi.get('/auth/csrf')
        .then(res => {
            sessionStorageAdapter.setItem("csrfToken", res.data.csrfToken);
            obiApi.defaults.headers.post['X-CSRF-Token'] = res.data.csrfToken;
            obiApi.defaults.headers.patch['X-CSRF-Token'] = res.data.csrfToken;
            obiApi.defaults.headers.delete['X-CSRF-Token'] = res.data.csrfToken;
            dispatch({ type: SET_CSRF_ACQUIRED });
        })
        .catch(err =>
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        );
};

export const getUserProfile = (history) => dispatch => {
    obiApi.get('/users/me').then(res => {
        sessionStorageAdapter.setItem("authenticated", true);
        dispatch({
            type: SET_CURRENT_USER,
            payload: res.data
        })
        dispatch(setSelectedAccount(res.data.account_id))
        if (history.location.pathname === "/")
            history.push('/inventories');

    }).catch(err => {
        sessionStorageAdapter.setItem("authenticated", false);
        if (history.location.pathname !== "/forgot" && !history.location.pathname.includes("/reset-password"))
            history.push('/');
    });
};

export const setSelectedAccount = (account_id) => dispatch => {
    obiApi.get('/accounts/' + account_id).then(res => {
        dispatch({
            type: SET_SELECTED_ACCOUNT,
            payload: res.data
        })
    }).catch(err => {
        dispatch({
            type: SET_NOTIFICATION,
            payload: { type: "error", message: formatError(err), autoclose: true }
        })
    });
};

export const setPeopleType = (type, selected_account) => dispatch => {
    selected_account.options.people_types.forEach(ptype => {
        if (type === ptype.id || type === ptype.slug)
            dispatch({ type: SET_PEOPLE_TYPE, payload: ptype })
    })
}


export const sendWelcomeEmail = (user) => dispatch => {
    obiApi.post('/auth/welcome', {
        email: user.email,
        base_uri: process.env.REACT_APP_URL
    })
        .then(res => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "success", message: res.data.message, autoclose: true }
            })
        }).catch(err => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        });
};