import obiApi from '../../services';
import { SET_NOTIFICATION } from "../../_base/base-const";
import { showDrawer, closeDrawer } from "../../_base/drawer/drawer-action";
import { showModal } from '../../_base/modal/modal-action';
import {
    LOADING_ITEMS, PERSIST_CHANGES_ITEMS, RESET_CHANGES_ITEMS,
    GET_INVENTORY_ITEM, SET_INVENTORY_ID, Item, GET_EMBED_CODE
} from "./items-const";


import { getAvailabilities, setItem as setItemForCalendar } from "../calendars/calendars-action";
import { getAllInventories } from "../inventories-action";

import moment from 'moment-timezone';
import { getUserTz, formatError } from '../../../utils/utils';

export const openNewItem = (inv_id) => dispatch => {
    dispatch({ type: SET_INVENTORY_ID, payload: inv_id })
    dispatch(showDrawer('new'));
};


export const createItem = (data, account_id) => dispatch => {
    dispatch({ type: LOADING_ITEMS, payload: true })
    obiApi.post('/inventories/' + data.inventory_id + '/items', data)
        .then(res => {
            dispatch({ type: LOADING_ITEMS, payload: false })
            dispatch(closeDrawer("new"));
            dispatch(getAllInventories(account_id))
        })
        .catch(err => {
            dispatch({ type: LOADING_ITEMS, payload: false })
            dispatch(closeDrawer("new"));
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};


export const openEditItem = (item) => dispatch => {
    dispatch({ type: LOADING_ITEMS, payload: true })
    dispatch(showDrawer('edit' + item.id))
    obiApi.get('/inventories/' + item.inventory_id + '/items/' + item.id)
        .then(res => {
            dispatch({ type: GET_INVENTORY_ITEM, payload: res.data })
            if (res.data.status === Item.Status.ACTIVE) {
                dispatch(setItemForCalendar(res.data))
                dispatch(
                    getAvailabilities(
                        res.data.id,
                        `${moment.tz(getUserTz()).startOf('month').add(-1, 'days').format("DD/MM/YYYY")}-${moment.tz(getUserTz()).endOf('month').add(1, 'days').format("DD/MM/YYYY")}`,
                        getUserTz()
                    )
                )
            }
            dispatch({ type: LOADING_ITEMS, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_ITEMS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const updateItem = (data, account_id, selected_month) => dispatch => {

    dispatch({ type: LOADING_ITEMS, payload: true })
    obiApi.patch('/inventories/' + data.inventory_id + '/items/' + data.id, data)
        .then(res => {
            dispatch(getAllInventories(account_id))
            //dispatch({ type: GET_INVENTORY_ITEM, payload: res.data[0] })
            if (res.data[0].status === Item.Status.ACTIVE) {
                dispatch(setItemForCalendar(data));
                dispatch(getAvailabilities(
                    res.data[0].id,
                    `${moment.tz(selected_month.start, getUserTz()).add(-1, 'day').format("DD/MM/YYYY")}-${moment.tz(selected_month.end, getUserTz()).add(1, 'days').format("DD/MM/YYYY")}`,
                    getUserTz()
                ))
            }
            dispatch({ type: LOADING_ITEMS, payload: false })
            //dispatch(closeDrawer("edit"));
        })
        .catch(err => {
            dispatch({ type: LOADING_ITEMS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};


export const deleteItem = (inv_id, item_id, account_id) => dispatch => {
    dispatch({ type: LOADING_ITEMS, payload: true })
    obiApi.delete('/inventories/' + inv_id + '/items/' + item_id)
        .then(res => {
            dispatch(getAllInventories(account_id))
            dispatch({ type: LOADING_ITEMS, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_ITEMS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const newBooking = (item) => dispatch => {
    dispatch({ type: LOADING_ITEMS, payload: true })
    dispatch(showModal('booking_item' + item.id))

    obiApi.get('/inventories/' + item.inventory_id + '/items/' + item.id)
        .then(res => {
            dispatch({ type: GET_INVENTORY_ITEM, payload: res.data })
            dispatch(setItemForCalendar(res.data))
            dispatch(
                getAvailabilities(
                    res.data.id,
                    `${moment.tz(getUserTz()).startOf('month').add(-1, 'days').format("DD/MM/YYYY")}-${moment.tz(getUserTz()).endOf('month').add(1, 'days').format("DD/MM/YYYY")}`,
                    getUserTz()
                )
            )
            dispatch({ type: LOADING_ITEMS, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_ITEMS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};


export const persistChanges = (data, ftype) => dispatch => {

    //parse available_slots as integers
    if (data.availabilities)
        data.availabilities.forEach(a => {
            a.available_slots = parseInt(a.available_slots);
        })

    dispatch({ type: PERSIST_CHANGES_ITEMS, payload: { data: data, ftype: ftype } })
};

export const resetChanges = (ftype) => dispatch => {
    dispatch({ type: RESET_CHANGES_ITEMS, payload: { ftype: ftype } })
};


export const generateEmbed = (item) => dispatch => {
    dispatch({ type: LOADING_ITEMS, payload: true })
    obiApi.post('/inventories/' + item.inventory_id + '/items/' + item.item_id + '/embed', item)
        .then(res => {
            dispatch({ type: GET_EMBED_CODE, payload: res.data })
            dispatch({ type: LOADING_ITEMS, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_ITEMS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};