import {
    GET_AVAILABILITIES, SET_CALENDAR_DATA,
    MONTH_CHANGE, SELECTED_RANGE_CHANGE,
    RESET_CALENDAR_DATA, SET_ITEM, SET_HELPERS
} from './calendars-const';
import { getUserTz } from '../../../utils/utils';
import moment from 'moment-timezone';

const INITIAL_STATE = {
    item: {},
    availabilities: {
        availabilities_generated: [],
        bookings: []
    },
    calendar_data: {
        disabled: [],
        available: [],
        labels: [],
        min: moment.tz(getUserTz()).startOf('day')
    },
    selected_month: {
        start: moment.tz(getUserTz()).startOf('month').format(),
        end: moment.tz(getUserTz()).endOf('month').format()
    },
    selected_range: {
        start: null,
        end: null
    },
    helpers: {
        block_month_change: false
    }
};




export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case GET_AVAILABILITIES:
            return {
                ...state,
                availabilities: action.payload
            };
        case SET_ITEM:
            return {
                ...state,
                item: action.payload
            };
        case SET_CALENDAR_DATA:
            return {
                ...state,
                calendar_data: action.payload
            };
        case RESET_CALENDAR_DATA:
            return {
                ...INITIAL_STATE
            };
        case MONTH_CHANGE:
            return {
                ...state,
                selected_month: action.payload
            };
        case SELECTED_RANGE_CHANGE:
            return {
                ...state,
                selected_range: action.payload
            };

        case SET_HELPERS:
            return {
                ...state,
                helpers: {
                    ...state.helpers,
                    ...action.payload
                }
            };
        default:
            return state;
    }
}
