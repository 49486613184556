import React from 'react';
import {
    Form, Radio, FlexboxGrid
} from 'rsuite';

import { TextField, RadioField, CustomField, MultiSelectField } from '../_common/form-fields';
import { People } from './peoples-const'
import { getSelectionValues, genObjectsFromIds } from '../../utils/utils';

const PeoplesForm = ({ people, selected_people_type, selected_account, model, handleFormChange, ftype, formError }) => {
    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={people}
            >
                {/* <Divider className="mt-1">General</Divider>*/}
                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item className="pr-2 pb-2" colspan={12}>
                        <RadioField name="status" className="inline-radio" appearance="picker" inline>
                            <span>Status: </span>
                            <Radio value={People.Status.ACTIVE}>Active</Radio>
                            <Radio value={People.Status.ARCHIVED}>Archived</Radio>
                        </RadioField>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pr-2 pb-2" colspan={12}>

                        <MultiSelectField
                            name={"types"}
                            //label={"Person Types"}
                            placeholder="Select Person Type"
                            searchable={false}
                            data={selected_account.options.people_types}
                            onChange={(values, e) => {
                                e.preventDefault();
                                people.types = genObjectsFromIds(values, selected_account.options.people_types)
                                handleFormChange(people, ftype)
                            }}
                            labelKey={"name"}
                            valueKey={"id"}
                            cleanable={false}
                            preventOverflow={true}
                            style={{ width: 200 }}
                            value={
                                getSelectionValues(people.types).length > 0 ?
                                    getSelectionValues(people.types) :
                                    [selected_people_type]
                            }
                        //value={people.types.length > 0 ? people.types : [selected_people_type.slug]}
                        />

                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item className="pr-2 pb-2" colspan={12}>
                        <TextField name="first_name" label="First Name" style={{ width: 220 }} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pr-2 pb-2" colspan={12}>
                        <TextField name="last_name" label="Last Name" style={{ width: 220 }} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pr-2 pb-2" colspan={12}>
                        <TextField name="email" label="Email" style={{ width: 220 }} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pr-2 pb-2" colspan={12}>
                        <TextField name="phone" label="Phone" style={{ width: 220 }} />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item className="pr-2 pb-2" colspan={12}>

                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pr-2 pb-2" colspan={12}>

                    </FlexboxGrid.Item>

                    {selected_people_type.custom_fields ?
                        selected_people_type.custom_fields.map((cfield, index) => (
                            <FlexboxGrid.Item className="pr-2 pb-2" colspan={12} key={"cfield" + index}>
                                <CustomField
                                    name={`details.${cfield.name}`}
                                    label={cfield.label}
                                    placeholder={cfield.placeholder}
                                    selection_values={cfield.selection_values}
                                    ctype={cfield.type}
                                    tooltip={cfield.tooltip}
                                    value={people.details && people.details.hasOwnProperty(cfield.name) ? people.details[`${cfield.name}`] : ""}
                                    style={{ width: 220 }}
                                />
                            </FlexboxGrid.Item>
                        ))
                        : ""}

                </FlexboxGrid>

            </Form>
        </>
    );

}




export default PeoplesForm;