import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Users from "./users";
import { Schema } from 'rsuite';
import _ from 'lodash'
import { formatErrors, parse } from '../../utils/utils'

import { getAll, openEdit, openNew, persistChanges, create, update, del } from './users-action'
import { closeDrawer } from '../_base/drawer/drawer-action';
import { showModal } from '../_base/modal/modal-action';
import { User } from "../users/users-const";

const UsersContainer = () => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const users = useSelector(state => state.users);
    const current_user = useSelector(state => state.base.user);
    const selected_account = useSelector(state => state.base.selected_account);

    useEffect(() => {
        if (selected_account.id)
            dispatch(getAll(selected_account.id));
    }, [dispatch, selected_account]);

    const { StringType, NumberType } = Schema.Types;

    const model = Schema.Model({
        account_id: NumberType().isInteger("Only integer number allowed").isRequired('This field is required.'),
        first_name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
        last_name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
        email: StringType().isEmail('Please enter the correct email').isRequired('This field is required.'),
        role: StringType().isOneOf(Object.values(User.Roles), `Can only be one of: ${Object.values(User.Roles)}`).isRequired('Role is required.').isRequired('This field is required.'),
        permissions: StringType().isRequired('Permissions are required.'),
        status: StringType().isOneOf(Object.values(User.Status), `Can only be one of: ${Object.values(User.Status)}`).isRequired('Status is required.')
    });

    const handleSave = useCallback(
        (ftype) => {
            const err = formatErrors(model.check(users[`${ftype}`]));
            if (err) {
                setFormError(err)
                return;
            }

            if (ftype === "new")
                dispatch(create(users.new, selected_account.id))
            else if (ftype === "edit")
                dispatch(update(users.edit, selected_account.id))
        }, [dispatch, users, model, selected_account]
    )

    const handlePageChange = useCallback(
        page => {
            dispatch(getAll(selected_account.id, page))
        }, [dispatch, selected_account]
    )

    const handleEdit = useCallback(
        id => {
            dispatch(openEdit(id))
        }, [dispatch]
    )

    const handleDelete = useCallback(
        id => {
            dispatch(del(selected_account.id, id, users.page.curr))
        }, [dispatch, users, selected_account]
    )

    const handleNew = useCallback(
        () => {
            dispatch(openNew(selected_account.id))
        }, [dispatch, selected_account]
    )

    const handleFormChange = useCallback(
        (form, ftype) => {

            let data = _.cloneDeep(parse(form))
            if (_.isArray(data.permissions))
                data.permissions = data.permissions.join(' ')

            setFormError({})
            dispatch(persistChanges(data, ftype))
        }, [dispatch]
    )

    const removeFormRow = useCallback(
        (key, index, ftype) => {
            const data = _.cloneDeep(users[`${ftype}`]);
            const arr = _.get(data, key);
            arr.splice(index, 1);
            dispatch(persistChanges(data, ftype))
        }, [dispatch, users]
    )

    const addFormRow = useCallback(
        (key, ftype) => {
            const data = _.cloneDeep(users[`${ftype}`]);
            let arr = _.get(data, key);
            if (arr) {
                arr.push({});
            } else {
                _.set(data, key, []);
                arr = _.get(data, key)
                arr.push({});
            }
            dispatch(persistChanges(data, ftype))
        }, [dispatch, users]
    )



    const handleCancel = useCallback(
        (ftype) => {
            dispatch(closeDrawer('user_' + ftype))
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )

    return (
        <>
            <Users
                users={users}
                current_user={current_user}
                handlePageChange={handlePageChange}
                handleEdit={handleEdit}
                handleNew={handleNew}
                handleDelete={handleDelete}
                handleFormChange={handleFormChange}
                removeFormRow={removeFormRow}
                addFormRow={addFormRow}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleShowModal={handleShowModal}
                formError={formError}
                model={model}
            />
        </>
    )
}

export default UsersContainer;