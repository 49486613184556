import React from 'react';
import { Badge } from 'rsuite'
import { Eventcalendar } from '@mobiscroll/react';
import { getUserTz } from '../../../utils/utils'
import moment from 'moment-timezone';


const CalendarTimeSlotsPicker = ({ data, handleInit, handleMonthChange, handleBookTimeSlot }) => {
    const min = moment.tz(data.min,getUserTz()).startOf('day').subtract(1,'seconds'); //fix as it was disabling whole day
    return (
        <Eventcalendar
            view={{
                calendar: {                    
                    type: 'month',
                    labels: true,
                    popover: true,
                    outerDays: false
                }
            }
            }
            theme='ios'
            labels={data.labels}
            data={data.available}
            firstDay={1}
            min={min}
            //onInit={handleInit}
            onEventClick={handleBookTimeSlot}
            renderEvent={renderEvent}
            onPageChange={handleMonthChange}
            dateFormat={"dd/mm/yy"}
            timeFormat={"HH:mm"}
            height={540}
        />
    )
}

const renderEvent = (data) => {
    const available_slots = parseInt(data.original.available_slots);
    return (
        <>
            <div className="mbsc-event-time mbsc-ios mbsc-ltr">
                <div className="mbsc-event-start mbsc-ios">{data.start}</div>
                <div className="mbsc-event-end mbsc-ios">{data.end}</div>
            </div>
            <div className="mbsc-event-color mbsc-ios"></div>
            <div className="mbsc-event-text mbsc-ios" title={data.title}>{data.title}</div>


            <Badge
                className={"cl-available-slots status-badge " + (parseInt(data.original.available_slots) > 0 ? "active" : "draft")}
                content={available_slots > 0 ? available_slots : data.original.booked_label}
            />
        </>
    )
};




export default CalendarTimeSlotsPicker;