import React from 'react';
import { Radio, FlexboxGrid, Panel } from 'rsuite';
import { TextField, RadioField, SelectField, TextFieldLabelAddOn, NumberField } from '../../../_common/form-fields';


import { genAvailabilityStatusData } from '../../../../utils/utils';
import { Item } from '../items-const'


const PanelBookingSettings = ({ item, formError, options, ...rest }) => {
    return (
        <Panel
        collapsible
            header="Booking Settings"
            expanded={
                formError.calendar_type ||
                    formError.calendar_type ||
                    formError.slot_type ||
                    formError.slot_interval ||
                    formError.slot_min ||
                    formError.slot_max
                    ? true : undefined
            }
            {...rest}
        >
            <FlexboxGrid>
                <FlexboxGrid.Item className="pr-3">
                    <RadioField
                        name="calendar_type"
                        className="inline-radio"
                        appearance="picker"
                        inline
                        //label={"Calendar Type"}
                        message={<div>
                            {"Permanent"}<br />
                            {"For bookings with unknown end date."}<br /><br />
                            {"Date Range"}<br />
                            {"For bookings with start and end dates."}<br /><br />
                            {"Time Slots"}<br />
                            {"For bookings with fixed time slots in hours/minutes"}
                        </div>
                        }
                        value={item.calendar_type}
                    >
                        <span>Calendar type: </span>
                        <Radio value={Item.CalendarType.SINGLE_DATE}>Permanent</Radio>
                        <Radio value={Item.CalendarType.DATES_RANGE}>Date Range</Radio>
                        <Radio value={Item.CalendarType.TIME_SLOTS}>Time Slots</Radio>
                    </RadioField>
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <FlexboxGrid className="pt-3">
                <FlexboxGrid.Item className={"pr-3 " + (item.calendar_type !== Item.CalendarType.TIME_SLOTS ? "d-none" : "")}>
                    <RadioField
                        name="slot_type"
                        className="inline-radio"
                        appearance="picker"
                        inline
                        label={"Slot Type"}
                        message={"How availabilities slots are going to be split in your calendar."}
                        value={item.slot_type}
                    >
                        <Radio value={Item.SlotType.HOURLY}>per hour</Radio>
                        <Radio value={Item.SlotType.MINUTELY}>per minute</Radio>
                    </RadioField>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item className={"pr-3 " + (item.calendar_type !== Item.CalendarType.TIME_SLOTS ? "d-none" : "")}>
                    <TextFieldLabelAddOn
                        name="slot_interval"
                        type="number"
                        min={1}
                        label={"Slot Interval"}
                        addon={
                            //item.slot_type === Item.SlotType.DAILY ? "day" + (item.slot_interval > 1 ? "s" : "") :
                            item.slot_type === Item.SlotType.HOURLY ? "hour" + (item.slot_interval > 1 ? "s" : "") :
                                item.slot_type === Item.SlotType.MINUTELY ? "minute" + (item.slot_interval > 1 ? "s" : "") : ""
                        }
                        right
                        inside
                        style={{ width: 120 }}
                        message={"Length of 1 slot"}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="pr-3">
                    <TextField
                        name="slot_label"
                        label="Slot Label (optional)"
                        message={"Used for calendar display. Enter label as singular noun. Examples: bed, room, visit, meeting, event,... Default: slot"}
                        style={{ width: 120 }}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <SelectField
                        name="booked_label"
                        label={"Booked Label (optional)"}
                        placeholder="Select label"
                        data={genAvailabilityStatusData(options, item.item_type_id, false)}
                        //value={item.item_type}
                        cleanable={false}
                        searchable={false}
                        message={"Label when date/slot is booked. Default: `booked`"}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid className="pt-3">
                {item.calendar_type === Item.CalendarType.DATES_RANGE ?
                    <>
                        <FlexboxGrid.Item>
                            <TextFieldLabelAddOn
                                name="slot_min"
                                type="number"
                                min={0}
                                label={"Min Days (optional)"}
                                addon={
                                    item.slot_type === Item.SlotType.DAILY ? "day" + (item.slot_min > 1 ? "s" : "") :
                                        item.slot_type === Item.SlotType.HOURLY ? "hour" + (item.slot_min > 1 ? "s" : "") :
                                            item.slot_type === Item.SlotType.MINUTELY ? "minute" + (item.slot_min > 1 ? "s" : "") : ""
                                }
                                right
                                inside
                                style={{ width: 130 }}
                                message={"Set minimum number of days user needs to book."}
                            />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="px-3">
                            <TextFieldLabelAddOn
                                name="slot_max"
                                type="number"
                                min={parseInt(item.slot_min)}
                                label={"Max Days (optional)"}
                                addon={
                                    item.slot_type === Item.SlotType.DAILY ? "day" + (item.slot_max > 1 ? "s" : "") :
                                        item.slot_type === Item.SlotType.HOURLY ? "hour" + (item.slot_max > 1 ? "s" : "") :
                                            item.slot_type === Item.SlotType.MINUTELY ? "minute" + (item.slot_max > 1 ? "s" : "") : ""
                                }
                                right
                                inside
                                style={{ width: 130 }}
                                message={"Set maximum number of days user can book."}
                            />
                        </FlexboxGrid.Item>
                    </> : ""}

                <FlexboxGrid.Item>
                    <label className="rs-control-label">Allow bookings after (optional)</label>
                    <FlexboxGrid className="align-items-end">
                        <FlexboxGrid.Item>
                            <NumberField
                                name="booking_after.value"
                                type="number"
                                min={0}
                                style={{ width: 70 }}
                                value={item.booking_after ? item.booking_after.value : 0}
                            />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item style={{ paddingBottom: 5 }}>
                            <SelectField
                                name={`booking_after.period`}
                                //placeholder="Select Weekday"
                                searchable={false}
                                data={Item.Availabilities.BookingAfterTypes}
                                preventOverflow={true}
                                style={{ width: 140 }}
                                value={item.booking_after ? item.booking_after.period : "days"}
                                cleanable={false}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Panel>
    )
}

export default PanelBookingSettings;