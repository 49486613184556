export const SET_CSRF_ACQUIRED= 'SET_CSRF_ACQUIRED';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT';
export const SET_PEOPLE_TYPE = 'SET_PEOPLE_TYPE';

export const GET_AVAILABLE_ACCOUNTS = 'GET_AVAILABLE_ACCOUNTS';
export const SET_MENU_EXPAND = 'SET_MENU_EXPAND';

export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const LOADING = 'LOADING'


export const SHOW_DRAWER = 'SHOW_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';

export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';