import React from 'react';

import { Container, Content, FlexboxGrid, Panel, Col } from 'rsuite';
import ForgotLoginForm from './forgot-form'

const ForgotLogin = ({ handleFormSubmit, handleChange, credentials, model, formError, history }) => {
    return (
        <>
            <Container className={"login-page login-bg"}>
                <Content className={"pt-lg-5 pt-3"}>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item componentClass={Col} lgOffset={6} lg={6} md={24}>
                            <div className="text-center">
                                <img src={require("../../assets/images/logo.svg")} alt="logo" />
                                <h4 className="text-white py-2 text-center">Welcome to Mirus Obi</h4>
                            </div>
                            <Panel className={"bg-white"} header={<h5 className="text-primary">Forgot Password</h5>} bordered shaded>
                                <ForgotLoginForm
                                    handleFormSubmit={handleFormSubmit}
                                    handleChange={handleChange}
                                    credentials={credentials}
                                    model={model}
                                    formError={formError}
                                    history={history}
                                />
                            </Panel>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Content>
            </Container>

        </>
    )
}

export default ForgotLogin;