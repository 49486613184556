import React, { useCallback } from 'react';
import {
    Panel, Header, FlexboxGrid,
    IconButton, Icon, Button
} from 'rsuite';
import { Inventory } from './inventories-const'
import ItemsContainer from './items/items-container'
import Modal from '../_base/modal/modal-container';
import Drawer from '../_base/drawer/drawer-container';
import InventoriesForm from './inventories-form'
import moment from 'moment-timezone';

const Inventories = ({
    inventories,
    current_user,
    selected_account,
    inventoryModel,
    formError,
    handleEditInventory,
    handleNewInventory,
    handleNewItem,
    handleDeleteInventory,
    handleSaveInventory,
    handleCancelInventory,
    handleFormChange,
    removeFormRow,
    addFormRow,
    handleShowModal
}) => {

    return (
        <>
            <Header className="pb-2">
                <FlexboxGrid>
                    <FlexboxGrid.Item>
                    <h4>Inventories - {selected_account.name}</h4>
                    </FlexboxGrid.Item>
                    {current_user.permissions && current_user.permissions.includes('obi:inventories:create') ?
                        <FlexboxGrid.Item className="flex-grow-1 text-right">
                            <IconButton
                                size="xs"
                                placement="left"
                                onClick={() => handleNewInventory()}
                                icon={<Icon icon="plus-square-o" />}
                            >
                                Create Inventory
                            </IconButton>
                        </FlexboxGrid.Item>
                        : ""}
                </FlexboxGrid>
            </Header>

            {inventories.data.length > 0 ? inventories.data.map((inventory, inv_index) => (
                <Panel
                    bordered
                    className="inventory mt-2 mb-2"
                    key={"inv" + inv_index}
                    header={
                        <InventoryPanelHeader
                            id={inventory.id}
                            name={inventory.name}
                            status={inventory.status}
                            handleNewItem={handleNewItem}
                            handleEditInventory={handleEditInventory}
                            handleDeleteInventory={handleDeleteInventory}
                            handleShowModal={handleShowModal}
                            current_user={current_user}
                        />
                    }
                >
                    <FlexboxGrid>
                        <ItemsContainer
                            inv_id={inventory.id}
                            inventory={inventory}
                        />
                    </FlexboxGrid>
                </Panel>
            )) : <FlexboxGrid.Item className={"py-3 text-center flex-grow-1"}>
                    {"No invetories found. Click on 'Create Inventory' in top right corner to start..."}
                </FlexboxGrid.Item>}



            <Drawer
                toptitle={"Inventory"}
                title={inventories.edit.name}
                subtitle={"Last edited:  " + moment(inventories.edit.updated_at).format("DD/MM/YYYY HH:mm")}
                type={"inventory_edit"}
                handleAction={() => handleSaveInventory("edit")}
                handleCloseAction={() => handleCancelInventory("edit")}
                loading={inventories.loading}
            >
                <InventoriesForm
                    inventory={inventories.edit}
                    handleFormChange={handleFormChange}
                    removeFormRow={removeFormRow}
                    addFormRow={addFormRow}
                    ftype={"edit"}
                    model={inventoryModel}
                    formError={formError}
                />
            </Drawer>

            <Drawer
                title={"New Inventory"}
                type={"inventory_new"}
                handleAction={() => handleSaveInventory("new")}
                handleCloseAction={() => handleCancelInventory("new")}
                loading={inventories.loading}
            >
                <InventoriesForm
                    inventory={inventories.new}
                    handleFormChange={handleFormChange}
                    removeFormRow={removeFormRow}
                    addFormRow={addFormRow}
                    ftype={"new"}
                    handleSave={handleSaveInventory}
                    handleCancel={handleCancelInventory}
                    loading={inventories.loading}
                    model={inventoryModel}
                    formError={formError}
                />
            </Drawer>


        </>
    );
}

const InventoryPanelHeader = ({ id, name, status, handleNewItem, handleEditInventory, handleDeleteInventory, handleShowModal, current_user }) => {
    return (
        <>
            <FlexboxGrid>
                <FlexboxGrid.Item className={"ml-2 show-action-icons-hover action-icons"}>
                    {current_user.permissions && current_user.permissions.includes('obi:inventories:update') ?
                        <Button
                            className="p-0 mr-2"
                            appearance="link"
                            onClick={() => handleEditInventory(id)}
                        >
                            <h6>{status !== Inventory.Status.ACTIVE ? "(" + status + ")" : ""} {name}</h6>
                        </Button>
                        : <h6 className="p-0 mr-2 d-inline-block">{status !== Inventory.Status.ACTIVE ? "(" + status + ")" : ""} {name}</h6>
                    }
                    {current_user.permissions && current_user.permissions.includes('obi:inventories:update') ?
                        <IconButton
                            circle
                            appearance="subtle"
                            size="xs"
                            color="orange"
                            onClick={() => handleEditInventory(id)}
                            icon={<Icon icon="edit2" />} />
                        : ""}
                    {current_user.permissions && current_user.permissions.includes('obi:inventories:delete') ?
                        <IconButton
                            circle
                            appearance="subtle"
                            size="xs"
                            color="red"
                            onClick={() => handleShowModal("inventory_delete" + id)}
                            icon={<Icon icon="trash2" />} />
                        : ""}
                    <Modal
                        type={"inventory_delete" + id}
                        title={"Delete"}
                        handleAction={useCallback(() => {
                            handleDeleteInventory(id)
                        }, [id, handleDeleteInventory])}
                        size="xs"
                    >
                        <div>
                            <p>{`Are you sure you want to delete ${name}?`}</p>
                            <p><strong>{`This will delete all items and bookings under this inventory!!!`}</strong></p>
                        </div>
                    </Modal>


                </FlexboxGrid.Item>
                <FlexboxGrid.Item className={"flex-grow-1 text-right"}>
                    {current_user.permissions && current_user.permissions.includes('obi:items:create') ?
                        <IconButton size="xs" placement="left" onClick={() => handleNewItem(id)} icon={<Icon icon="plus-square-o" />}>
                            New Item
                    </IconButton>
                        : ""}
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </>
    )
}



export default Inventories;