import React from 'react';
import { Form, FormGroup, FormControl, ButtonToolbar, Button } from 'rsuite';

const ForgotLoginForm = ({ handleFormSubmit, handleChange, credentials, model, formError, history }) => {
    return (
        <>
            <Form
                fluid
                onChange={(e) => handleChange(e)}
                model={model}
                checkTrigger={"blur"}
                formError={formError}
                formValue={credentials}
            >
                <FormGroup>
                    <FormControl name="email" placeholder="Email" value={credentials.email} />
                </FormGroup>
                <FormGroup>
                    <ButtonToolbar>
                        <Button appearance="primary" onClick={() => handleFormSubmit()}>Send to Email</Button>
                        <Button appearance="link" onClick={() => history.push("/")}>Back to Login</Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>
        </>
    )
}

export default ForgotLoginForm;