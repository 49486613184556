import obiApi from '../services';
import { SET_NOTIFICATION } from "../_base/base-const";

import { showDrawer, closeDrawer } from "../_base/drawer/drawer-action";
import {
    LOADING_PEOPLES, GET_PEOPLES, GET_PEOPLE,
    PERSIST_CHANGES_PEOPLES, SET_ACCOUNT_ID,
    SET_PEOPLE_TYPE_NEW
} from "./peoples-const";
import { formatError } from '../../utils/utils'

export const getAll = (type, account_id, page, s = null, status = null, ids = null) => dispatch => {

    dispatch({ type: LOADING_PEOPLES, payload: true })

    obiApi.get('/peoples', {
        params: {
            type: type,
            account_id: account_id,
            page: page,
            s: s,
            status: status,
            ids: ids
        }
    })
        .then(res => {
            dispatch({ type: LOADING_PEOPLES, payload: false })
            dispatch({
                type: GET_PEOPLES,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({ type: LOADING_PEOPLES, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const openEdit = (id) => dispatch => {
    dispatch({ type: LOADING_PEOPLES, payload: true })
    dispatch(showDrawer('people_edit'));

    obiApi.get('/peoples/' + id)
        .then(res => {
            dispatch({ type: GET_PEOPLE, payload: res.data })
            dispatch({ type: LOADING_PEOPLES, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_PEOPLES, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const openNew = (acc_id, ptype) => dispatch => {
    dispatch({ type: SET_PEOPLE_TYPE_NEW, payload: [ptype] })
    dispatch({ type: SET_ACCOUNT_ID, payload: acc_id })
    dispatch(showDrawer('people_new'));
};


export const persistChanges = (data, ftype) => dispatch => {
    dispatch({ type: PERSIST_CHANGES_PEOPLES, payload: { data: data, ftype: ftype } })
};

export const create = (data, account_id, type) => dispatch => {

    dispatch({ type: LOADING_PEOPLES, payload: true })
    obiApi.post('/peoples', data)
        .then(res => {

            dispatch({ type: LOADING_PEOPLES, payload: false })
            dispatch(closeDrawer("people_new"));
            dispatch(getAll(type, account_id))

        })
        .catch(err => {
            dispatch({ type: LOADING_PEOPLES, payload: false })
            dispatch(closeDrawer("people_new"));
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};

export const update = (data, account_id, type) => dispatch => {
    dispatch({ type: LOADING_PEOPLES, payload: true })
    obiApi.patch('/peoples/' + data.id, data)
        .then(res => {
            //dispatch({ type: LOADING_PEOPLES, payload: false })
            dispatch(getAll(type, account_id))
            dispatch(closeDrawer("people_edit"));
        })
        .catch(err => {
            dispatch({ type: LOADING_PEOPLES, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};

export const del = (type, account_id, id, page, s = null, status = null) => dispatch => {
    dispatch({ type: LOADING_PEOPLES, payload: true })
    obiApi.delete('/peoples/' + id)
        .then(res => {
            dispatch(getAll(type, account_id, page))
            dispatch({ type: LOADING_PEOPLES, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_PEOPLES, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};