import React from 'react';
import { Link } from 'react-router-dom'
import { Navbar, Nav, Icon, Sidebar, Sidenav, SelectPicker, Header, Dropdown } from 'rsuite';
import { slugify } from '../../../utils/utils'

const Navigation = ({ isAdmin, current_user, selected_account, expand, handleToggle, handleLogout, available_accounts, handleChangeAccount }) => {
    return (
        <>
            <Header id="top-nav">
                <Navbar appearance="inverse">
                    <Navbar.Header>
                        <h4 className="pt-1">
                            <Icon icon="calendar" size="lg" />
                            <span className="ml-2"> Mirus Obi</span>
                        </h4>
                    </Navbar.Header>
                    <Navbar.Body>
                        <Nav pullRight>

                            {isAdmin ?
                                <Dropdown
                                    className={"settings"}
                                    icon={<Icon icon="cog" />}
                                    eventKey="1"
                                    placement={"bottomEnd"}
                                >
                                    <Dropdown.Item componentClass={Link} to="/accounts" eventKey="1-1">Manage Accounts</Dropdown.Item>
                                    <Dropdown.Item componentClass={Link} to="/users" eventKey="1-2">Manage Users</Dropdown.Item>
                                </Dropdown>
                                : ""}


                            <SelectPicker
                                className="selected-account d-inline-block"
                                placeholder="Select Account"
                                data={available_accounts}
                                labelKey={'name'}
                                valueKey={'id'}
                                onChange={id => handleChangeAccount(id)}
                                value={selected_account.id ? selected_account.id : ""}
                                cleanable={false}
                                size={"sm"}
                                placement={"bottomEnd"}
                            />


                            <Dropdown
                                className="user-profile"
                                eventKey="2"
                                title={
                                    <div className="initials">
                                        {
                                            current_user.first_name && current_user.last_name ?
                                                current_user.first_name.charAt(0).toUpperCase() + current_user.last_name.charAt(0).toUpperCase() : ""
                                        }
                                    </div>
                                }
                                placement={"bottomEnd"}
                            >
                                {/*<Dropdown.Item componentClass={Link} to="/profile" eventKey="2-1">My Profile</Dropdown.Item>*/}
                                <Dropdown.Item
                                    //componentClass={Link} 
                                    onClick={() => handleLogout()}
                                    //to="/users" 
                                    eventKey="2-2"
                                >Log out</Dropdown.Item>
                            </Dropdown>

                        </Nav>
                    </Navbar.Body>
                </Navbar>
            </Header>


            <Sidebar id="menu" className="bg-primary11" width={expand ? 200 : 56} collapsible >
                <Sidenav
                    className="menu-items"
                    expanded={expand}
                //appearance="inverse"
                >
                    <Sidenav.Body>
                        <Nav activeKey={1}>
                            {/*<Nav.Item componentClass={Link} to="/dashboard" eventKey="1" active icon={<Icon icon="dashboard" />}>Dashboard</Nav.Item>*/}

                            <Nav.Item componentClass={Link} to="/inventories" eventKey="2" icon={<Icon icon="object-ungroup" />}>Inventories</Nav.Item>
                            <Nav.Item componentClass={Link} to="/bookings" eventKey="3" icon={<Icon icon="calendar-check-o" />}>Bookings</Nav.Item>

                            {
                                selected_account &&
                                    selected_account.options &&
                                    selected_account.options.people_types &&
                                    selected_account.options.people_types.length > 0
                                    ? selected_account.options.people_types.map((ptype, index) => (
                                        <Nav.Item
                                            key={"ptype" + index}
                                            componentClass={Link}
                                            to={"/people/" + slugify(ptype.name)}
                                            eventKey={`${index + 3}`}
                                            icon={<Icon icon="group" />}
                                        >{ptype.name}</Nav.Item>
                                    ))
                                    : ""}








                            {/*isAdmin ?
                            <Nav.Item componentClass={Link} to="/accounts" eventKey="4" icon={<Icon icon="building" />}>Accounts</Nav.Item>
                        : ""*/}
                            {/*<Nav.Item componentClass={Link} to="/users" eventKey="4" icon={<Icon icon="group" />}>Users</Nav.Item>
                            <Nav.Item componentClass={Link} to="/accounts" eventKey="5" icon={<Icon icon="building" />}>Accounts</Nav.Item>*/}

                            {/*<Dropdown eventKey="4" trigger="hover" title="Settings" icon={<Icon icon="gear-circle" />} placement="rightStart">
                            <Dropdown.Item componentClass={Link} to="/options" eventKey="4-4">General Options</Dropdown.Item>
                            <Dropdown.Item componentClass={Link} to="/company" eventKey="4-1">Company Account</Dropdown.Item>
                            <Dropdown.Item componentClass={Link} to="/users" eventKey="4-2">Manage Users</Dropdown.Item>
                            <Dropdown.Item componentClass={Link} to="/profile" eventKey="4-3">My Profile</Dropdown.Item>
                            <Dropdown.Item componentClass={Link} to="/api" eventKey="4-5">API Keys</Dropdown.Item>
                        </Dropdown>*/}
                        </Nav>


                        {/*isAdmin ?
                        <div className="selected-account">
                            <SelectPicker
                                placeholder="Select Account"
                                data={available_accounts}
                                labelKey={'name'}
                                valueKey={'id'}
                                onChange={id => handleChangeAccount(id)}
                                value={selected_account.id ? selected_account.id : ""}
                                cleanable={false}
                            />
                        </div>
                        : ""
                    */}

                    </Sidenav.Body>
                </Sidenav>


                <Navbar className="nav-toggle">
                    <Navbar.Body>
                        <Nav pullRight>
                            <Nav.Item onClick={handleToggle} style={{ width: 56, textAlign: 'center' }}>
                                <Icon icon={expand ? 'angle-left' : 'angle-right'} />
                            </Nav.Item>
                        </Nav>
                    </Navbar.Body>
                </Navbar>
            </Sidebar>
        </>
    );
}

export default Navigation;