import React from 'react';
import { Form, FormGroup, FormControl, ButtonToolbar, Button } from 'rsuite';

const ResetPasswordForm = ({ handleFormSubmit, handleChange, credentials, model, formError, history }) => {
    return (
        <>
            <Form
                fluid
                onChange={(e) => handleChange(e)}
                model={model}
                checkTrigger={"blur"}
                formError={formError}
                formValue={credentials}
            >
                <FormGroup>
                    <FormControl name="reset_token" type="hidden" value={credentials.reset_token} />
                    <FormControl className="mb-2" name="new_password" type="password" placeholder="New Password" value={credentials.password} />
                    <FormControl name="repeat_password" type="password" placeholder="Repeat Password" value={credentials.password} />
                </FormGroup>
                <FormGroup>                    
                    <ButtonToolbar>
                        <Button appearance="primary" onClick={() => handleFormSubmit()}>Confirm Change</Button>
                        <Button appearance="link" onClick={() => history.push("/")}>Back to Login</Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>
        </>
    )
}

export default ResetPasswordForm;