import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Schema } from 'rsuite';
import Inventories from "./inventories";

import { Inventory } from "./inventories-const";
import { User } from "../users/users-const";

import {
    getAllInventories, openEditInventory, openNewInventory, persistChanges,
    createInventory, updateInventory, deleteInventory
} from './inventories-action'
import { getAll as getAllUsers } from '../users/users-action'
import { openNewItem } from './items/items-action'
import { closeDrawer } from '../_base/drawer/drawer-action';
import { showModal } from '../_base/modal/modal-action';

import { parse, formatErrors } from '../../utils/utils'
import _ from 'lodash'


const { StringType, NumberType } = Schema.Types;

const InventoriesContainer = () => {
    const dispatch = useDispatch();
    const selected_account = useSelector(state => state.base.selected_account);
    const current_user = useSelector(state => state.base.user);
    const admin = useSelector(state => state.base.admin);

    const inventories = useSelector(state => state.inventories);
    const [formError, setFormError] = useState({});

    useEffect(() => {
        if (selected_account.id) {
            dispatch(getAllInventories(selected_account.id));
        }
    }, [dispatch, selected_account, admin]);

    const inventoryModel = Schema.Model({
        account_id: NumberType().isInteger("Only integer number allowed").isRequired('This field is required.'),
        name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
        status: StringType().isOneOf(Object.values(Inventory.Status), `Can only be one of: ${Object.values(Inventory.Status)}`).isRequired('This field is required.')
    });

    const handleEditInventory = useCallback(
        id => {
            dispatch(openEditInventory(id))
        }, [dispatch]
    )



    const handleDeleteInventory = useCallback(
        id => {
            dispatch(deleteInventory(id, selected_account.id))
        }, [dispatch, selected_account]
    )



    const handleSaveInventory = useCallback(
        (ftype) => {
            const err = formatErrors(inventoryModel.check(inventories[`${ftype}`]));
            if (err) {
                setFormError(err)
                return;
            }

            if (ftype === "new")
                dispatch(createInventory(inventories.new, selected_account.id))
            else if (ftype === "edit")
                dispatch(updateInventory(inventories.edit, selected_account.id))

        }, [dispatch, inventories, selected_account, inventoryModel]
    )



    const handleNewInventory = useCallback(
        () => {
            dispatch(openNewInventory(selected_account.id))
        }, [dispatch, selected_account]
    )


    const handleFormChange = useCallback(
        (form, ftype) => {
            setFormError({})
            dispatch(persistChanges(parse(form), ftype))
        }, [dispatch]
    )

    const removeFormRow = useCallback(
        (key, index, ftype) => {
            const data = _.cloneDeep(inventories[`${ftype}`]);
            const arr = _.get(data, key);
            arr.splice(index, 1);
            dispatch(persistChanges(data, ftype))
        }, [dispatch, inventories]
    )

    const addFormRow = useCallback(
        (key, ftype, initial_state = {}) => {
            const data = _.cloneDeep(inventories[`${ftype}`]);
            let arr = _.get(data, key);
            if (arr) {
                arr.push(initial_state);
            } else {
                _.set(data, key, []);
                arr = _.get(data, key)
                arr.push(initial_state);
            }
            dispatch(persistChanges(data, ftype))
        }, [dispatch, inventories]
    )

    const handleCancelInventory = useCallback(
        (ftype) => {
            dispatch(closeDrawer('inventory_' + ftype))
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )

    const handleNewItem = useCallback(
        (inv_id) => {
            dispatch(getAllUsers(selected_account.id, 0, null, User.Status.ACTIVE))
            dispatch(openNewItem(inv_id))
        }, [dispatch, selected_account]
    )

    return (
        <>
            <Inventories
                current_user={current_user}
                selected_account={selected_account}
                inventories={inventories}
                inventoryModel={inventoryModel}
                handleEditInventory={handleEditInventory}
                handleNewInventory={handleNewInventory}
                handleNewItem={handleNewItem}
                handleDeleteInventory={handleDeleteInventory}
                handleSaveInventory={handleSaveInventory}
                handleCancelInventory={handleCancelInventory}
                handleFormChange={handleFormChange}
                removeFormRow={removeFormRow}
                addFormRow={addFormRow}
                handleShowModal={handleShowModal}
                formError={formError}
            />
        </>
    )
}

export default InventoriesContainer;