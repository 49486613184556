import moment from 'moment-timezone';
import { getUserTz } from '../../../utils/utils'
import _ from 'lodash'
import { Item } from '../items/items-const'


const isInDisabled = (start, disabled) => {
    for (const dis of disabled) {
        if (new Date(start) >= new Date(dis.start) && new Date(start) <= new Date(dis.end)) {
            return true
        }
    }
    return false;
}

export const getDisabled = (item) => {
    if (!item.availabilities_generated)
        return []
    let availabilities = _.cloneDeep(item.availabilities_generated);
    availabilities = _.orderBy(availabilities, ['booking_allowed', 'start'], ['asc', 'asc'])
    let disabled = [];
    //get disabled first

    availabilities.forEach(d => {
        if (!d.booking_allowed) {
            d.start = moment.tz(d.start, getUserTz());
            d.end = moment.tz(d.end, getUserTz());
            disabled.push(d)
        }
    });


    //sumarize available slots for case there is same time slot in different positions
    availabilities = mergeSameTimeSlots(availabilities);
    //get booked, cut bookings from available slots
    availabilities = reduceAvailableSlots(item, availabilities);

    //add booked to disabled
    if (item.calendar_type === Item.CalendarType.SINGLE_DATE || item.calendar_type === Item.CalendarType.DATES_RANGE) {
        availabilities.forEach(d => {
            if (d.available_slots < 1 && !isInDisabled(d.start, disabled)) {
                d.start = moment.tz(d.start, getUserTz());
                d.end = moment.tz(d.end, getUserTz());
                d.booking_allowed_label = item.booked_label ? item.booked_label : "Booked"
                disabled.push(d)
            }
        });


        //adjust disabled times for daily bookings because when there is difference between user tz and calendar tz more dates get disabled

        if (moment.tz(getUserTz()).utcOffset() !== moment.tz(item.tz).utcOffset()) {
            disabled.forEach(d => {
                if (moment.tz(getUserTz()).utcOffset() < moment.tz(item.tz).utcOffset()) {
                    d.start = d.start.startOf('day')
                    d.end = d.end.subtract(1, 'days').endOf('day')
                } else {
                    d.start = d.start.add(1, 'days').startOf('day')
                    d.end = d.end.endOf('day')
                }
            })
        }
    }

    return disabled;
}



export const getAvailable = (item, min) => {
    if (!item.availabilities_generated)
        return []
    let availabilities = _.cloneDeep(item.availabilities_generated);
    let available = [];
    const disabled = getDisabled(item);
    //order availabilities so disabled comes first for later compare
    availabilities = _.orderBy(availabilities, ['booking_allowed', 'start'], ['asc', 'asc'])


    availabilities.forEach(d => {
        //only future dates which are not disabled
        if (
            d.booking_allowed &&
            moment.tz(d.start, getUserTz()).isSameOrAfter(min) &&
            !isInDisabled(d.start, disabled)
        ) {
            d.start = moment.tz(d.start, getUserTz());
            d.end = moment.tz(d.end, getUserTz());
            //d.title = item.item_type + ": " + item.name;
            d.title = item.name;
            d.booked_label = item.booked_label ? item.booked_label : "Booked"
            d.slot_label = item.slot_label ? item.slot_label : "slot"
            d.color = '#1a9a55';
            d.item_id = item.id;
            available.push(d)
        }
    });


    //sumarize available slots for case there is same time slot in different positions
    available = mergeSameTimeSlots(available);
    //get booked, cut bookings from available slots
    available = reduceAvailableSlots(item, available);

    return available;
}

const reduceAvailableSlots = (item, available) => {
    item.bookings.forEach(booking => {
        available.forEach(a => {
            if (
                (moment(booking.booked_from).isSameOrAfter(a.start) && moment(booking.booked_to).subtract(1, "seconds").isSameOrBefore(a.end)) || //covering just for hourly/minutley bookings

                (!booking.booked_to && moment(booking.booked_from).isSameOrBefore(a.end)) || //covering daily bookings without end date

                (
                    item.slot_type === "DAILY" &&
                    booking.booked_to &&
                    booking.booked_from &&
                    moment(booking.booked_from).isSameOrBefore(a.start) &&
                    moment(booking.booked_to).isAfter(a.start)
                ) //covering daily bookings with start and end

            ) {
                a.available_slots -= 1;
            }

        })
    })
    return available
}

const mergeSameTimeSlots = (availabilities) => {
    let o = {}
    let merged = availabilities.reduce(function (r, e) {
        let key = e.start + '|' + e.end;
        if (!o[key]) {
            o[key] = e;
            r.push(o[key]);
        } else {
            o[key].available_slots += e.available_slots;
        }
        return r;
    }, []);
    return merged
}

const getAvailableDays = (item, disabled, min) => {
    let available_days = [];
    let availabilities = _.cloneDeep(item.availabilities_generated);
    availabilities = _.orderBy(availabilities, ['booking_allowed', 'start'], ['asc', 'asc'])
    availabilities.forEach(d => {
        if (d.booking_allowed && moment.tz(d.start, getUserTz()).isSameOrAfter(min) && !isInDisabled(d.start, disabled)) {
            available_days.push(moment.tz(d.start, getUserTz()).format("DD/MM/YYYY"))
            available_days = _.uniq(available_days)
        }
    });
    return available_days
}

export const getLabels = (item, min) => {
    if (!item.availabilities_generated)
        return []
    let labels = [];
    const disabled = getDisabled(item);
    const available = getAvailable(item, min);
    const available_days = getAvailableDays(item, disabled, min);

    // set labels for available dates
    available_days.forEach(ad => {
        let c = 0; //total slots per day avialable
        available.forEach(a => {
            if (ad === a.start.format("DD/MM/YYYY"))
                c += parseInt(a.available_slots)
        });
        if (c > 0)
            labels.push({
                date: moment.tz(ad, "DD/MM/YYYY", getUserTz()).startOf("day").format(),
                d: moment.tz(ad, "DD/MM/YYYY", getUserTz()).startOf("day").format(),
                text: c + " " + (item.slot_label ? item.slot_label : "slot") + (c > 1 ? "s" : "") + " available",
                color: '#1a9a55'
            })
        else
            labels.push({
                date: moment.tz(ad, "DD/MM/YYYY", getUserTz()).startOf("day").format(),
                d: moment.tz(ad, "DD/MM/YYYY", getUserTz()).startOf("day").format(),
                text: item.booked_label ? item.booked_label : "Booked",
                color: 'gray'
            })
    })

    // set labels for unavailable dates
    disabled.forEach(d => {

        //shift time if disabled dates are matching with available because 2 labels can't be show in calendar
        if (item.calendar_type === Item.CalendarType.TIME_SLOTS) {
            if (available_days.includes(moment.tz(d.start, getUserTz()).format("DD/MM/YYYY"))) {
                d.start = moment.tz(d.start, getUserTz()).add(1, 'day').startOf('day');
            }
            if (available_days.includes(moment.tz(d.end, getUserTz()).format("DD/MM/YYYY"))) {
                d.end = moment.tz(d.end, getUserTz()).add(-1, 'day').endOf('day');
            }
        }
        labels.push({
            start: moment.tz(d.start, getUserTz()).isBefore(moment()) ? moment.tz(getUserTz()).startOf('day').format() : moment.tz(d.start, getUserTz()).format(),
            end: moment.tz(d.end, getUserTz()).format(),
            isMultiDay: true,
            text: d.booking_allowed_label,
            color: 'gray',
            allDay: true,
            disabled: true
        })
    })
  
    return labels;

}