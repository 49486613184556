export const LOADING_BOOKINGS = 'LOADING_BOOKINGS'
export const PERSIST_CHANGES_BOOKINGS = 'PERSIST_CHANGES_BOOKINGS'
export const RESET_BOOKINGS = 'RESET_BOOKINGS'

export const SET_SELECTED_PEOPLE = 'SET_SELECTED_PEOPLE'

export const GET_BOOKINGS = 'GET_BOOKINGS'
export const GET_BOOKING = 'GET_BOOKING'

export const NEW_BOOKING = 'NEW_BOOKING'

export const Booking = {
    Status: {
        ACTIVE: 'ACTIVE',
        PENDING: 'PENDING',
        CANCELED: 'CANCELED',
        DECLINED: 'DECLINED',
        RESCHEDULED: 'RESCHEDULED',
        ARCHIVED: 'ARCHIVED'
    }
}



