import React from 'react';
import { Form, FlexboxGrid, Icon, IconButton } from 'rsuite';
import { SelectField, UserSelectField } from '../../_common/form-fields';
import moment from 'moment-timezone'
import { getUserTz } from '../../../utils/utils';
import { genUserSelectionData, genObjectsFromIds, getSelectionValues } from '../../../utils/utils';

const CalendarBookingsForm = ({
    peoples, users, booking, sources, model, handleFormChange, handleSearchUsers, handleSearchPeople,
    handleNewPerson, handleEditPerson, ftype, formError
}) => {

    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={booking}
            >

                <FlexboxGrid className="pb-2">
                    {moment(booking.booked_from).isValid() ?
                        <FlexboxGrid.Item>
                            <strong>{moment.tz(booking.booked_from, getUserTz()).format("DD/MM/YYYY HH:mm")} </strong>
                        </FlexboxGrid.Item>
                        : ""}

                    {moment(booking.booked_to).isValid() ?
                        <FlexboxGrid.Item className="pl-1">
                            <strong> - {moment.tz(booking.booked_to, getUserTz()).format("DD/MM/YYYY HH:mm")}</strong>
                        </FlexboxGrid.Item>
                        : ""}
                </FlexboxGrid>
                <FlexboxGrid className="pb-2">
                    <FlexboxGrid.Item className="pb-2" colspan={24}>
                        <SelectField
                            name="booking_source"
                            label="Booking Source"
                            labelKey={"name"}
                            valueKey={"name"}
                            data={sources}
                            value={booking.source}
                            style={{ width: 220 }}
                            searchable={false}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pb-2" colspan={24}>
                        <UserSelectField
                            name="related_people"
                            placeholder="Search/Select Persons"
                            label="Who is booking"
                            message={"Search or Select all persons related to this booking"}
                            data={genUserSelectionData(peoples.data)}
                            onChange={(values, e) => {
                                e.preventDefault();
                                booking.related_people = genObjectsFromIds(values, peoples.data)
                                handleFormChange(booking, ftype)
                            }}
                            onSearch={(s) => handleSearchPeople(s)}
                            onSelect={() => handleSearchPeople(null)}
                            onExited={() => handleSearchPeople(null)}
                            value={getSelectionValues(booking.related_people)}
                            size={"sm"}
                            handleEdit={handleEditPerson}
                        />

                        <IconButton
                            size="xs"
                            placement="left"
                            onClick={() => handleNewPerson()}
                            icon={<Icon icon="plus-square-o" />}
                        //title={"Add New Person"}
                        >Add New Person</IconButton>

                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="pb-2" colspan={24}>
                        <UserSelectField
                            name="related_users"
                            placeholder="Select Team Members"
                            label="Related team members (optional)"
                            message={"If this booking is related to any team member you can set it here."}
                            onChange={(values, e) => {
                                e.preventDefault();
                                booking.related_users = genObjectsFromIds(values, users)
                                handleFormChange(booking, ftype)
                            }}
                            data={genUserSelectionData(users)}
                            value={getSelectionValues(booking.related_users)}
                            size={"sm"}
                            onSearch={handleSearchUsers}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Form>
        </>
    );

}

export default CalendarBookingsForm;