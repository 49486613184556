export const LOADING_PEOPLES = 'LOADING_PEOPLES'
export const PERSIST_CHANGES_PEOPLES = 'PERSIST_CHANGES_PEOPLES'

export const GET_PEOPLES = 'GET_PEOPLES'
export const GET_PEOPLE = 'GET_PEOPLE'

export const NEW_PEOPLE = 'NEW_PEOPLE'
export const SET_ACCOUNT_ID = 'SET_ACCOUNT_ID'
export const SET_PEOPLE_TYPE_NEW = 'SET_PEOPLE_TYPE_NEW'

export const People = {
    Status: {
        ACTIVE: 'ACTIVE',
        PENDING: 'PENDING',
        DISABLED: 'DISABLED',
        ARCHIVED: 'ARCHIVED'
    }
}



