import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Peoples from "./peoples";
import { Schema } from 'rsuite';
import _ from 'lodash'
import { formatErrors, parse } from '../../utils/utils'

import { getAll, openEdit, openNew, persistChanges, create, update, del } from './peoples-action'

import { setPeopleType } from '../_base/base-action'
import { closeDrawer } from '../_base/drawer/drawer-action';
import { showModal } from '../_base/modal/modal-action';
import { People } from "../peoples/peoples-const";

const { StringType, NumberType, ArrayType, ObjectType } = Schema.Types;
export const model = Schema.Model({
    account_id: NumberType().isInteger("Only integer number allowed").isRequired('This field is required.'),
    types: ArrayType().isRequired('Select at least one.'),
    first_name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required'),
    last_name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required'),
    email: StringType().isEmail('Please enter the correct email'),
    phone: StringType(),
    status: StringType().isOneOf(Object.values(People.Status), `Can only be one of: ${Object.values(People.Status)}`),
    related_people: ArrayType(),
    related_users: ArrayType(),
    details: ObjectType()
});

/*
const relatedModel = Schema.Model({
    id: NumberType().isInteger("Only integer number allowed").isRequired('Item Type name is required.'),
    relationship_type: StringType().isRequired('This field is required.')
});*/


const PeoplesContainer = ({ match }) => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const peoples = useSelector(state => state.peoples);
    const current_user = useSelector(state => state.base.user);
    const selected_account = useSelector(state => state.base.selected_account);
    const people_type = match.params.people_type;
    const selected_people_type = useSelector(state => state.base.selected_people_type);

    useEffect(() => {
        if (people_type && selected_account.id) {
            dispatch(setPeopleType(people_type, selected_account));
        }
        if (selected_people_type.id && selected_account.id) {
            dispatch(getAll(selected_people_type.id, selected_account.id));
        }
    }, [dispatch, people_type, selected_account, match, selected_people_type]);

    const handleSave = useCallback(
        (ftype) => {
            const err = formatErrors(model.check(peoples[`${ftype}`]));
            if (err) {
                setFormError(err)
                return;
            }

            if (ftype === "new")
                dispatch(create(peoples.new, selected_account.id, selected_people_type.id))
            else if (ftype === "edit")
                dispatch(update(peoples.edit, selected_account.id, selected_people_type.id))
        }, [dispatch, peoples, selected_account, selected_people_type]
    )

    const handlePageChange = useCallback(
        page => {
            dispatch(getAll(selected_people_type.id, selected_account.id, page))
        }, [dispatch, selected_people_type, selected_account]
    )

    const handleEdit = useCallback(
        id => {
            dispatch(openEdit(id))
        }, [dispatch]
    )

    const handleDelete = useCallback(
        id => {
            dispatch(del(selected_people_type.id, selected_account.id, id, peoples.page.curr))
        }, [dispatch, peoples, selected_account, selected_people_type]
    )

    const handleNew = useCallback(
        () => {
            dispatch(openNew(selected_account.id, selected_people_type))
        }, [dispatch, selected_account, selected_people_type]
    )

    const handleFormChange = useCallback(
        (form, ftype) => {

            let data = _.cloneDeep(parse(form))
            if (_.isArray(data.permissions))
                data.permissions = data.permissions.join(' ')

            setFormError({})
            dispatch(persistChanges(data, ftype))
        }, [dispatch]
    )

    const removeFormRow = useCallback(
        (key, index, ftype) => {
            const data = _.cloneDeep(peoples[`${ftype}`]);
            const arr = _.get(data, key);
            arr.splice(index, 1);
            dispatch(persistChanges(data, ftype))
        }, [dispatch, peoples]
    )

    const addFormRow = useCallback(
        (key, ftype) => {
            const data = _.cloneDeep(peoples[`${ftype}`]);
            let arr = _.get(data, key);
            if (arr) {
                arr.push({});
            } else {
                _.set(data, key, []);
                arr = _.get(data, key)
                arr.push({});
            }
            dispatch(persistChanges(data, ftype))
        }, [dispatch, peoples]
    )



    const handleCancel = useCallback(
        (ftype) => {
            dispatch(closeDrawer('people_' + ftype))
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )

    return (
        <>
            <Peoples
                peoples={peoples}
                selected_people_type={selected_people_type}
                current_user={current_user}
                selected_account={selected_account}
                handlePageChange={handlePageChange}
                handleEdit={handleEdit}
                handleNew={handleNew}
                handleDelete={handleDelete}
                handleFormChange={handleFormChange}
                removeFormRow={removeFormRow}
                addFormRow={addFormRow}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleShowModal={handleShowModal}
                formError={formError}
                model={model}
            />
        </>
    )
}

export default PeoplesContainer;