import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import Router from './router';
import '../assets/rsuite/rsuite.css';
import '../assets/main.scss';
import { getCsrfToken, getUserProfile } from './_base/base-action';

const AdminApp = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const csrf_acquired = useSelector(state => state.base.csrf_token_acquired)
    useEffect(() => {
        dispatch(getCsrfToken());
        if (csrf_acquired)
            dispatch(getUserProfile(history));
    }, [dispatch, history, csrf_acquired]);

    return (
        csrf_acquired ?
            <Router />
            : ""
    )
}

export default AdminApp;