import React, { useCallback } from 'react';
import {
    Panel, Table, Header, FlexboxGrid, IconButton, Icon
} from 'rsuite';

import { StatusCell, DateCell } from '../_common/table-cells';
import Drawer from '../_base/drawer/drawer-container';
import Modal from '../_base/modal/modal-container';
import PeoplesForm from './peoples-form'
import moment from 'moment-timezone';

const { Column, HeaderCell, Cell, Pagination } = Table;


const Peoples = ({
    peoples, selected_people_type, selected_account,
    current_user, handlePageChange, handleEdit,
    handleDelete, handleNew, handleCancel,
    handleFormChange, model, formError,
    handleSave, removeFormRow, addFormRow,
    handleShowModal
}) => {

    return (
        <>
            <Panel shaded className="bg-white">
                <Header className="pb-2">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <h4>{selected_people_type.name}</h4>
                        </FlexboxGrid.Item>
                        {current_user.permissions && current_user.permissions.includes('obi:peoples:create') ?
                            <FlexboxGrid.Item className="text-right" colspan={12}>
                                <IconButton
                                    size="xs"
                                    placement="left"
                                    onClick={() => handleNew()}
                                    icon={<Icon icon="plus-square-o" />}
                                >Add New</IconButton>
                            </FlexboxGrid.Item>
                            : ""}
                    </FlexboxGrid>
                </Header>
                <Table loading={peoples.loading} height={600} data={peoples.data}>
                    <Column width={80} >
                        <HeaderCell>Status</HeaderCell>
                        <StatusCell dataKey="status" />
                    </Column>
                    <Column width={150} >
                        <HeaderCell>First Name</HeaderCell>
                        <Cell dataKey="first_name" />
                    </Column>
                    <Column width={150} >
                        <HeaderCell>Last Name</HeaderCell>
                        <Cell dataKey="last_name" />
                    </Column>
                    <Column width={150} >
                        <HeaderCell>Email</HeaderCell>
                        <Cell dataKey="email" />
                    </Column>
                    <Column width={120} >
                        <HeaderCell>Phone</HeaderCell>
                        <Cell dataKey="phone" />
                    </Column>
                    <Column width={120} >
                        <HeaderCell>Created</HeaderCell>
                        <DateCell dataKey="created_at" />
                    </Column>
                    <Column width={120} >
                        <HeaderCell>Last Update</HeaderCell>
                        <DateCell dataKey="updated_at" />
                    </Column>

                    <Column width={100} fixed={"right"}>
                        <HeaderCell>Actions</HeaderCell>
                        <ActionCell
                            dataKey="id"
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handleShowModal={handleShowModal}
                            current_user={current_user}
                        />
                    </Column>
                </Table>
                <Pagination
                    activePage={parseInt(peoples.page.curr)}
                    total={parseInt(peoples.page.items)}
                    displayLength={100}
                    showLengthMenu={false}
                    onChangePage={handlePageChange}
                />
            </Panel>


            <Drawer
                toptitle={selected_people_type.name}
                title={peoples.edit.first_name + " " + peoples.edit.last_name}
                subtitle={"Last edited:  " + moment(peoples.edit.updated_at).format("DD/MM/YYYY HH:mm")}
                className={"peoples"}
                type={"people_edit"}
                handleAction={() => handleSave("edit")}
                handleCloseAction={() => handleCancel("edit")}
                loading={peoples.loading}
            >
                <PeoplesForm
                    people={peoples.edit}
                    selected_people_type={selected_people_type}
                    handleFormChange={handleFormChange}
                    removeFormRow={removeFormRow}
                    addFormRow={addFormRow}
                    ftype={"edit"}
                    model={model}
                    formError={formError}
                    selected_account={selected_account}
                />
            </Drawer>

            <Drawer
                title={"New " + selected_people_type.name}
                className={"peoples"}
                type={"people_new"}
                handleAction={() => handleSave("new")}
                handleCloseAction={() => handleCancel("new")}
                loading={peoples.loading}
            >
                <PeoplesForm
                    people={peoples.new}
                    selected_people_type={selected_people_type}
                    handleFormChange={handleFormChange}                  
                    ftype={"new"}
                    model={model}
                    formError={formError}
                    selected_account={selected_account}
                />
            </Drawer>

        </>
    );

}


const ActionCell = ({ rowData, dataKey, handleEdit, handleDelete, handleShowModal, current_user, ...props }) => {
    return (
        <Cell {...props} className="link-group">
            {current_user.permissions && current_user.permissions.includes('obi:peoples:update') ?
                <IconButton
                    circle
                    size="xs"
                    appearance="subtle"
                    color="orange"
                    onClick={() => handleEdit(rowData.id)}
                    icon={<Icon icon="edit2" />}
                />
                : ""}
            {current_user.permissions && current_user.permissions.includes('obi:peoples:delete') ?
                <IconButton
                    circle
                    size="xs"
                    appearance="subtle"
                    color="red"
                    onClick={() => handleShowModal('people_delete' + rowData.id)}
                    icon={<Icon icon="trash2" />}
                    className="ml-1"
                />
                : ""}
            <Modal
                type={"people_delete" + rowData.id}
                title={"Delete"}
                handleAction={useCallback(() => {
                    handleDelete(rowData.id)
                }, [rowData, handleDelete])}
                size="xs"
            >
                {`Are you sure you want to delete people ${rowData.name}?`}
            </Modal>
        </Cell>
    );
};


export default Peoples;