import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import Login from "./login";
import { loginUser } from './login-action';
import { Schema } from 'rsuite';

import { formatErrors } from '../../utils/utils'

const LoginContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [formError, setFormError] = useState({});
    const [credentials, setCredentials] = useState({ email: "", password: "" });


    const { StringType } = Schema.Types;

    const model = Schema.Model({
        email: StringType().isEmail('Please enter the correct email').isRequired('This field is required.'),
        password: StringType().isRequired('This field is required.'),
    });

    const handleChange = useCallback(
        form => {
            setFormError({})
            setCredentials({
                email: form.email ? form.email : "",
                password: form.password ? form.password : ""
            })
        }, [setCredentials, setFormError]
    )

    const handleFormSubmit = useCallback(
        () => {
            const err = formatErrors(model.check(credentials));
            if (err) {
                setFormError(err)
                return;
            }
            dispatch(loginUser(credentials, history))
        }, [dispatch, credentials, history, model, setFormError]
    )

    return (
        <Login
            handleChange={handleChange}
            handleFormSubmit={handleFormSubmit}
            credentials={credentials}
            model={model}
            formError={formError}
            history={history}
        />
    )
}

export default LoginContainer;