
import obiApi from '../../services';
import { SET_NOTIFICATION } from "../../_base/base-const";
import {
    GET_AVAILABILITIES, SET_ITEM, SET_CALENDAR_DATA,
    MONTH_CHANGE, SELECTED_RANGE_CHANGE, RESET_CALENDAR_DATA,
    SET_HELPERS
} from "./calendars-const";

import { LOADING_PEOPLES } from "../../peoples/peoples-const";
import { closeDrawer } from "../../_base/drawer/drawer-action";
import { getAll as getAllPeoples } from "../../peoples/peoples-action";
import { persistChanges as persistChangesBooking } from "../../bookings/bookings-action";
import { getLabels, getDisabled, getAvailable } from './calendars-utils'
import moment from 'moment-timezone';
import { cloneDeep } from 'lodash'
import { getUserTz, formatError } from '../../../utils/utils';



export const getAvailabilities = (item_id, scope, user_tz) => dispatch => {
    obiApi.get('/items/' + item_id + "/availabilities", {
        params: {
            scope: scope,
            tz: user_tz
        }
    })
        .then(res => {
            dispatch({
                type: GET_AVAILABILITIES,
                payload: res.data,
            })
            dispatch(setCalendarData(res.data))
        })
        .catch(err => {
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const setItem = (item) => dispatch => {
    dispatch({ type: SET_ITEM, payload: item })
};
export const setHelpers = (data) => dispatch => {
    dispatch({ type: SET_HELPERS, payload: data })
};

export const setCalendarData = (item) => dispatch => {
    let min = moment.tz(getUserTz());
    if (item.booking_after && item.booking_after.value > 0) {
        min = moment().add(item.booking_after.value, item.booking_after.period)
    }
    /*if (item.slot_type === "DAILY")
        min.startOf('day')//.subtract(1, 'seconds')*/

    dispatch({
        type: SET_CALENDAR_DATA,
        payload: {
            disabled: getDisabled(item),
            available: getAvailable(item, min),
            labels: getLabels(item, min),
            min: min
        }
    })
};

export const resetCalendarData = () => dispatch => {
    dispatch({ type: RESET_CALENDAR_DATA })
};

export const changeMonth = (new_month) => dispatch => {
    dispatch({ type: MONTH_CHANGE, payload: new_month })
};

export const changeRange = (new_range) => dispatch => {
    dispatch({ type: SELECTED_RANGE_CHANGE, payload: new_range })
};

export const createPerson = (data, account_id, type, new_booking) => dispatch => {
    dispatch({ type: LOADING_PEOPLES, payload: true })
    obiApi.post('/peoples', data)
        .then(res => {
            dispatch({ type: LOADING_PEOPLES, payload: false })
            dispatch(closeDrawer("people_new"));
            dispatch(getAllPeoples(type, account_id))
            let nb = cloneDeep(new_booking)
            nb.related_people.push(res.data[0]);
            dispatch(persistChangesBooking(nb, 'new'))
        })
        .catch(err => {
            dispatch({ type: LOADING_PEOPLES, payload: false })
            dispatch(closeDrawer("people_new"));
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};



