import { combineReducers } from 'redux';
import baseReducer from '../components/_base/base-reducer';
import accountsReducer from '../components/accounts/accounts-reducer';
import usersReducer from '../components/users/users-reducer';
import inventoriesReducer from '../components/inventories/inventories-reducer';
import itemsReducer from '../components/inventories/items/items-reducer';
import calendarsReducer from '../components/inventories/calendars/calendars-reducer.js';
import bookingsReducer from '../components/bookings/bookings-reducer';
import peoplesReducer from '../components/peoples/peoples-reducer';

const rootReducer = combineReducers({
    base: baseReducer,
    accounts: accountsReducer,
    users: usersReducer,
    inventories: inventoriesReducer,
    items: itemsReducer,
    calendars: calendarsReducer,
    bookings: bookingsReducer,
    peoples: peoplesReducer
});
export default rootReducer;