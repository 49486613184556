import React from 'react';
import { Form, Button } from 'rsuite';
import { SelectField } from '../../_common/form-fields';
const ItemsFormEmbed = ({
    item, model, handleFormChange, ftype, formError, sources,
    handleGenerateEmbed
}) => {

    return (
        <>
            <Form
                model={model}
                onChange={(formValue, e) => {
                    handleFormChange(formValue, ftype)
                }}
                checkTrigger={"blur"}
                formError={formError}
                formValue={item}
            >


                <SelectField
                    name="booking_source"
                    label="Booking Source"
                    placeholder={"Select booking source"}
                    labelKey={"name"}
                    valueKey={"name"}
                    data={sources}
                    value={item.booking_source}
                    style={{ width: 300 }}
                    searchable={false}
                //onChange={(value) => { if (value) handleGenerateEmbed(item) }}
                />
                {item.code ?
                    <div className="code-snippet">
                        <pre>
                            {`<script id="mirus-obi-loader" src="${process.env.REACT_APP_EMBED_URL}/loader.js" type="text/javascript" async></script>`}
                            <br />
                            {`<div id="mirus-obi-wrap-${item.code}"></div>`}
                            <br />
                        </pre>
                    </div>
                    : ""}
                <Button
                    onClick={() => {
                        handleGenerateEmbed()
                    }}
                    size={"sm"}
                    appearance="primary"
                >Generate Embed Code</Button>



            </Form>
        </>
    );

}




export default ItemsFormEmbed;