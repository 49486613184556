import React from 'react';
import { Radio, FlexboxGrid, Panel } from 'rsuite';
import { RadioField, TextFieldLabelAddOn } from '../../../_common/form-fields';


const PanelDisplay = ({ formError, item, handleFormChange, ftype, ...rest }) => {
    return (
        <Panel
            collapsible
            header="Customize Display"
            expanded={
                formError.approval ||
                    formError.notify_clients ||
                    formError.notify_users ||
                    formError.max_bookings_allowed
                    ? true : undefined
            }
            {...rest}
        >
            <FlexboxGrid>
                <FlexboxGrid.Item className="pr-4">
                    <RadioField
                        name="display.all_time_bookings.visible"
                        className="inline-radio"
                        appearance="picker"
                        inline
                        message={"Display total number of bookings for this item."}
                        value={item.display.all_time_bookings.visible}
                    >
                        <span>All Time Bookings:</span>
                        <Radio value={true}>Visible</Radio>
                        <Radio value={false}>Hidden</Radio>
                    </RadioField>
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <FlexboxGrid className="pt-3">
                <FlexboxGrid.Item className="pr-4">
                    <RadioField
                        name="display.availability_next_x_days_in_precentage.visible"
                        className="inline-radio"
                        appearance="picker"
                        inline
                        message={"Display availability percentage for next X days. Line will be displayed as progress bar."}
                        value={item.display.availability_next_x_days_in_precentage.visible}
                    >
                        <span>Availability % for next X days:</span>
                        <Radio value={true}>Visible</Radio>
                        <Radio value={false}>Hidden</Radio>
                    </RadioField>
                </FlexboxGrid.Item>
                {item.display.availability_next_x_days_in_precentage.visible ?
                    <FlexboxGrid.Item>
                        <TextFieldLabelAddOn
                            name="display.availability_next_x_days_in_precentage.days"
                            type="number"
                            min={0}
                            addon={"day" + (item.display.availability_next_x_days_in_precentage.days > 1 ? "s" : "")}
                            right
                            inside
                            style={{ width: 130 }}
                            value={item.display.availability_next_x_days_in_precentage.days}
                        />
                    </FlexboxGrid.Item>
                    : ""}
            </FlexboxGrid>

            <FlexboxGrid className="pt-3">
                <FlexboxGrid.Item className="pr-4">
                    <RadioField
                        name="display.current_availability.visible"
                        className="inline-radio"
                        appearance="picker"
                        inline
                        message={"Display current availability of this item."}
                        value={item.display.current_availability.visible}
                    >
                        <span>Current Availability:</span>
                        <Radio value={true}>Visible</Radio>
                        <Radio value={false}>Hidden</Radio>
                    </RadioField>
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <FlexboxGrid className="pt-3">
                <FlexboxGrid.Item className="pr-4">
                    <RadioField
                        name="display.total_bookings_past_x_days.visible"
                        className="inline-radio"
                        appearance="picker"
                        inline
                        message={"Display total number of bookings for last X days. Enter number of days to be included in report."}
                        value={item.display.total_bookings_past_x_days.visible}
                    >
                        <span>Total bookings for last X days:</span>
                        <Radio value={true}>Visible</Radio>
                        <Radio value={false}>Hidden</Radio>
                    </RadioField>
                </FlexboxGrid.Item>
                {item.display.total_bookings_past_x_days.visible ?
                    <FlexboxGrid.Item>
                        <TextFieldLabelAddOn
                            name="display.total_bookings_past_x_days.days"
                            type="number"
                            min={0}
                            addon={"day" + (item.display.total_bookings_past_x_days.days > 1 ? "s" : "")}
                            right
                            inside
                            style={{ width: 130 }}
                            value={item.display.total_bookings_past_x_days.days}
                        />
                    </FlexboxGrid.Item>
                    : ""}
            </FlexboxGrid>

            <FlexboxGrid className="pt-3">
                <FlexboxGrid.Item className="pr-4">
                    <RadioField
                        name="display.next_availability.visible"
                        className="inline-radio"
                        appearance="picker"
                        inline
                        message={"Display date when this item will be available again."}
                        value={item.display.next_availability.visible}
                    >
                        <span>Next Availability Date:</span>
                        <Radio value={true}>Visible</Radio>
                        <Radio value={false}>Hidden</Radio>
                    </RadioField>
                </FlexboxGrid.Item>
            </FlexboxGrid>


        </Panel>
    )
}

export default PanelDisplay;