import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setExpand, logoutUser, getAvailableAccounts } from './nav-action';
import { setSelectedAccount } from '../base-action';
import Navigation from './nav';

const NavContainer = ({ expand }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const current_user = useSelector(state => state.base.user);
    const isAdmin = useSelector(state => state.base.admin);
    const selected_account = useSelector(state => state.base.selected_account);
    const available_accounts = useSelector(state => state.base.available_accounts);

    useEffect(() => {
        dispatch(getAvailableAccounts());
    }, [dispatch]);


    const handleToggle = useCallback(
        () => {
            dispatch(setExpand(!expand))
        }, [dispatch, expand]
    )

    const handleLogout = useCallback(
        () => {
            dispatch(logoutUser(history))
        }, [dispatch, history]
    )

    const handleChangeAccount = useCallback(
        id => {
            dispatch(setSelectedAccount(id));
        }, [dispatch]
    )

    return (
        <Navigation
            isAdmin={isAdmin}
            current_user={current_user}
            selected_account={selected_account}
            available_accounts={available_accounts}
            expand={expand}
            handleChangeAccount={handleChangeAccount}
            handleToggle={handleToggle}
            handleLogout={handleLogout}
        />
    )
}

export default NavContainer;