import React from 'react';
import { Form, PanelGroup } from 'rsuite';



import PanelGeneral from './form-panels/general';
import PanelInventoryConfig from './form-panels/inventory-config';
import PanelBookingConfig from './form-panels/booking-config';

const AccountsForm = ({
    account, current_user, available_accounts,
    model, handleFormChange, ftype,
    removeFormRow, addFormRow,
    handleShowModal, formError,
    handleValidateCustomFields
}) => {
    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                checkTrigger={"blur"}
                formError={formError}
                formValue={account}
            >
                <PanelGroup bordered>
                    <PanelGeneral
                        account={account}
                        formError={formError}
                        current_user={current_user}
                        available_accounts={available_accounts}
                    />
                    <PanelBookingConfig
                        account={account}
                        formError={formError}
                        removeFormRow={removeFormRow}
                        addFormRow={addFormRow}
                        ftype={ftype}
                        handleShowModal={handleShowModal}
                        handleValidateCustomFields={handleValidateCustomFields}
                    />
                    <PanelInventoryConfig
                        account={account}
                        formError={formError}
                        removeFormRow={removeFormRow}
                        addFormRow={addFormRow}
                        ftype={ftype}
                        handleShowModal={handleShowModal}
                        handleValidateCustomFields={handleValidateCustomFields}
                    />
                </PanelGroup>             

            </Form>
        </>
    );

}




export default AccountsForm;