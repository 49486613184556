import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { LayoutPublic, LayoutPrivate } from './_base/base';

import LoginContainer from './login/login-container';
import ForgotLoginContainer from './login/forgot-container';
import ResetPasswordContainer from './login/reset-container';
import DashboardContainer from './dashboard/dashboard-container';
import AccountsContainer from './accounts/accounts-container';
import InventoriesContainer from './inventories/inventories-container';
import BookingsContainer from './bookings/bookings-container';
import UsersContainer from './users/users-container';
import PeoplesContainer from './peoples/peoples-container';
const Router = () => {
    return (
        <>
            <Switch>
                <RouteWrapper exact path="/" component={LoginContainer} layout={LayoutPublic} />
                <RouteWrapper exact path="/forgot" component={ForgotLoginContainer} layout={LayoutPublic} />
                <RouteWrapper exact path="/reset-password/:token" component={ResetPasswordContainer} layout={LayoutPublic} />

                <RouteWrapper exact path="/dashboard" component={DashboardContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/accounts" component={AccountsContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/inventories" component={InventoriesContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/bookings" component={BookingsContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/users" component={UsersContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/people/:people_type" component={PeoplesContainer} layout={LayoutPrivate} />
                {/*<RouteWrapper exact path="/company" component={DashboardContainer} layout={LayoutPrivate} />
                <RouteWrapper exact path="/profile" component={DashboardContainer} layout={LayoutPrivate} />                
                <RouteWrapper exact path="/options" component={DashboardContainer} layout={LayoutPrivate} />*/}
            </Switch>
        </>
    );
}

function RouteWrapper({
    component: Component,
    layout: Layout,
    ...rest
}) {
    return (
        <Route {...rest} render={(props) =>
            <Layout {...props}>
                <Component {...props} />
            </Layout>
        } />
    );
}

export default Router;