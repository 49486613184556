import React from 'react';
import Dashboard from "./dashboard";


const DashboardContainer = () => {
    return (
        <Dashboard />
    )
}

export default DashboardContainer;