export const LOADING_ITEMS = 'LOADING_ITEMS'
export const PERSIST_CHANGES_ITEMS = 'PERSIST_CHANGES_ITEMS'
export const RESET_CHANGES_ITEMS = 'RESET_CHANGES_ITEMS'
export const SET_INVENTORY_ID = 'SET_INVENTORY_ID'

export const GET_INVENTORY_ITEM = 'GET_INVENTORY_ITEM'
export const GET_EMBED_CODE = 'GET_EMBED_CODE'


export const Item = {
    CalendarType: {
        SINGLE_DATE: 'SINGLE_DATE',
        DATES_RANGE: 'DATES_RANGE',
        TIME_SLOTS: 'TIME_SLOTS'
    },
    SlotType: {
        DAILY: 'DAILY',
        HOURLY: 'HOURLY',
        MINUTELY: 'MINUTELY'
    },
    Status: {
        ACTIVE: 'ACTIVE',
        DISABLED: 'DISABLED',
        DRAFT: 'DRAFT',
        ARCHIVED: 'ARCHIVED'
    },
    Availabilities: {
        RangeType: {
            DATE_ON: 'DATE_ON',
            DATE_RANGE: 'DATE_RANGE',
            DATE_ON_TIME_BTW: 'DATE_ON_TIME_BTW',
            DATE_TIME_RANGE: 'DATE_TIME_RANGE',
            DATE_RANGE_TIME_BTW: 'DATE_RANGE_TIME_BTW',
            WEEKDAY_ON: 'WEEKDAY_ON',
            WEEKDAY_RANGE: 'WEEKDAY_RANGE',
            WEEKDAY_RANGE_TIME_BTW: 'WEEKDAY_RANGE_TIME_BTW',
            WEEKDAY_ON_TIME_BTW: 'WEEKDAY_ON_TIME_BTW',
            ANY_TIME: 'ANY_TIME'
        },
        RangeTypesTimeSlot: [
            {
                value: "DATE_ON",
                label: "Date"
            },
            {
                value: "DATE_RANGE",
                label: "Date Range"
            },
            {
                value: "DATE_ON_TIME_BTW",
                label: "Date + Time Range"
            },
            {
                value: "DATE_TIME_RANGE",
                label: "Date & Time Range"
            },
            {
                value: "DATE_RANGE_TIME_BTW",
                label: "Date Range + Time Range"
            },
            {
                value: "WEEKDAY_ON",
                label: "Weekday"
            },
            {
                value: "WEEKDAY_RANGE",
                label: "Weekday Range"
            },
            {
                value: "WEEKDAY_RANGE_TIME_BTW",
                label: "Weekday Range + Time Range"
            },
            {
                value: "WEEKDAY_ON_TIME_BTW",
                label: "Weekday + Time Range"
            },
            {
                value: "ANY_TIME",
                label: "Any Time"
            }
        ],
        RangeTypesPermanent: [
            {
                value: "DATE_ON",
                label: "Date"
            },
            {
                value: "DATE_RANGE",
                label: "Date Range"
            },
            {
                value: "ANY_TIME",
                label: "Any Date"
            }
        ],
        RangeTypesDateRange: [
            {
                value: "DATE_ON",
                label: "Date"
            },
            {
                value: "DATE_RANGE",
                label: "Date Range"
            },
            {
                value: "WEEKDAY_ON",
                label: "Weekday"
            },
            {
                value: "WEEKDAY_RANGE",
                label: "Weekday Range"
            },
            {
                value: "ANY_TIME",
                label: "Any Date"
            }
        ],
        Weekdays: [
            {
                value: "Monday",
                label: "Monday"
            },
            {
                value: "Tuesday",
                label: "Tuesday"
            },
            {
                value: "Wednesday",
                label: "Wednesday"
            },
            {
                value: "Thursday",
                label: "Thursday"
            },
            {
                value: "Friday",
                label: "Friday"
            },
            {
                value: "Saturday",
                label: "Saturday"
            },
            {
                value: "Sunday",
                label: "Sunday"
            }
        ],
        BookingAfterTypes: [
            {
                value: 'days',
                label: 'day(s) from now'
            },
            {
                value: 'hours',
                label: 'hour(s) from now'
            },
            {
                value: 'minutes',
                label: 'minute(s) from now'
            }
        ]
    }
}
export const AVAILABILITY_INITIAL_STATE = {
    range_type: "",
    range_from: "",
    range_to: "",
    range_between: "",
    available_slots: 0,
    //available_users: [],
    availability_status_id: null,
    booking_allowed: true,
    booking_allowed_label: ""
}


