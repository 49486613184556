import {
    LOADING_ACCOUNTS,
    GET_ACCOUNTS,
    GET_ACCOUNT,
    PERSIST_CHANGES_ACCOUNTS,
    RESET_ACCOUNTS,
    Account
} from './accounts-const';

const ACCOUNT_INITIAL_STATE = {
    name: "",
    status: Account.Status.DRAFT,
    domains: "",
    parent_id: null,
    options: {
        people_types: [{
            name: "",
            slug: "",
            custom_fields: []
        }],
        items_types: [{
            name: "",
            custom_fields: [],
            people_type: { slug: "" },
            availabilities_status: [
                {
                    booking_allowed: true,
                    booking_allowed_label: ""
                }
            ]
        }],
        sources: [{
            name: ""
        }]
    }
}

const INITIAL_STATE = {
    data: [],
    page: {
        curr: 0,
        items: 0,
        pages: 0
    },
    loading: false,
    filters: [],
    sorters: [],
    s: "",
    new: ACCOUNT_INITIAL_STATE,
    edit: ACCOUNT_INITIAL_STATE

};



export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_ACCOUNTS:
            return {
                ...state,
                data: action.payload.data,
                page: action.payload.page,
                filters: action.payload.filters,
                sorters: action.payload.sorters
            };
        case GET_ACCOUNT:
            return {
                ...state,
                edit: action.payload
            };

        case PERSIST_CHANGES_ACCOUNTS:
            return {
                ...state,
                [`${action.payload.ftype}`]: action.payload.data
            };

        case LOADING_ACCOUNTS:
            return {
                ...state,
                loading: action.payload
            };
        case RESET_ACCOUNTS:
            return {
                ...INITIAL_STATE
            };
        default:
            return state;
    }
}