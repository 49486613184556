import React from 'react';
import { Panel, Placeholder } from 'rsuite';

const Dashboard = () => {
    return (
        <>
            <Panel header="Dashboard - Under construction" shaded>
                <Placeholder.Paragraph style={{ marginTop: 30 }} rows={5} graph="image" active />

                <Placeholder.Paragraph style={{ marginTop: 30 }} rows={5} graph="square" active />
                <Placeholder.Paragraph style={{ marginTop: 30 }} rows={5} graph="circle" active />
                <Placeholder.Paragraph style={{ marginTop: 30 }} rows={5} graph="image" active />
            </Panel>
        </>
    );

}
export default Dashboard;