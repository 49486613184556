import obiApi from '../services';
import { SET_NOTIFICATION } from "../_base/base-const";
import { setSelectedAccount } from "../_base/base-action";
import { showDrawer, closeDrawer } from "../_base/drawer/drawer-action";
import { getAvailableAccounts } from '../_base/navigation/nav-action'
import {
    LOADING_ACCOUNTS, GET_ACCOUNTS, GET_ACCOUNT,
    PERSIST_CHANGES_ACCOUNTS, RESET_ACCOUNTS
} from "./accounts-const";
import { formatError } from '../../utils/utils'

export const getAll = (page, s = null, status = null) => dispatch => {

    dispatch({ type: LOADING_ACCOUNTS, payload: true })

    obiApi.get('/accounts', {
        params: {
            page: page,
            s: s,
            status: status
        }
    })
        .then(res => {
            dispatch({ type: LOADING_ACCOUNTS, payload: false })
            dispatch({
                type: GET_ACCOUNTS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({ type: LOADING_ACCOUNTS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const reset = () => dispatch => {
    dispatch({ type: RESET_ACCOUNTS })
};

export const openEdit = (id) => dispatch => {
    dispatch({ type: LOADING_ACCOUNTS, payload: true })
    dispatch(showDrawer('account_edit'));

    obiApi.get('/accounts/' + id)
        .then(res => {
            dispatch({ type: GET_ACCOUNT, payload: res.data })
            dispatch({ type: LOADING_ACCOUNTS, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_ACCOUNTS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};

export const openNew = () => dispatch => {
    dispatch(showDrawer('account_new'));
};


export const persistChanges = (data, ftype) => dispatch => {
    dispatch({ type: PERSIST_CHANGES_ACCOUNTS, payload: { data: data, ftype: ftype } })
};

export const create = (data) => dispatch => {
    dispatch({ type: LOADING_ACCOUNTS, payload: true })
    obiApi.post('/accounts', data)
        .then(res => {
            dispatch({ type: LOADING_ACCOUNTS, payload: false })
            dispatch(closeDrawer("account_new"));
            dispatch(getAll(0))
            dispatch(getAvailableAccounts())
        })
        .catch(err => {
            dispatch({ type: LOADING_ACCOUNTS, payload: false })
            dispatch(closeDrawer("account_new"));
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};

export const update = (data, account_id) => dispatch => {
    dispatch({ type: LOADING_ACCOUNTS, payload: true })
    obiApi.patch('/accounts/' + data.id, data)
        .then(res => {
            //dispatch({ type: LOADING_ACCOUNTS, payload: false })
            if (data.id === account_id)
                dispatch(setSelectedAccount(account_id))
            dispatch(getAll(0))
            dispatch(getAvailableAccounts())
            dispatch(closeDrawer("account_edit"));
        })
        .catch(err => {
            dispatch({ type: LOADING_ACCOUNTS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );

};

export const del = (id, page, s = null, status = null) => dispatch => {
    dispatch({ type: LOADING_ACCOUNTS, payload: true })
    obiApi.delete('/accounts/' + id)
        .then(res => {
            dispatch(getAll(page))
            dispatch(getAvailableAccounts())
            dispatch({ type: LOADING_ACCOUNTS, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_ACCOUNTS, payload: false })
            dispatch({
                type: SET_NOTIFICATION,
                payload: { type: "error", message: formatError(err), autoclose: true }
            })
        }
        );
};