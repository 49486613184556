import React from 'react';
import moment from 'moment-timezone';
import { Table, Badge, Button } from 'rsuite';
import { getUserTz } from '../../utils/utils';

const { Cell } = Table;


export const StatusCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Cell {...props}>
            <Badge className={"status-badge " + rowData.status.toLowerCase()} content={rowData.status.toLowerCase()} />
        </Cell>
    );
};

export const DateCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Cell {...props}>
            {moment(rowData[dataKey]).isValid() ? moment.tz(rowData[dataKey], getUserTz()).format("DD/MM/YYYY HH:mm") : ""}
        </Cell>
    );
};

export const StartEndDateCell = ({ rowData, dataKeyStart, dataKeyEnd, ...props }) => {
    let single_date = "";
    let start_date = "";
    let end_date = "";
    let merged_date = "";
    let merged_times = "";
    if (moment(rowData[dataKeyStart]).isValid() && moment(rowData[dataKeyEnd]).isValid()) {
        if (moment.tz(rowData[dataKeyStart], getUserTz()).format("DDMMYYY") === moment.tz(rowData[dataKeyEnd], getUserTz()).format("DDMMYYY")) {
            merged_date = moment.tz(rowData[dataKeyStart], getUserTz()).format("DD/MM/YYYY");
            merged_times = moment.tz(rowData[dataKeyStart], getUserTz()).format("HH:mm") + " - " + moment.tz(rowData[dataKeyEnd], getUserTz()).add(1,"seconds").format("HH:mm");
        } else {
            start_date = moment.tz(rowData[dataKeyStart], getUserTz()).add(1,"seconds").format("DD/MM/YYYY HH:mm");
            end_date = moment.tz(rowData[dataKeyEnd], getUserTz()).add(1,"seconds").format("DD/MM/YYYY HH:mm")
        }
    } else if (moment(rowData[dataKeyStart]).isValid()) {
        single_date = moment.tz(rowData[dataKeyStart], getUserTz()).format("DD/MM/YYYY HH:mm");
    }
    return (
        <Cell {...props}>
            {
                single_date !== "" ? single_date :
                    merged_date !== "" && merged_times !== "" ? <>{merged_date}  <br />{merged_times}</> :
                        start_date !== "" && end_date !== "" ? <>{start_date}  <br />{end_date}</>
                            : ""
            }
        </Cell>
    );
};

export const EditLinkCell = ({ rowData, handleEditAction, dataKey, ...props }) => {
    return (
        <Cell {...props}>
            <Button className="p-0" appearance="link" onClick={() => handleEditAction(rowData.id)}>{rowData.name}</Button>
        </Cell>
    );
};



