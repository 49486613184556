import React from 'react';
import { Form, PanelGroup } from 'rsuite';
import PanelGeneral from './form-panels/items-form-general';
import PanelNotifications from './form-panels/items-form-notifications';
import PanelBookingSettings from './form-panels/items-form-boooking-settings';
import PanelAvailabilities from './form-panels/items-form-availabilities';
import PanelDisplay from './form-panels/items-form-display';

const ItemsForm = ({
    item, options, users, model,
    handleFormChange, ftype, removeFormRow,
    addFormRow, formError
}) => {

    return (
        <>
            <Form
                model={model}
                onChange={(formValue, e) => {
                    console.log(formValue)
                    handleFormChange(formValue, ftype)
                }}
                checkTrigger={"blur"}
                formError={formError}
                formValue={item}
            >
                <PanelGroup bordered>
                    <PanelGeneral
                        item={item}
                        formError={formError}
                        options={options}
                    />
                    <PanelNotifications
                        item={item}
                        formError={formError}
                        users={users}
                        handleFormChange={handleFormChange}
                        ftype={ftype}
                    />
                    <PanelBookingSettings
                        item={item}
                        formError={formError}
                        options={options}
                    />
                    <PanelAvailabilities
                        item={item}
                        formError={formError}
                        options={options}
                        handleFormChange={handleFormChange}
                        removeFormRow={removeFormRow}
                        addFormRow={addFormRow}
                        ftype={ftype}
                    />
                    <PanelDisplay
                        item={item}
                        formError={formError}
                        handleFormChange={handleFormChange}
                        ftype={ftype}
                    />
                </PanelGroup>
            </Form>
        </>
    );

}




export default ItemsForm;