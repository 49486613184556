import React from 'react';
import { Form, Radio } from 'rsuite';

import { TextField, RadioField } from '../_common/form-fields';

import { Inventory } from './inventories-const'

const InventorysForm = ({ inventory, model, handleFormChange, ftype, formError }) => {

    return (
        <>
            <Form
                model={model}
                onChange={(formValue) =>
                    handleFormChange(formValue, ftype)
                }
                formError={formError}
                formValue={inventory}
            >

                <RadioField name="status" className="inline-radio" appearance="picker" inline>
                    <span>Status: </span>
                    <Radio value={Inventory.Status.ACTIVE}>Active</Radio>
                    <Radio value={Inventory.Status.DRAFT}>Draft</Radio>
                    <Radio value={Inventory.Status.DISABLED}>Disabled</Radio>
                    <Radio value={Inventory.Status.ARCHIVED}>Archived</Radio>
                </RadioField>

                <TextField name="name" label="Name" />               

            </Form>
        </>
    );

}




export default InventorysForm;