import React from 'react';
import { useSelector } from "react-redux";
import { Container } from 'rsuite';

import Notification from './notification/notification'
import NavContainer from './navigation/nav-container'

const LayoutPublic = ({ children }) => {
    return (
        <>
            <Notification />
            {children}
        </>
    );
}

const LayoutPrivate = ({ children }) => {
    const menu_expand = useSelector(state => state.base.menu.expand);
    return (
        <>
            <Notification />
            <div className="sidebar-page">
                <Container>                   
                    <NavContainer expand={menu_expand} />
                    <Container id="content-container" className={menu_expand ? "menu-expanded" : "menu-closed"}>
                        {children}
                    </Container>
                </Container>
            </div>
        </>
    );
}

export { LayoutPublic, LayoutPrivate };