import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Items from "./items";
import { Item } from "./items-const";
import { User } from "../../users/users-const";
import { Schema } from 'rsuite';
import { parse, formatErrors } from '../../../utils/utils'
import _ from 'lodash'

import {
    openEditItem, persistChanges, resetChanges,
    createItem, updateItem, deleteItem, newBooking,
    generateEmbed
} from './items-action'

import { resetCalendarData } from '../calendars/calendars-action'

import { closeDrawer } from '../../_base/drawer/drawer-action';
import { showModal, closeModal } from '../../_base/modal/modal-action';

import { getAll as getAllUsers } from '../../users/users-action'

const { StringType, ObjectType, ArrayType, BooleanType, NumberType } = Schema.Types;

const ItemsContainer = ({ inv_id, inventory }) => {
    const dispatch = useDispatch();

    const selected_account = useSelector(state => state.base.selected_account);
    const current_user = useSelector(state => state.base.user);
    const items = useSelector(state => state.items);
    const users = useSelector(state => state.users);
    const [formError, setFormError] = useState({});
    const selected_month = useSelector(state => state.calendars.selected_month);

    const itemModel = Schema.Model({
        inventory_id: NumberType().isInteger("Only integer number allowed").isRequired('This field is required.'),
        item_type_id: NumberType().isInteger("Only integer number allowed").isRequired('This field is required.'),
        slot_type: StringType().isOneOf(Object.values(Item.SlotType), `Can only be one of: ${Object.values(Item.SlotType)}`),
        calendar_type: StringType().isOneOf(Object.values(Item.CalendarType), `Can only be one of: ${Object.values(Item.CalendarType)}`),
        name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
        slot_interval: NumberType().isInteger("Only integer number allowed").min(1, 'Minimum 1').isRequired('This field is required.'),
        slot_label: StringType(),
        booked_label: StringType(),
        slot_min: NumberType().isInteger("Only integer number allowed").min(0, 'Minimum value for this field is 0').isRequired('This field is required.'),
        slot_max: NumberType().isInteger("Only integer number allowed").min(0, 'Minimum value for this field is 0').isRequired('This field is required.'),
        max_bookings_allowed: NumberType().isInteger("Only integer number allowed").min(0, 'Minimum 0').isRequired('Invalid value.'),
        booking_after: ObjectType().shape({
            period: StringType(),
            value: NumberType().isInteger("Only integer number allowed").min(0, 'Minimum number is 0'),
        }),
        availabilities: ArrayType(),
        approval: BooleanType().isRequired('This field is required.'),
        notify_clients: BooleanType().isRequired('This field is required.'),
        notify_users: ArrayType().of(ObjectType()),
        description: StringType(),
        status: StringType().isOneOf(Object.values(Item.Status), `Can only be one of: ${Object.values(Item.Status)}`),
        tz: StringType().isRequired('This field is required.')
    });

    const availabilitiesModel = Schema.Model({
        range_type: StringType().isOneOf(Object.values(Item.Availabilities.RangeType), `Can only be one of: ${Object.values(Item.Availabilities.RangeType)}`).isRequired('This field is required.'),
        range_from: StringType(),
        range_to: StringType(),
        range_between: StringType(),
        available_slots: NumberType().isInteger("Only integer number allowed").min(0, 'Min 0').isRequired('This field is required.'),
        //available_users: ArrayType().of(NumberType().isInteger("Only integer number allowed")),
        availability_status_id: NumberType().isInteger("Only integer number allowed").isRequired('This field is required.'),
        booking_allowed: BooleanType().isRequired('This field is required.'),
        booking_allowed_label: StringType().isRequired('This field is required.')
    });

    const embedModel = Schema.Model({
        inventory_id: NumberType().isInteger("Only integer number allowed").isRequired('This field is required.'),
        item_id: NumberType().isInteger("Only integer number allowed").isRequired('This field is required.'),
        booking_source: StringType().isRequired('This field is required')
    });


    const handleCancelEmbed = useCallback(
        (item) => {
            dispatch(persistChanges({
                ...items.embed,
                inventory_id: 0,
                item_id: 0,
                booking_source: "",
                code: ""
            }, "embed"))
            dispatch(closeModal('embed' + item.id))
        }, [dispatch, items]
    )

    const handleEditEmbed = useCallback(
        (item) => {
            dispatch(persistChanges({
                ...items.embed,
                inventory_id: parseInt(item.inventory_id),
                item_id: parseInt(item.id)
            }, "embed"))
            dispatch(showModal('embed' + item.id))
        }, [dispatch, items]
    )

    const handleGenerateEmbed = useCallback(
        () => {
            let err = formatErrors(embedModel.check(items.embed));
            err = !err ? {} : err;
            if (Object.keys(err).length > 0) {
                setFormError(err)
                return
            }
            dispatch(persistChanges({
                ...items.embed,
                code: ""
            }, "embed"))
            dispatch(generateEmbed(items.embed))
        }, [dispatch, items, embedModel]
    )

    const handleEditItem = useCallback(
        (item) => {
            dispatch(resetCalendarData())
            dispatch(getAllUsers(selected_account.id, 0, null, User.Status.ACTIVE))
            dispatch(openEditItem(item))
        }, [dispatch, selected_account]
    )

    const handleDeleteItem = useCallback(
        (inv_id, item_id) => {
            dispatch(deleteItem(inv_id, item_id, selected_account.id))
        }, [dispatch, selected_account]
    )


    const handleSaveItem = useCallback(
        (ftype) => {
            let err = formatErrors(itemModel.check(items[`${ftype}`]));
            err = !err ? {} : err;
            items[`${ftype}`].availabilities.forEach((av, index) => {
                let terr = formatErrors(availabilitiesModel.check(av))
                if (terr) {
                    for (let key in terr) {
                        err[`availabilities.${index}.${key}`] = terr[key]
                    }
                    err['availabilities'] = true
                }
            });

            if (Object.keys(err).length > 0) {
                setFormError(err)
                return
            }

            if (ftype === "new")
                dispatch(createItem(items.new, selected_account.id))
            else if (ftype === "edit")
                dispatch(updateItem(items.edit, selected_account.id, selected_month))

        }, [dispatch, items, selected_account, itemModel, availabilitiesModel, selected_month]
    )

    const handleFormChange = useCallback(
        (form, ftype) => {
            let data = parse(form);
            //custom rules      
            if (data.calendar_type === Item.CalendarType.DATES_RANGE || data.calendar_type === Item.CalendarType.SINGLE_DATE) {
                data.slot_type = Item.SlotType.DAILY
                data.slot_interval = 1
            }
            if (data.slot_type === Item.SlotType.DAILY && data.calendar_type === Item.CalendarType.TIME_SLOTS)
                data.slot_type = Item.SlotType.HOURLY

            //set availability_status_id
            if (data.availabilities)
                data.availabilities.forEach(av => {
                    if (av.booking_allowed_label) {
                        selected_account.options.items_types.forEach(it => {
                            if (data.item_type_id === it.id) {
                                it.availabilities_status.forEach(avs => {
                                    if (av.booking_allowed_label === avs.booking_allowed_label && av.booking_allowed === avs.booking_allowed)
                                        av.availability_status_id = avs.id
                                })
                            }
                        })
                    }
                })

            setFormError({})
            dispatch(persistChanges(data, ftype))
        }, [dispatch, selected_account]
    )

    const removeFormRow = useCallback(
        (key, index, ftype) => {
            const data = _.cloneDeep(items[`${ftype}`]);
            const arr = _.get(data, key);
            arr.splice(index, 1);
            dispatch(persistChanges(data, ftype))
        }, [dispatch, items]
    )

    const addFormRow = useCallback(
        (key, ftype, initial_state = {}) => {
            const data = _.cloneDeep(items[`${ftype}`]);
            let arr = _.get(data, key);
            if (arr) {
                arr.push(initial_state);
            } else {
                _.set(data, key, []);
                arr = _.get(data, key)
                arr.push(initial_state);
            }
            dispatch(persistChanges(data, ftype))
        }, [dispatch, items]
    )

    const handleCancelItem = useCallback(
        (ftype) => {
            dispatch(closeDrawer('item_' + ftype))
            dispatch(resetChanges(ftype))
            dispatch(resetCalendarData())
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(getAllUsers(selected_account.id, 0, null, User.Status.ACTIVE))
            dispatch(showModal(type))
        }, [dispatch, selected_account]
    )

    const handleBooking = useCallback(
        (item) => {
            dispatch(resetCalendarData())
            dispatch(getAllUsers(selected_account.id, 0, null, User.Status.ACTIVE))
            dispatch(newBooking(item))
        }, [dispatch, selected_account]
    )



    return (
        <>
            <Items
                inventory={inventory}
                items={items}
                inv_id={inv_id}
                selected_account={selected_account}
                current_user={current_user}
                users={users}
                itemModel={itemModel}
                handleEditItem={handleEditItem}
                handleDeleteItem={handleDeleteItem}
                handleSaveItem={handleSaveItem}
                handleCancelItem={handleCancelItem}
                handleFormChange={handleFormChange}
                removeFormRow={removeFormRow}
                handleBooking={handleBooking}
                addFormRow={addFormRow}
                handleShowModal={handleShowModal}
                formError={formError}
                embedModel={embedModel}
                handleGenerateEmbed={handleGenerateEmbed}
                handleEditEmbed={handleEditEmbed}
                handleCancelEmbed={handleCancelEmbed}
            />
        </>
    )
}

export default ItemsContainer;